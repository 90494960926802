import React, { useState, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import Textbox from 'components/Textbox/Textbox'
import Dropdown from 'components/Dropdown/Dropdown'
import Button from 'components/Button/Button'

import { addCreditCard } from 'utils/api/organizations'

import './CheckoutForm.css'

const formatCentsToDollars = (amt) => {
  return amt / 100
}

const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const CheckoutForm = ({ setCardSaved }) => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [clientSecret, setClientSecret] = useState('')
  const stripe = useStripe()
  const elements = useElements()
  const [currency, setCurrency] = useState('usd')

  const handleChange = (e) => {
    setDisabled(e.empty)
    setError(e.error ? e.error.message : '')
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setProcessing(true)
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    console.log('error', error)
    console.log('paymentMethod', paymentMethod)
    // stripe.createToken( :: https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    if (error) {
      setError(`Payment failed ${error.message}`)
      setProcessing(false)
    } else {
      const { id } = paymentMethod
      addCreditCard({
        stripeId: id,
        currency: currency,
      }).then((response) => {
        setProcessing(false)
        if (response.success) {
          setError(null)
          setSucceeded(true)
          setCardSaved(true)
        } else {
          setError(null)
          setSucceeded(false)
        }
      })
    }
  }
  if (succeeded) {
    return (
      <div className="checkout-billing-success-wrapper">
        <i className="bi bi-check-circle checkout-billing-success-icon"></i>
        <p className="checkout-billing-success-text">Added your card</p>
      </div>
    )
  }
  /*
  
  */
  return (
    <div className="checkout-submit-wrapper">
      <form id="payment-form" onSubmit={handleSubmit}>
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        <div className="checkout-card-wrapper">
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
        </div>
        <div className="checkout-submit-button-wrapper">
          <Button
            readOnly={processing || disabled || succeeded}
            id="submit"
            className="checkout-submit-button"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                <>Save Card</>
              )}
            </span>
          </Button>
        </div>
      </form>
    </div>
  )
}
export default CheckoutForm
