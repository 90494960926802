import { useState, useEffect } from 'react'
import SharedOrdersPage from '../Shared/SharedOrdersPage'

import { listOrders } from 'utils/api/orders'

// Partner
// (Timestamp)
//
const ListOrdersPage = () => {
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const fetchOrders = () => {
    setLoading(true)
    listOrders({ page }).then((response) => {
      if (response.success) {
        setOrders(response.data.orders || [])
      }
      setLoading(false)
      setInitialLoading(false)
    })
  }

  useEffect(() => {
    fetchOrders()
  }, [page])

  if (initialLoading) {
    return (
      <SharedOrdersPage>
        <div className="spinner-border"></div>
      </SharedOrdersPage>
    )
  }

  return (
    <SharedOrdersPage>
      <>
        <div className="row">
          <div className="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-md-6 col-xl-4 mb-2 mb-md-0">
                    <div class="input-group input-group-search">
                      <input
                        type="text"
                        class="form-control"
                        id="datatables-orders-search"
                        placeholder="Search orders…"
                      />
                      <button class="btn" type="button">
                        <i class="align-middle" data-lucide="search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-8">
                    {/* <div class="text-sm-end">
                      <button type="button" class="btn btn-light btn-lg me-2">
                        <i data-lucide="download"></i> Export
                      </button>
                      <button type="button" class="btn btn-primary btn-lg">
                        <i data-lucide="plus"></i> New Order
                      </button>
                    </div> */}
                  </div>
                </div>
                <table id="datatables-orders" class="table w-100">
                  <thead>
                    <tr>
                      <th class="align-middle">
                        <div class="form-check fs-4">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="datatables-orders-check-all"
                          />
                          <label
                            class="form-check-label"
                            for="datatables-orders-check-all"
                          ></label>
                        </div>
                      </th>
                      <th scope="align-middle">Partner</th>
                      <th scope="align-middle">Status</th>
                      <th scope="align-middle">Order Num.</th>
                      <th scope="align-middle">Customer Order Num.</th>
                      <th scope="align-middle">Total</th>
                      <th scope="align-middle text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => (
                      <tr key={order.id}>
                        <td>
                          <div class="form-check fs-4">
                            <input class="form-check-input" type="checkbox" />
                            <label class="form-check-label"></label>
                          </div>
                        </td>
                        <td>
                          {order.partnerName || ''}
                          {order.orderForSelf && (
                            <span class="badge badge-subtle-primary ms-2">
                              Self
                            </span>
                          )}
                        </td>
                        <td>
                          <span
                            class="badge badge-subtle-warning"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {order.status}
                          </span>
                        </td>
                        <td>
                          <strong>{order.code}</strong>
                        </td>
                        <td>{order.customerCode}</td>
                        <td>{order.total}</td>{' '}
                        <td class="text-middle">
                          <a
                            href={`/orders/${order.id}`}
                            type="button"
                            class="btn btn-light"
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    </SharedOrdersPage>
  )
}

export default ListOrdersPage

/*
{(orders || []).length == 0 ? (
                      <tr>
                        <td colSpan="7">No orders found</td>
                      </tr>
                    ) : (
                      orders.map((order, index) => (
                        <tr key={order.id}>
                          <td>{index + 1}</td>
                          <td>{order.partner?.name || ''}</td>
                          <td>{order.status}</td>
                          <td>{order.code}</td>
                          <td>{order.customerCode}</td>
                          <td>{order.total}</td>
                          <td>
                            <a href={`/orders/${order.id}`}>View</a>
                          </td>
                        </tr>
                      ))
                    )}
*/
