import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import Textarea from 'components/Textbox/Textarea'
import Textbox from 'components/Textbox/Textbox'
import Button from 'components/Button/Button'

import { createApp } from 'utils/api/apps'
import TextboxError from 'components/Textbox/TextboxError'

const DeveloperCreatePage = () => {
  let navigate = useNavigate()

  const [error, setError] = useState('')
  const [creating, setCreating] = useState(false)

  const [name, setName] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [description, setDescription] = useState('')
  const [slug, setSlug] = useState('')
  const [iconUrl, setIconUrl] = useState('')
  const [homepageUrl, setHomepageUrl] = useState('')
  const [documentationUrl, setDocumentationUrl] = useState('')
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState('')
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [coverColor, setCoverColor] = useState('')

  const [installURL, setInstallURL] = useState('')
  const [uninstallURL, setUninstallURL] = useState('')
  const [viewTokensURL, setViewTokensURL] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()

    setCreating(true)
    createApp({
      name,
      shortDescription,
      description,
      slug,
      iconUrl,
      homepageUrl,
      documentationUrl,
      privacyPolicyUrl,
      termsOfServiceUrl,
      contactEmail,
      coverColor,
      installURL,
      uninstallURL,
      viewTokensURL,
    })
      .then((response) => {
        if (response.success) {
          navigate('/developer')
        }
        setCreating(false)
        setError(response.data.message)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Page>
      <PageTitleRow>
        <h1>Create App</h1>
      </PageTitleRow>
      <div className="row">
        <div className="col-12">
          <div>
            <form onSubmit={onSubmit}>
              <Textbox
                label="Name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                textboxStyle={{ maxWidth: 500 }}
              />
              <Textbox
                label="Slug"
                placeholder="Slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                textboxStyle={{ maxWidth: 500 }}
              />
              <Textbox
                label="Short Description"
                placeholder="Short Description"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                textboxStyle={{ maxWidth: 500 }}
              />
              <Textarea
                label="Description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Textbox
                label="Icon URL"
                placeholder="Icon URL"
                textboxStyle={{ maxWidth: 500 }}
                value={iconUrl}
                onChange={(e) => setIconUrl(e.target.value)}
              />
              <Textbox
                label="Homepage URL"
                placeholder="Homepage URL"
                textboxStyle={{ maxWidth: 500 }}
                value={homepageUrl}
                onChange={(e) => setHomepageUrl(e.target.value)}
              />
              <Textbox
                label="Documentation URL"
                placeholder="Documentation URL"
                textboxStyle={{ maxWidth: 500 }}
                value={documentationUrl}
                onChange={(e) => setDocumentationUrl(e.target.value)}
              />
              <Textbox
                label="Privacy Policy URL"
                placeholder="Privacy Policy URL"
                textboxStyle={{ maxWidth: 500 }}
                value={privacyPolicyUrl}
                onChange={(e) => setPrivacyPolicyUrl(e.target.value)}
              />
              <Textbox
                label="Terms of Service URL"
                placeholder="Terms of Service URL"
                textboxStyle={{ maxWidth: 500 }}
                value={termsOfServiceUrl}
                onChange={(e) => setTermsOfServiceUrl(e.target.value)}
              />
              <Textbox
                label="Contact Email"
                placeholder="Contact Email"
                textboxStyle={{ maxWidth: 500 }}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
              <Textbox
                label="Cover Color"
                placeholder="Cover Color"
                textboxStyle={{ maxWidth: 500 }}
                value={coverColor}
                onChange={(e) => setCoverColor(e.target.value)}
              />
              <Textbox
                label="Install URL"
                placeholder="Install URL"
                textboxStyle={{ maxWidth: 500 }}
                value={installURL}
                onChange={(e) => setInstallURL(e.target.value)}
              />
              <Textbox
                label="Uninstall URL"
                placeholder="Uninstall URL"
                textboxStyle={{ maxWidth: 500 }}
                value={uninstallURL}
                onChange={(e) => setUninstallURL(e.target.value)}
              />
              <Textbox
                label="View Tokens URL"
                placeholder="View Tokens URL"
                textboxStyle={{ maxWidth: 500 }}
                value={viewTokensURL}
                onChange={(e) => setViewTokensURL(e.target.value)}
              />
              {error && error !== '' && <TextboxError>{error}</TextboxError>}
              <div>
                <Button loading={creating}>Create</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default DeveloperCreatePage
