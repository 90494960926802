import React from 'react'

import './Logo.css'

const Logo = ({ size, style = {} }) => {
  let fontSize = 32
  if (size === 'sm') {
    fontSize = 16
  } else if (size === 'md') {
    fontSize = 20
  }
  return (
    <div className="logo-wrapper" style={style}>
      <h1 className="title" style={{ fontSize }}>
        hendricks
      </h1>
    </div>
  )
}

export default Logo
