const EDITransactionSets = ({ companySlug }) => {
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Transaction Set</th>
            <th>Status</th>
            <th>Related To</th>
            <th>EDI File</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Invoice (810)</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Active
              </span>
            </td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                Order: 1004
              </span>
            </td>
            <td>
              <a href="#">ISA: 1234567890</a>
            </td>
            <td>
              <a href="#">View</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default EDITransactionSets
