import React, { useRef, useEffect } from 'react'

import Panel from 'components/Panel/Panel'

import './Modal.css'

const Modal = ({ children, toggle, size, hidePanel = false }) => {
  const wrapperRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        toggle()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  let panelSize = 'panel-medium'
  if (size === 'small') {
    panelSize = 'panel-small'
  } else if (size === 'medium') {
    panelSize = 'panel-medium'
  } else if (size === 'large') {
    panelSize = 'panel-large'
  }

  return (
    <div className="modal-outter-wrapper">
      <div className="modal-inner-wrapper">
        <div className={`modal-panel-wrapper ${panelSize}`} ref={wrapperRef}>
          
          {
            (hidePanel) ? (
              <>
              {children}
              </>
            ) : (
              <Panel>{children}</Panel>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Modal
