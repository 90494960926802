import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { listOwnedApps } from 'utils/api/apps'

import SettingsPage from '../SettingsPage'

const SettingsDeveloperPage = () => {
  let navigate = useNavigate()
  const [apps, setApps] = useState([])
  const [appStats, setAppStats] = useState({})

  useEffect(() => {
    listOwnedApps({
      page: 1,
      limit: 100,
    }).then((response) => {
      if (response.success) {
        setApps(response.data.apps)
        setAppStats(response.data.appStats)
      }
    })
  }, [])

  return (
    <SettingsPage loading={false}>
      <h2 className="settings-section-title">Developer</h2>
      <p className="settings-section-title-subtext">Manage your apps.</p>

      <table className="table">
        <thead>
          <tr>
            <th>App Name</th>
            <th>App ID</th>
            <th>Active Installs</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {apps.length > 0 ? (
            <>
              {apps.map((app) => (
                <tr key={app.id}>
                  <td>
                    <p>{app.name}</p>
                  </td>
                  <td>
                    <p>{app.id}</p>
                  </td>
                  <td>
                    <p>{appStats[app.id].installCounts || 0}</p>
                  </td>
                  <td>
                    <a
                      href="#"
                      onClick={() =>
                        navigate(`/settings/developer/apps/${app.id}`)
                      }
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="4">
                <p>No apps found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </SettingsPage>
  )
}

export default SettingsDeveloperPage
