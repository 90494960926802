import React, { useState } from 'react'

import { setCompanyId } from 'utils/cookies'

import Modal from 'components/Modal/Modal'

import './CompanySelector.css'

const getCurrentCompany = (companyId, list) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.id === companyId) {
      return item
    }
  }
  if (list.length > 0) {
    return list[0]
  }
  return null
}

const getLetters = (name) => {
  if (name.length === 0) {
    return 'Aa'
  } else if (name.length === 1) {
    return name[0].toUpperCase()
  }
  return name[0].toUpperCase() + name[1].toLowerCase()
}

const CompanySelector = ({ companyId, companiesAvailable }) => {
  const [showOptions, setShowOptions] = useState(false)

  const toggleCompanySelector = (e) => {
    if (e) {
      e.preventDefault()
    }
    console.log('toggleCompanySelector')
    setShowOptions(!showOptions)
  }

  const currentCompany = getCurrentCompany(companyId, companiesAvailable)

  const selectCompany = (e, companyId) => {
    e.preventDefault()

    setCompanyId(companyId)
    window.location.reload()
  }

  return (
    <div className="company-selector-wrapper">
      <div
        className="company-selector-current-selected"
        onClick={toggleCompanySelector}
      >
        <div className="company-selector-icon-wrapper">
          <p>{getLetters(currentCompany.name)}</p>
        </div>

        <div className="company-selector-details">
          <p className="company-selector-current-selected-text">
            {currentCompany.name}
          </p>

          {companiesAvailable.length <= 1 ? null : showOptions ? (
            <i className="bi bi-chevron-up"></i>
          ) : (
            <i className="bi bi-chevron-down company-selector-current-selected-icon"></i>
          )}
        </div>
      </div>
      {
        showOptions && (
          <Modal toggle={(e) => toggleCompanySelector(e)}>
            <>
              {companiesAvailable.map((company) => {
                if (company.id === companyId) {
                  return (
                    <button
                      className="company-selector-options-item-wrapper"
                    >
                      <div className="company-selector-icon-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-shop"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                        </svg>
                      </div>
                      <p className="company-selector-current-selected-text">
                        {company.name}
                        {' (Current)'}
                      </p>
                    </button>
                  )
                }
                return (
                  <button
                    className="company-selector-options-item-wrapper"
                    onClick={(e) => selectCompany(e, company.id)}
                  >
                    <div className="company-selector-icon-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        className="bi bi-shop"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                      </svg>
                    </div>
                    <p className="company-selector-current-selected-text">
                      {company.name}
                    </p>
                  </button>
                )
              })}
            </>
          </Modal>
        )
      }
    </div>
  )
}

export default CompanySelector