import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Panel from 'components/Panel/Panel'
import Logo from 'components/Logo/Logo'
import Button from 'components/Button/Button'
import Textbox from 'components/Textbox/Textbox'

import { signIn } from 'utils/api/login'

import { setEmail as setEmailInCookies, setAuthToken } from 'utils/cookies'

import './SignInPage.css'

const SignInPage = () => {
  let navigate = useNavigate()

  // Get all query params
  const queryParams = new URLSearchParams(window.location.search)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(queryParams.get('email') || '')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [redirect, setRedirect] = useState('')

  // Get shop query parameter
  const shop = queryParams.get('shop')

  const onSubmit = (e) => {
    e.preventDefault()

    if (email === '') {
      setEmailError('Email is required')
      return
    }
    if (password === '') {
      setPasswordError('Password is required')
      return
    }
    console.log('Submitting request')
    setLoading(true)
    signIn({
      email,
      password,
    }).then((response) => {
      console.log('response', response)
      setLoading(false)
      if (response.success) {
        const { data } = response
        console.log('data', data)
        setAuthToken(data.session.id)
        console.log('email', email)
        setEmailInCookies(email)
        // if (data.sessionLocked) {
        //   console.log('Redirect to verify page')
        //   setRedirect('/verify')
        //   return
        // }
        if (shop && shop !== '') {
          console.log('Redirect to shopify page')
          // Include query params in the redirect
          setRedirect(`/oauth/v1/shopify?${window.location.search}`)
        } else {
          console.log('Redirect to home page')
          setRedirect('/')
        }
        return
      } else {
        setPasswordError('Invalid log in')
      }
    })
  }

  if (redirect !== '') {
    console.log('redirect 2', redirect)
    navigate(redirect)
  }

  return (
    <div className="container-fluid">
      <div className="row sign-in-row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <Panel>
            <br />
            <Logo size="md" />
            <br />
            <form onSubmit={onSubmit}>
              <div>
                <Textbox
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailError}
                />
                <Textbox
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordError}
                  type="password"
                />
              </div>
              <div className="sign-in-button-wrapper">
                <Button loading={loading}>Sign In</Button>
              </div>
            </form>
            <div>
              <p className="sign-in-other-text">
                Need an account? <a href="/signup">Sign Up</a>
              </p>
              <p className="sign-in-other-text">
                <a href="/forgot-password">Forgot Password?</a>
              </p>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default SignInPage
