import { get, patch, post } from './general'

export const getMe = (body) => {
  return get('/user', {}, {})
}

export const updateUser = (body) => {
  return patch('/user', {}, body)
}

export const listAPIKeys = (params) => {
  return get(`/user/api-keys`, {}, params)
}

export const createAPIKey = (body) => {
  return post(`/user/api-keys`, {}, body)
}
