import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'

import PageTabs from 'components/Page/PageTabs'
import PageTab from 'components/Page/PageTab'
import { Cpu, Grid, Layout, LocateIcon } from 'lucide-react'

const SharedProductsPage = ({ children, title = 'Products' }) => {
  return (
    <Page>
      <PageTitleRow>
        <h1 class="h3 mb-3">{title}</h1>
      </PageTitleRow>
      {/* <PageTabs>
        <PageTab to="/products" label="Catalog" icon={<Grid />} />
        <PageTab
          to="/products/validation-rules"
          label="Validation Rules"
          icon={<Layout />}
        />
        <PageTab
          to="/products/automations"
          label="Automations"
          icon={<Cpu />}
        />
        <PageTab
          to="/products/locations"
          label="Locations"
          icon={<LocateIcon />}
        />
      </PageTabs> */}
      {children}
    </Page>
  )
}

/*

					
*/

export default SharedProductsPage
