import React, { useEffect, useState } from 'react'
import LogRocket, { identify } from 'logrocket'
import { useNavigate } from 'react-router-dom'
import {
  isSignedIn,
  getCompanyId,
  setCompanyId,
  setCompaniesAvailable,
  companyIdIsSet,
  getEmail,
} from 'utils/cookies'

import { getMe } from 'utils/api/users'

const PUBLIC_PAGES = ['/signup', '/signin', '/forgot-password', '/verify']

const isPublicRoute = () => {
  for (let i = 0; i < PUBLIC_PAGES.length; i++) {
    const page = PUBLIC_PAGES[i]
    if (page == window.location.pathname) {
      return true
    }
  }
  return false
}

const PrivateRoute = ({ children }) => {
  let navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [counter, setCounter] = useState(0)
  const [redirect, setRedirect] = useState('')

  const indentifyUser = () => {
    const email = getEmail()
    if (email === '') {
      return
    }
    const companyId = getCompanyId()
    LogRocket.identify(email, {
      email: email,
      organizationId: companyId || '',
    })
  }

  useEffect(() => {
    indentifyUser()
  }, [])

  useEffect(() => {
    indentifyUser()
  }, [counter])

  useEffect(() => {
    if (isPublicRoute()) {
      console.log('isPublicRoute')
      return
    }
    console.log('NOT isPublicRoute')
    if (!isSignedIn()) {
      setRedirect(`/signin?reason=not-signed-in&redirect=${encodeURI(window.location.pathname + window.location.search)}`)
      return
    }

    getMe().then((response) => {
      console.log('getMe :: response', response)
      if (response.success && response.data.sessionLocked) {
        setRedirect('/verify?reason=session-locked')
        return
      }
      if (response.statusCode == 403) {
        setRedirect(`/signin?reason=signed-out&redirect=${encodeURI(window.location.pathname + window.location.search)}`)
        return
      }
      let companyId = ''
      console.log('companyIdIsSet', companyIdIsSet())
      if (
        response.success &&
        !response.data.company &&
        !companyIdIsSet() &&
        response.data.companiesWithAccess.length === 0
      ) {
        // No company set, no companies found
        console.log('create comapny')
        setRedirect('/create-company')
        return
      } else if (
        response.success &&
        response.data.company &&
        response.data.company !== null &&
        !companyIdIsSet()
      ) {
        console.log('Setting company id')
        // No company ID set, set it to the first one
        setCompanyId(response.data.company.id)
        setCompaniesAvailable(response.data.companiesWithAccess)
        setCounter(counter + 1)
        companyId = response.data.company.id
      }
      LogRocket.identify(response.data.user.email, {
        name: response.data.user.name || '',
        email: response.data.user.email,
        organizationId: companyId !== '' ? companyId : getCompanyId(),
      })
      setLoading(false)
    })
  }, [])

  if (redirect && redirect !== '') {
    console.log('redirect')
    setRedirect('')
    navigate(redirect)
    return
  }
  console.log('!getCompanyId()', !isPublicRoute(), !getCompanyId())
  if (
    loading &&
    !isPublicRoute() &&
    !getCompanyId() &&
    window.location.pathname !== '/create-company'
  ) {
    // Private route and no company ID is set. The response from GetMe will
    // set it.
    console.log('Render loader')
    return (
      <div>
        <p>Loader</p>
      </div>
    )
  }
  console.log('Render page')
  return <>{children}</>
}

export default PrivateRoute
