import React, { useState } from 'react'

import Dropdown from 'components/Dropdown/Dropdown'
import Textbox from 'components/Textbox/Textbox'
import Button from 'components/Button/Button'

import { addCredit } from 'utils/api/organizations'

import './AddAccountCredit.css'
import { Plus } from 'lucide-react'

const AddAccountCredit = () => {
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = useState('usd')
  const [amount, setAmount] = useState('10.00')
  const [amountError, setAmountError] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()

    if (amount === '') {
      setAmountError('Invalid amount')
      return
    }

    const amountFloat = parseFloat(amount)
    const amountInt = Math.round(amountFloat * 100)
    if (amountInt < 35) {
      setAmountError('Amount cannot be less than $0.35')
      return
    }

    setLoading(true)
    setAmountError('')
    addCredit({
      amount: amountInt,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        // setLoading()
        window.location.reload()
      } else {
        setAmountError(response.data.message)
      }
    })
  }

  return (
    <div className="add-account-credit-wrapper">
      <form onSubmit={onSubmit}>
        <Dropdown value={currency} label="Currency">
          <option value="usd">USD</option>
        </Dropdown>
        <Textbox
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Credit"
          label="Amount"
          className="add-account-credit-amount"
          error={amountError}
          readOnly={loading}
          textboxStyle={{ width: 'calc(100% - 80px - 80px)' }}
        />
        <button className="btn btn-primary" type="submit" disabled={loading} style={{ verticalAlign: 'top' }}>
          <Plus />
          Add
        </button>
      </form>
    </div>
  )
}

export default AddAccountCredit
