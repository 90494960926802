import Cookies from 'universal-cookie'

const PATH = '/'
const DOMAIN = 'app.hendricks.so'
const AUTH_TOKEN = 'authToken'
const EMAIL = 'email'
const COMPANY = 'company'
const COMPANIES_AVAILABLE = 'companies_available'

function getDomain() {
  if (window.location.origin === 'http://localhost:5001') {
    return 'http://localhost:5001'
  }
  return DOMAIN
}

function getExpiry() {
  const now = new Date()
  now.setHours(now.getHours() + 24)
  return now
}

function getDefaultSetOptions() {
  return {
    // domain: getDomain(),
    expiry: getExpiry(),
    path: PATH,
  }
}

function getDefaultGetOptions() {
  return {
    domain: getDomain(),
    path: PATH,
  }
}

export function isSignedIn() {
  const authToken = getAuthToken()
  const email = getEmail()

  console.log('isSignedIn :: email', email)
  console.log('isSignedIn :: authToken', authToken)

  if (!email) {
    return false
  }
  if (!authToken) {
    return false
  }
  return email !== '' || authToken !== ''
}

export function setAuthToken(authToken) {
  console.log('setAuthToken', authToken)
  const cookies = new Cookies()
  cookies.set(AUTH_TOKEN, authToken, getDefaultSetOptions())
  console.log('getAuthToken()', getAuthToken())
}

export function getAuthToken() {
  const cookies = new Cookies()
  return cookies.get(AUTH_TOKEN, getDefaultGetOptions())
}

export function setEmail(email) {
  console.log('setEmail', email)
  const cookies = new Cookies()
  cookies.set(EMAIL, email, getDefaultSetOptions())
  console.log('getEmail()', getEmail())
}

export function getEmail() {
  const cookies = new Cookies()
  return cookies.get(EMAIL, getDefaultGetOptions())
}

export function clearCookies() {
  const cookies = new Cookies()
  cookies.remove(AUTH_TOKEN, getDefaultSetOptions())
  cookies.remove(EMAIL, getDefaultSetOptions())
  cookies.remove(COMPANY, getDefaultSetOptions())
  cookies.remove(COMPANIES_AVAILABLE, getDefaultSetOptions())
  console.log('clearCookies :: getEmail()', getEmail())
  console.log('clearCookies :: getAuthToken()', getAuthToken())
}

export function setCompanyId(companyId) {
  const cookies = new Cookies()
  cookies.set(COMPANY, companyId, getDefaultSetOptions())
}

export function companyIdIsSet() {
  if (getCompanyId() === 'undefined') {
    return false
  }
  return getCompanyId() && getCompanyId() !== ''
}

export function getCompanyId() {
  const cookies = new Cookies()
  return cookies.get(COMPANY, getDefaultGetOptions())
}

export function setCompaniesAvailable(companies) {
  const cookies = new Cookies()
  cookies.set(COMPANIES_AVAILABLE, companies, getDefaultSetOptions())
}

export function getCompaniesAvailable() {
  const cookies = new Cookies()
  return cookies.get(COMPANIES_AVAILABLE, getDefaultGetOptions())
}
