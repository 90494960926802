import React, { useEffect, useState } from 'react'
import LogRocket from 'logrocket'

import { runJobs } from 'utils/api/jobs'

const App = ({ children }) => {
  // useEffect(() => {
  //   runJobs()
  //   const jobTimer = setInterval(() => {
  //     console.log('Running jobs')
  //     runJobs()
  //   }, 60000) // Every 60 seconds
  //   return () => clearInterval(jobTimer)
  // }, [])
  if (window.location.hostname !== 'localhost') {
    LogRocket.init('3dswbb/hendricks')
  }
  return <>{children}</>
}

export default App
