import { useState, useEffect } from 'react'

import SettingsPage from '../SettingsPage'
import Button from 'components/Button/Button'

import { listAPIKeys, createAPIKey } from 'utils/api/users'
import { timeSince } from 'utils/date'

import './SettingsAPIPage.css'

const SettingsAPIPage = () => {
  const [loadingAPIKeys, setLoadingAPIKeys] = useState(true)
  const [apiKeys, setAPIKeys] = useState([])
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    listAPIKeys({}).then((response) => {
      if (response.success) {
        setAPIKeys(response.data.keys || [])
      }
      setLoadingAPIKeys(false)
    })
  }, [])

  const onAddAPIKey = (e) => {
    e.preventDefault()

    setCreating(true)
    createAPIKey({}).then((response) => {
      if (response.success) {
        setAPIKeys([response.data.key, ...apiKeys])
      }
      setCreating(false)
    })
  }

  return (
    <SettingsPage loading={loadingAPIKeys} developerMode={true}>
      <h2 className="settings-section-title">API Keys</h2>
      <p className="settings-section-title-subtext">
        Below are the list of API keys to allow to access your account via API.
      </p>
      <div className="add-api-key-wrapper">
        <Button onClick={onAddAPIKey} loading={creating}>
          Create
        </Button>
      </div>
      {apiKeys.length == 0 ? (
        <p>No API Keys Found</p>
      ) : (
        apiKeys.map((apiKey) => (
          <div className="api-key-listing-item">
            <p className="api-key-listing-code">
              <code>{apiKey.value}</code>
            </p>
            <p className="api-key-time-since">
              Created {timeSince(new Date(apiKey.createdAt))} ago
            </p>
          </div>
        ))
      )}
      {/* <h2 className="settings-section-title">Developer Mode</h2>
        <p className="settings-section-title-subtext">Below are the list of API keys to allow to access your account via API.</p> */}
    </SettingsPage>
  )
}

export default SettingsAPIPage
