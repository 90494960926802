import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import OnboardingPageNextButtons from './OnboardingPageNextButtons'
import SharedOnboardingPage from './SharedOnboardingPage'
import { getMe } from 'utils/api/users'

import { onboardingDiscoveryUpdate } from 'utils/api/organizations'

const OnboardingDiscoveryPage = () => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [allowInvitesFromDiscovery, setAllowInvitesFromDiscovery] =
    useState(false)
  const [catalogSync, setCatalogSync] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    getMe().then((response) => {
      if (response.success) {
        console.log(
          'response.data.company',
          response.data.company.allowInvitesFromDiscovery,
          response.data.company.keepCatalogInSyncForDiscovery
        )
        setLoading(false)
        setCompany(response.data.company)
        setAllowInvitesFromDiscovery(
          response.data.company.allowInvitesFromDiscovery
        )
        setCatalogSync(response.data.company.keepCatalogInSyncForDiscovery)
      }
    })
  }, [])

  const onUpdate = (e, data) => {
    e.preventDefault()

    console.log('onUpdate :: data', data)

    setSaving(true)
    onboardingDiscoveryUpdate(data).then((response) => {
      if (response.success) {
        setSaving(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onNext = (e) => {
    e.preventDefault()
    navigate('/onboarding/syncing')
  }

  if (loading) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={7}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={7}>
      <h2 className="onboarding-section-title">Discovery</h2>
      <p className="text-muted">Configure your discovery setttings.</p>

      <br />
      <p>
        We are a closed network. This means there are no indicators if you are
        on Hendricks or not. You cannot discover other retailers / suppliers
        within the network. We do this because we understand the effort to form
        connections and making partners public often wouldn't be infavor of your
        business.
        <br />
        <br />
        We do have a discovery feature that allows you to discover new retailers
        and suppliers using <strong>public information.</strong> It would be the
        same as searching for a supplier on Google. Below you can configure some
        settings that help improve the functionality of this feature.
      </p>
      <hr />
      {company && company.onboarding.isSupplier && (
        <div>
          <div className="form-group">
            <label htmlFor="discovery">Allow Invites from Discovery</label>
            <select
              className="form-control"
              id="discovery"
              name="discovery"
              required
              value={allowInvitesFromDiscovery}
              onChange={(e) => {
                setAllowInvitesFromDiscovery(e.target.value)
                onUpdate(e, {
                  allowInvitesFromDiscovery: e.target.value === 'true',
                })
              }}
            >
              <option value={true}>Enabled</option>
              <option value={false}>Disabled</option>
            </select>
            <small className="form-text text-muted">
              Partner invites are sent using an email. It's fairly difficult to
              guess the email of the organization, and keeps it private. If
              enabled, you would receive partner invites from other
              organizations without knowing your email. In both scenarioes, you
              would have to approve the invite.
            </small>
            {saving && (
              <div style={{ width: '100%' }}>
                <div className="badge badge-subtle-success">Saving</div>
              </div>
            )}
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="catalogSync">Keep my Catalog in Sync</label>
            <select
              className="form-control"
              id="catalogSync"
              name="catalogSync"
              required
              value={catalogSync}
              onChange={(e) => {
                setCatalogSync(e.target.value)
                onUpdate(e, {
                  keepCatalogInSyncForDiscovery: e.target.value == 'true',
                })
              }}
            >
              <option value={true}>Enabled</option>
              <option value={false}>Disabled</option>
            </select>
            <small className="form-text text-muted">
              By default, we pull your catalog from public available
              information. If enabled, we would use products from your Hendricks
              account instead.
            </small>
            {saving && (
              <div style={{ width: '100%' }}>
                <div className="badge badge-subtle-success">Saving</div>
              </div>
            )}
          </div>
        </div>
      )}

      <OnboardingPageNextButtons>
        <button className="btn btn-primary" onClick={onNext}>
          Next
        </button>
      </OnboardingPageNextButtons>
    </SharedOnboardingPage>
  )
}

export default OnboardingDiscoveryPage
