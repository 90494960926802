import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SharedOnboardingPage from './SharedOnboardingPage'
import OnboardingPageNextButtons from './OnboardingPageNextButtons'

import {
  onboardingListApps,
  onboardingAppsNextUrl,
} from 'utils/api/organizations'
import { installApp } from 'utils/api/apps'

import './OnboardingAppsPage.css'

const AppListing = ({ app, fetchApps }) => {
  const [loading, setLoading] = useState(false)

  const onInstall = (e) => {
    e.preventDefault()
    setLoading(true)

    installApp(app.id, {}).then((response) => {
      if (response.success) {
        console.log('App installed')
        fetchApps()
      } else {
        window.alert(response.data.message)
      }
      setLoading(false)
    })
  }

  return (
    <div className="onboarding-app-listing">
      <div className="onboarding-app-listing-img-col">
        <img src={app.iconUrl} alt={app.name} class="img-fluid" />
      </div>
      <div className="onboarding-app-listing-details">
        <p className="app-title">{app.name}</p>
        <p className="app-description">{app.shortDescription}</p>
        <div>
          {loading ? (
            <button class="btn btn-outline-primary" disabled>
              Loading...
            </button>
          ) : app.installed ? (
            <button class="btn btn-outline-primary">Uninstall</button>
          ) : (
            <button class="btn btn-outline-primary" onClick={onInstall}>
              Install
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const OnboardingAppsPage = () => {
  let navigate = useNavigate()
  const [initialLoading, setInitialLoading] = useState(true)
  const [apps, setApps] = useState([])
  const [loadingNextUrl, setLoadingNextUrl] = useState(false)

  useEffect(() => {
    fetchApps()
  }, [])

  const fetchApps = () => {
    onboardingListApps({}).then((response) => {
      if (response.success) {
        setApps(response.data.apps || [])
        setInitialLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onNext = (e) => {
    e.preventDefault()
    setLoadingNextUrl(true)
    onboardingAppsNextUrl().then((response) => {
      setLoadingNextUrl(false)
      if (response.success) {
        navigate(response.data.nextUrl)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (initialLoading) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={3}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={3}>
      <h2 className="onboarding-section-title">Connect Apps</h2>
      <p className="text-muted">
        Install any apps that may related to your existing system. Organizations
        tend to connect their product information management (PIM) system, order
        management system (OMS), and inventory management system (IMS). For
        most, an e-commerce platform (Ex. Shopify) does all three roles.
      </p>
      <br />
      <div>
        {/* <div style={{ maxWidth: 300 }}>
          <div
            class="input-group input-group-search"
            style={{ backgroundColor: '#fff' }}
          >
            <input
              type="text"
              class="form-control"
              id="datatables-products-search"
              placeholder="Search apps..."
              style={{ backgroundColor: '#fff' }}
            />
            <button
              class="btn"
              type="button"
              style={{ backgroundColor: '#fff' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                data-lucide="search"
                class="lucide lucide-search align-middle"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <path d="m21 21-4.3-4.3"></path>
              </svg>
            </button>
          </div>
        </div>
        <br /> */}

        <div className="onboarding-apps-section">
          {/* <h3>E-Commerce Platforms</h3> */}
          {apps.length === 0 ? (
            <p>No apps found</p>
          ) : (
            <>
              {apps.map((app, index) => (
                <AppListing key={index} app={app} fetchApps={fetchApps} />
              ))}
            </>
          )}
        </div>
      </div>
      <OnboardingPageNextButtons>
        <button
          className="btn btn-primary"
          disabled={loadingNextUrl}
          onClick={onNext}
        >
          Next
        </button>
      </OnboardingPageNextButtons>
    </SharedOnboardingPage>
  )
}

export default OnboardingAppsPage
