import { useState, useEffect } from 'react'

import './PendingInvites.css'
import {
  listPartnerInvites,
  acceptPartnerInvite,
  declinePartnerInvite,
} from 'utils/api/partners'
import { Check, X } from 'lucide-react'

const PartnerInvite = ({
  inviteId,
  partnerName,
  partnerLogo,
  fetchPartnerInvites,
  fetchPartners,
}) => {
  const [loading, setLoading] = useState(false)

  const onAccept = (e) => {
    e.preventDefault()

    setLoading(true)
    acceptPartnerInvite(inviteId).then((response) => {
      setLoading(false)
      if (response.success) {
        fetchPartnerInvites()
        fetchPartners()
      }
    })
  }

  const onReject = (e) => {
    e.preventDefault()

    setLoading(true)
    declinePartnerInvite(inviteId).then((response) => {
      setLoading(false)
      if (response.success) {
        fetchPartnerInvites()
        fetchPartners()
      }
    })
  }

  if (partnerName == '') {
    return null
  }
  return (
    <div className="pending-invite-item">
      <div className="pending-invite-img-col">
        <img src={'/fav_icon.png'} alt={partnerName} />
      </div>
      <div className="pending-invite-details-col">
        <p>{partnerName}</p>
      </div>
      <div className="pending-invite-actions-col">
        {!loading && (
          <>
            <button
              className="pending-invite-action-btn pending-invite-action-btn-accept"
              onClick={onAccept}
            >
              <Check size={24} color="#4caf50" style={{ marginRight: 10 }} />
            </button>
            <button
              className="pending-invite-action-btn pending-invite-action-btn-reject"
              onClick={onReject}
            >
              <X size={24} color="#f44336" />
            </button>
          </>
        )}
      </div>
    </div>
  )
}
const PendingInvites = ({ fetchPartners }) => {
  const [loading, setLoading] = useState(true)
  const [invites, setInvites] = useState([])

  const fetchPartnerInvites = () => {
    listPartnerInvites({
      page: 1,
    }).then((response) => {
      setInvites(response.data.invites || [])
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchPartnerInvites()
  }, [])

  if (loading) {
    return null
  }

  if (invites.length === 0) {
    return null
  }

  return (
    <div class="card">
      <div class="card-body">
        <h3 class="h5">Pending Invites</h3>
        <p class="text-muted">
          These are the partners you have invited to trade with you.
        </p>
        <div className="pending-invites-list">
          {invites.map((invite) => {
            return (
              <PartnerInvite
                key={invite.id}
                inviteId={invite.id}
                partnerName={invite.senderOrgName}
                partnerLogo={invite.senderOrgLogoURL || ''}
                fetchPartnerInvites={fetchPartnerInvites}
                fetchPartners={fetchPartners}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PendingInvites
