import { get, post, patch } from './general'
import { getCompanyId } from '../cookies'

export const installIntegration = (body) => {
  return post('/installs', {}, body)
}

export const updateInstall = (installId, body) => {
  return patch(`/installs/${installId}`, {}, body)
}

export const listInstalls = () => {
  return get(`/installs`, {}, {})
}
export const listPendingAssociations = () => {
  return get(`/pending-associations`, {}, {})
}

export const assignPendingAssociation = (id, body) => {
  return patch(`/pending-associations/${id}`, {}, body)
}
export const getInstall = (id) => {
  return get(`/installs/${id}`, {}, {})
}

export const updateSettings = (id, body) => {
  return patch(`/installs/${id}/settings`, {}, body)
}

export const manuallyScheduleRun = (id) => {
  return post(`/installs/${id}/run`, {}, {})
}

export const listIncomeAccounts = (id) => {
  return get(`/installs/${id}/quickbooks/income-accounts`, {}, {})
}

export const listCostOfGoodsAccounts = (id) => {
  return get(`/installs/${id}/quickbooks/cost-of-goods-accounts`, {}, {})
}

export const listDefaultProcesses = (id, params) => {
  return get(`/installs/${id}/default-processes`, {}, params)
}

export const listSyncEvents = (id, params) => {
  return get(`/installs/${id}/events`, {}, params)
}

export const getSyncEvent = (id, eventId, params) => {
  return get(`/installs/${id}/events/${eventId}`, {}, params)
}

export const listSyncEventProcesses = (id, eventId, params) => {
  return get(`/installs/${id}/events/${eventId}/processes`, {}, params)
}

export const updateSFTPSettings = (id, body) => {
  return patch(`/installs/${id}/quickbooks-edi/settings/sftp`, {}, body)
}

export const updateProductSettings = (id, body) => {
  return patch(`/installs/${id}/quickbooks-edi/settings/products`, {}, body)
}

export const updateCustomerSettings = (id, body) => {
  return patch(`/installs/${id}/quickbooks-edi/settings/customers`, {}, body)
}

export const toggleActive = (id, body) => {
  return post(`/installs/${id}/toggle-active`, {}, body)
}

export const disconnect = (id, body) => {
  return post(`/installs/${id}/disconnect`, {}, body)
}

export const listProcesses = (id, params) => {
  return get(`/installs/${id}/processes`, {}, params)
}

export const getProcess = (id, processId) => {
  return get(`/installs/${id}/processes/${processId}`, {}, {})
}

export const getProcessAsYAML = (id, processId) => {
  return get(
    `/installs/${id}/processes/${processId}`,
    {
      Accept: 'application/x-yaml',
    },
    {}
  )
}

export const uninstall = (id) => {
  return post(`/installs/${id}/uninstall`, {}, {})
}

export const updateSettingOptionsAndSections = (id, body) => {
  return patch(`/installs/${id}/setting-options-and-sections`, {}, body)
}

export const overwriteProcess = (installId, processId, body) => {
  return patch(
    `/installs/${installId}/processes/${processId}/overwrite`,
    {},
    body
  )
}
