import React, { useState, useEffect } from 'react'
import Dropdown from 'components/Dropdown/Dropdown'

import SettingsPage from '../SettingsPage'
import { getMe } from 'utils/api/users'
import { startStripeSetup, updateInvoicingSettings } from 'utils/api/invoicing'

import './SettingsInvoicingPage.css'
import TextboxError from 'components/Textbox/TextboxError'
import Textbox from 'components/Textbox/Textbox'

const SettingsInvoicingPage = () => {
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [invoicingCurency, setInvoicingCurrency] = useState('usd')
  const [invoicingMethod, setInvoicingMethod] = useState('stripe')
  const [invoicingFrequency, setInvoicingFrequency] = useState('individual')
  const [connectingStripe, setConnectingStripe] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [invoicingEmail, setInvoicingEmail] = useState('')

  useEffect(() => {
    getMe({}).then((response) => {
      setLoading(false)
      if (response.success) {
        setCompany(response.data.company)
        setInvoicingCurrency(response.data.company.invoicingCurrency)
        setInvoicingMethod(response.data.company.invoicingMethod)
        setInvoicingFrequency(response.data.company.invoicingFrequency)
        setInvoicingEmail(response.data.company.invoicingEmail)
      }
    })
  }, [])

  const onConnectStripe = (e) => {
    e.preventDefualt()

    setConnectingStripe(true)
    startStripeSetup().then((response) => {
      setConnectingStripe(false)
      if (response.success) {
        window.location.href = response.data.redirectUrl
      } else {
        setError(response.data.message)
      }
    })
  }

  const onSave = (e, body) => {
    updateInvoicingSettings(body).then((response) => {
      if (response.success) {
        setSaving(false)
      } else {
        setError(response.data.message)
      }
    })
  }

  if (!company) {
    return (
      <SettingsPage loading={loading}>
        <span />
      </SettingsPage>
    )
  }

  return (
    <SettingsPage
      loading={loading}
      developerMode={company.developerMode || false}
    >
      <h3 className="settings-section-title">Sending Invoicing</h3>
      <p className="settings-section-title-subtext">
        Details about invoicing retailers.
      </p>
      <div>
        {saving && (
          <div>
            <p className="invoicing-saving-text">
              <span className="spinner-border"></span>
              Saving Changes...
            </p>
          </div>
        )}
        {error !== '' && <TextboxError>{error}</TextboxError>}
        <Dropdown
          label="Invoicing Method"
          value={invoicingMethod}
          onChange={(e) => {
            setInvoicingMethod(e.target.value)
            onSave(e, { invoicingMethod: e.target.value })
          }}
          style={{ maxWidth: 300 }}
        >
          <option value="">Disabled</option>
          <option value="stripe">Stripe</option>
          <option value="manual">Manual</option>
        </Dropdown>
        {invoicingMethod !== '' && (
          <>
            {/* <Dropdown
              label="Currency"
              value={invoicingCurency}
              onChange={(e) => setInvoicingCurrency(e.target.value)}
              style={{ maxWidth: 300 }}
            >
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
              <option value="gbp">GBP</option>
              <option value="cad">CAD</option>
              <option value="aud">AUD</option>
              <option value="nzd">NZD</option>
            </Dropdown> */}
            <Dropdown
              label="Frequency"
              value={invoicingFrequency}
              onChange={(e) => {
                setInvoicingFrequency(e.target.value)
                onSave(e, { invoicingFrequency: e.target.value })
              }}
              style={{ maxWidth: 300 }}
            >
              <option value="individual">Individual (Per Order)</option>
              {/* <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option> */}
            </Dropdown>
          </>
        )}
        <br />
        {invoicingMethod === 'stripe' && !company.invoicingStripeSetupBegun && (
          <button
            className="btn btn-primary"
            onClick={onConnectStripe}
            disabled={connectingStripe}
          >
            Connect Stripe
          </button>
        )}
        <br />
      </div>
      <hr />
      <h3 className="settings-section-title">Receiving Invoices</h3>
      <p className="settings-section-title-subtext">
        Details about receiving invoices from suppliers.
      </p>
      <Textbox
        placeholder="Email"
        value={invoicingEmail}
        onChange={(e) => setInvoicingEmail(e.target.value)}
        textboxStyle={{ maxWidth: 300 }}
      />
    </SettingsPage>
  )
}

export default SettingsInvoicingPage
