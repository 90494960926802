import React, { useState } from 'react'
import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import { ChevronLeft, Plus } from 'lucide-react'
import { Link } from 'react-router-dom'
import Textbox from 'components/Textbox/Textbox'

import './CreateOrderPage.css'

const ShippingAddressForm = () => {
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  return (
    <>
      <p className="create-order-section-title">Shipping Address</p>
      <Textbox
        label="Line 1"
        value={line1}
        onChange={(e) => setLine1(e.target.value)}
        placeholder="Line 1"
      />
      <Textbox
        label="Line 2"
        value={line2}
        onChange={(e) => setLine2(e.target.value)}
        placeholder="Line 2"
      />
      <div className="shipping-address-row">
        <Textbox
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
          textboxStyle={{ width: '49%' }}
        />
        <Textbox
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="State"
          textboxStyle={{ width: '49%' }}
        />
      </div>
      <div className="shipping-address-row">
        <Textbox
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="Country"
          textboxStyle={{ width: '49%' }}
        />
        <Textbox
          label="Zip"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          placeholder="Zip"
          textboxStyle={{ width: '49%' }}
        />
      </div>
    </>
  )
}

const SelectedLineItem = () => {
  return <div className="selected-line-item"></div>
}

const LineItems = () => {
  return (
    <div>
      <h3 className="create-order-section-title">Line Items</h3>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="line-item-details">
                <div>
                  <img src="https://placehold.co/400" alt="Product" />
                </div>
                <div>
                  <p>Product Name {' / '} Variant Name</p>
                  <p>sku-asd-asd</p>
                </div>
              </td>
              <td>
                <input type="number" className="form-control" />
              </td>
              <td>$100.00</td>
              <td>$100.00</td>
              <td>
                <button className="btn btn-danger">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const SuggestedLineItem = () => {
  return (
    <button className="suggested-line-item-btn">
      <div className="suggested-line-item-wrapper">
        <img
          src="https://placehold.co/400"
          alt="Product"
          className="create-order-product-image"
        />
        <div className="suggested-line-item-details-col">
          <p className="suggested-line-item-details-name">Product Name</p>
          <p>
            Brand
            {' / '}
            <span>Home Decor</span>
          </p>
        </div>
      </div>
    </button>
  )
}

const SuggestedLineItemLoading = () => {
  return (
    <div className="suggested-line-item-loading">
      <div className="suggested-line-item-loading-image" />
      <div className="suggested-line-item-loading-details">
        <div className="suggested-line-item-loading-name" />
        <div className="suggested-line-item-loading-brand" />
      </div>
    </div>
  )
}

const AddVariantLineItem = () => {
  return (
    <div className="suggested-variant-line-wrapper">
      <div className="suggested-line-item-wrapper">
        <img src="https://placehold.co/400" alt="Product" />
        <div className="suggested-line-item-details-col">
          <p className="suggested-line-item-details-name">Product Name</p>
          <p>
            Brand
            {' / '}
            <span>Home Decor</span>
          </p>
        </div>
        <div
          className="suggested-line-item-actions-col"
          style={{ paddingLeft: 20 }}
        >
          <input
            type="number"
            className="form-control"
            placeholder="Quantity"
            style={{ width: 50 }}
          />
          <button className="btn btn-outline-primary" style={{ marginLeft: 5 }}>
            <Plus />
          </button>
        </div>
      </div>
    </div>
  )
}

const AddLineItemForm = () => {
  const [search, setSearch] = useState('')
  return (
    <div>
      <h3 className="create-order-section-title">Add Item</h3>
      <div>
        <Textbox
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search for products..."
          textboxStyle={{ width: '100%', maxWidth: 400 }}
        />
        <div>
          <SuggestedLineItem />
          <SuggestedLineItem />
          <SuggestedLineItem />
          <SuggestedLineItemLoading />
          <AddVariantLineItem />
          <AddVariantLineItem />
          <AddVariantLineItem />
        </div>
      </div>
    </div>
  )
}

const CreateOrderPage = () => {
  return (
    <Page>
      <PageTitleRow>
        <div className="col-md-12">
          <h1>New Order</h1>
          <Link to="/orders">
            <ChevronLeft />
            Back to Orders
          </Link>
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <LineItems />
              <br />
              <hr />
              <AddLineItemForm />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <ShippingAddressForm />
              <hr />
              <p>Notes</p>
              <hr />
              <p>Total</p>
              <button className="btn btn-primary">Create Order</button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default CreateOrderPage
