import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'

const DeveloperMainPage = () => {
  return (
    <Page>
      <PageTitleRow>
        <h1>Developers</h1>
      </PageTitleRow>
      <div className="row">
        <div className="col-12">
          <p>TODO - Line graph of installs / views</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>TODO - List of Apps</p>
        </div>
      </div>
    </Page>
  )
}

export default DeveloperMainPage
