import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Page from 'components/Page/Page'
import { ArrowLeft } from 'lucide-react'
import _find from 'lodash/find'

import {
  getOrder,
  listOrderHistory,
  acceptOrder,
  denyOrder,
} from 'utils/api/orders'
import { displayDollarAmount, formatDollar } from 'utils/money'
import { timeSince } from 'utils/date'

import Modal from 'components/Modal/Modal'
import Textbox from 'components/Textbox/Textbox'

import './OrdersViewPage.css'
import './CreateFulfillmentModal.css'

const CreateFulfillmentModal = () => {
  const [trackingCode, setTrackingCode] = useState('')
  const [carrier, setCarrier] = useState('')
  const [trackingUrl, setTrackingUrl] = useState('')

  return (
    <div className="card">
      <div className="card-body">
        <div className="create-fulfillment-modal-header">
          <div className="create-fulfillment-modal-header-left">
            <h3 className="">Create Fulfillment</h3>
            <p className="text-muted">Add a fulfillment to this order.</p>
          </div>
          <div className="create-fulfillment-modal-header-right">
            <button className="btn btn-outline-primary">
              Create Fulfillment
            </button>
          </div>
        </div>
        <br />
        <form>
          <div className="create-fulfillment-form">
            <div className="create-fulfillment-form-left">
              <table className="table w-100 dataTable dtr-inline">
                <thead>
                  <tr>
                    <th className="align-middle">Line Item</th>
                    <th className="align-middle">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>Item 1</p>
                      <p>
                        <small>SKU: 123456</small>
                      </p>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        style={{ width: 80 }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="create-fulfillment-form-right">
              <Textbox
                label="Tracking Code"
                placeholder="Tracking Code"
                value={trackingCode}
                onChange={(e) => setTrackingCode(e.target.value)}
              />
              <Textbox
                label="Carrier"
                placeholder="Carrier"
                value={carrier}
                onChange={(e) => setCarrier(e.target.value)}
              />
              <Textbox
                label="Tracking URL"
                placeholder="Tracking URL"
                value={trackingUrl}
                onChange={(e) => setTrackingUrl(e.target.value)}
              />
              <br />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const OrdersViewPage = () => {
  const [loading, setLoading] = useState(true)
  const [majorError, setMajorError] = useState(null)
  const [order, setOrder] = useState(null)
  const [fulfillments, setFulfillments] = useState([])
  const [history, setHistory] = useState([])
  const [rejecting, setRejecting] = useState(false)
  const [accepting, setAccepting] = useState(false)
  const [showCreateFulfillmentModal, setShowCreateFulfillmentModal] =
    useState(false)

  const { id } = useParams()

  useEffect(() => {
    getOrder(id).then((response) => {
      if (response.success) {
        setOrder(response.data.order)
        setFulfillments(response.data.fulfillments || [])
      } else {
        setMajorError(response.error)
      }
      setLoading(false)
    })

    listOrderHistory(id).then((response) => {
      if (response.success) {
        setHistory(response.data.history || [])
      }
    })
  }, [])

  const onToggleCreateFulfillmentModal = () => {
    setShowCreateFulfillmentModal(!showCreateFulfillmentModal)
  }

  const onReject = (e) => {
    e.preventDefault()
    console.log('reject')
    setRejecting(true)
    denyOrder(id).then((response) => {
      if (response.success) {
        setOrder(response.data.order)
      } else {
        window.alert(response.data.message)
      }
      setRejecting(false)
    })
  }

  const onAccept = (e) => {
    e.preventDefault()
    setAccepting(true)
    acceptOrder(id).then((response) => {
      if (response.success) {
        setOrder(response.data.order)
      } else {
        window.alert(response.data.message)
      }
      setAccepting(false)
    })
  }

  if (loading || !order) {
    console.log('loading', order)
    return (
      <Page>
        <div className="spinner-border"></div>
      </Page>
    )
  }

  if (majorError && majorError !== '') {
    return (
      <Page>
        <div className="alert alert-danger">{majorError}</div>
      </Page>
    )
  }

  return (
    <Page>
      <>
        <div className="row orders-title-row">
          <div className="col-6">
            <h1>{order && order.code ? `Order #${order.code}` : 'Order'}</h1>
            <a href="/orders">
              <ArrowLeft size={16} /> Back to Orders
            </a>
          </div>
          <div className="col-6 text-end">
            {order.status == 'pending' && order.isSupplier && (
              <>
                <button
                  className="btn btn-danger"
                  onClick={onReject}
                  style={{ marginRight: 15 }}
                  disabled={rejecting}
                >
                  Reject
                </button>
                <button
                  className="btn btn-success"
                  onClick={onAccept}
                  disabled={accepting}
                >
                  Accept
                </button>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="card">
              <div className="card-body">
                <div>
                  <p>
                    Status:{' '}
                    {order.status == 'pending' ? (
                      <span className="badge bg-secondary">Pending</span>
                    ) : order.status == 'accepted' ? (
                      <span className="badge bg-success">Accepted</span>
                    ) : (
                      <span className="badge bg-primary">{order.status}</span>
                    )}
                  </p>
                  <p>
                    Order #:
                    {order.code}
                  </p>
                  <p>Customer #: {order.customerCode}</p>
                  <p>
                    Partner:{' '}
                    {order.orderForSelf ? (
                      'N/A (Fulfilled from your own inventory)'
                    ) : (
                      <a href={`/partners/${order.partnerId}`}>
                        {order.partnerName ? order.partnerName : 'Unknown'}
                      </a>
                    )}
                  </p>
                </div>
                <br />
                <div>
                  <h3 className="orders-section-title">Items</h3>
                  <table className="table w-100 dataTable dtr-inline">
                    <thead>
                      <tr>
                        <th className="align-middle">Line Item</th>
                        <th className="align-middle">Quantity</th>
                        <th className="align-middle">Base Price</th>
                        <th className="align-middle">Retail Price</th>
                        <th className="align-middle">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(order.lineItems || []).map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <p>{item.title}</p>
                            <p>
                              <small>{item.sku}</small>
                              {item.barcode !== '' && (
                                <small>
                                  {item.barcode} {'('}
                                  {item.barcodeType}
                                  {')'}
                                </small>
                              )}
                            </p>
                          </td>
                          <td>{item.quantity}</td>
                          <td>
                            {displayDollarAmount(
                              item.basePrice.amount,
                              item.basePrice.currency
                            )}
                          </td>
                          <td>
                            {displayDollarAmount(
                              item.retailPrice.amount,
                              item.retailPrice.currency
                            )}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div>
                  <div className="create-fulfillments-title">
                    <h3 className="orders-section-title">Fulfillments</h3>

                    {(order.status === 'accepted' ||
                      order.status === 'fulfilled' ||
                      order.status === 'partially_fulfilled') && (
                      <button
                        className="btn btn-outline-primary"
                        onClick={onToggleCreateFulfillmentModal}
                      >
                        Add Fulfillment
                      </button>
                    )}
                  </div>
                  <div>
                    {fulfillments.length === 0 ? (
                      <p className="text-muted">No fulfillments</p>
                    ) : (
                      <div>
                        {
                          <div>
                            <div>
                              {fulfillments.map((fulfillment, index) => (
                                <div key={fulfillment.id}>
                                  <p>
                                    <strong>
                                      {fulfillment.trackingCode ||
                                        '(No Tracking Code)'}
                                    </strong>
                                    <span
                                      className="badge bg-primary"
                                      style={{ marginLeft: 5 }}
                                    >
                                      {fulfillment.status}
                                    </span>
                                  </p>
                                  <p>
                                    <strong>
                                      {fulfillment.carrier || '(No Carrier)'}
                                    </strong>
                                  </p>
                                  <div>
                                    {(fulfillment.lineItems || []).map(
                                      (fulfillmentItem) => {
                                        if (
                                          fulfillmentItem.orderId !== order.id
                                        ) {
                                          return null
                                        }
                                        const lineItemOnOrder = _find(
                                          order.lineItems,
                                          (lineItem) =>
                                            lineItem.id ===
                                            fulfillmentItem.lineItemId
                                        )
                                        console.log(
                                          'lineItemOnOrder',
                                          lineItemOnOrder
                                        )
                                        if (!lineItemOnOrder) {
                                          return (
                                            <div
                                              key={fulfillmentItem.id}
                                              className="fulfillment-line-item-wrapper"
                                            >
                                              <p>
                                                Unknown line item:{' '}
                                                {fulfillmentItem.lineItemId}
                                              </p>
                                            </div>
                                          )
                                        }
                                        return (
                                          <div
                                            key={fulfillmentItem.id}
                                            className="fulfillment-line-item-wrapper"
                                          >
                                            <div>
                                              <p>{lineItemOnOrder.title}</p>
                                              <p className="text-muted">
                                                {lineItemOnOrder.sku}
                                              </p>
                                            </div>
                                            <div>
                                              <p>
                                                {fulfillmentItem.quantity}
                                                {' of '}
                                                {lineItemOnOrder.quantity}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <h3 className="orders-section-title">Order Summary</h3>
                <div>
                  {Object.keys(order.subTotalsByCurrency || {}).map(
                    (currency) => (
                      <p>
                        Subtotal for {currency}:{' '}
                        {formatDollar(order.subTotalsByCurrency[currency])}
                      </p>
                    )
                  )}
                  <p>
                    Total: {displayDollarAmount(order.totalAmountInUSD, 'USD')}
                  </p>
                  <p className="text-muted">
                    Conversion Rates:{' '}
                    {Object.keys(order.totalAmountConversionRates || {}).map(
                      (currency, index) => (
                        <>
                          {index > 0 && <span>, </span>}
                          <span>
                            {currency} {' ('}
                            {order.totalAmountConversionRates[currency]}
                            {')'}
                          </span>
                        </>
                      )
                    )}
                  </p>
                </div>
                <br />
                <div>
                  <h3 className="orders-section-title">Shipping Address</h3>
                  <p>{order.shippingAddress.name}</p>
                  {order.shippingAddress.company !== '' && (
                    <p>{order.shippingAddress.company}</p>
                  )}
                  <p>{order.shippingAddress.line1}</p>
                  <p>{order.shippingAddress.line2}</p>
                  <p>
                    {order.shippingAddress.city}, {order.shippingAddress.state}{' '}
                    {order.shippingAddress.zip}
                  </p>
                  <p>{order.shippingAddress.country}</p>
                  <p>{order.phone}</p>
                </div>

                <br />
                <div>
                  <h3>Customer Note</h3>
                  <p>
                    {order.customerNote && order.customerNote !== '' ? (
                      <span>{order.customerNote}</span>
                    ) : (
                      <span className="text-muted">No note</span>
                    )}
                  </p>
                </div>

                {history.length > 0 ? (
                  <>
                    <hr />
                    <p>
                      <strong>Activity</strong>
                    </p>
                    <ul className="timeline mt-2 mb-0">
                      {(history || []).map((historyItem, index) => (
                        <li className="timeline-item">
                          <strong>{historyItem.title}</strong>

                          {historyItem.createdAt && (
                            <span className="float-end text-muted text-sm">
                              {timeSince(new Date(historyItem.createdAt))} ago
                            </span>
                          )}
                          <p>{historyItem.description}</p>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
      {showCreateFulfillmentModal && (
        <Modal toggle={onToggleCreateFulfillmentModal} size="large">
          <CreateFulfillmentModal toggle={onToggleCreateFulfillmentModal} />
        </Modal>
      )}
    </Page>
  )
}

export default OrdersViewPage
