import { get, post, patch } from './general'

export const listOrders = (queryParams) => {
  return get(`/orders`, {}, queryParams)
}

export const getOrder = (id) => {
  return get(`/orders/${id}`, {}, {})
}

export const listOrderHistory = (id) => {
  return get(`/orders/${id}/history`, {}, {})
}

export const acceptOrder = (id) => {
  return post(`/orders/${id}/accept`, {}, {})
}

export const denyOrder = (id) => {
  return post(`/orders/${id}/deny`, {}, {})
}

export const listOrderRules = (queryParams) => {
  return get(`/orders/rules`, {}, queryParams)
}

export const getOrderRule = (id, queryParams) => {
  return get(`/orders/rules/${id}`, {}, queryParams)
}

export const updateOrderRule = (id, data) => {
  return patch(`/orders/rules/${id}`, {}, data)
}