import SharedEDIV1Page from '../SharedPage/SharedEDIV1Page'

const PublixEDIPage = () => (
  <SharedEDIV1Page
    name="Publix"
    logo="/imgs/apps/publix1.png"
    companySlug={'publix'}
  />
)

export default PublixEDIPage
