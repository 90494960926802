import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Page from 'components/Page/Page'
import { ArrowLeft } from 'lucide-react'
import { getOrderRule, updateOrderRule } from 'utils/api/orders'

import './ViewOrderRulesPage.css'
import { formatDollar } from 'utils/money'

const Condition = ({ index, depth, condition }) => {
  console.log('Condition', depth, condition)
  if (!condition) {
    return null
  }
  const paddingLeft = depth * 20
  if (condition.type === 'and' || condition.type === 'or') {
    // Multiple sub-conditions
    return (
      <div style={{ paddingLeft }}>
        {index === 0 && depth === 0 ? (
          <p>When...</p>
        ) : index > 0 ? (
          <p>{condition.type}</p>
        ) : null}
        {condition.conditions.map((subCondition, i) => (
          <Condition
            key={i}
            index={i}
            depth={depth + 1}
            condition={subCondition}
          />
        ))}
      </div>
    )
  }
  if (condition.type === 'order_total') {
    let friendlyOperator = condition.operator
    if (condition.operator === 'eq') {
      friendlyOperator = 'equals'
    } else if (condition.operator === 'gt') {
      friendlyOperator = 'is greater than'
    } else if (condition.operator === 'lt') {
      friendlyOperator = 'is less than'
    } else if (condition.operator === 'gte') {
      friendlyOperator = 'is greater than or equal to'
    } else if (condition.operator === 'lte') {
      friendlyOperator = 'is less than or equal to'
    }
    return (
      <div style={{ paddingLeft }}>
        <p>
          Order total is {friendlyOperator} ${formatDollar(condition.value)} USD
        </p>
      </div>
    )
  }
  return (
    <div style={{ paddingLeft }}>
      <p>Unknown condition</p>
    </div>
  )
}

const LoadingCondition = () => (
  <div className="loading-condition">
    <div className="loading-condition-title"></div>
    <div className="loading-condition-children"></div>
  </div>
)

const ViewOrderRulesPage = () => {
  const [orderRule, setOrderRule] = useState(null)
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [savingStatus, setSavingStatus] = useState(false)

  useEffect(() => {
    getOrderRule(id).then((response) => {
      if (response.success) {
        setOrderRule(response.data.rule)
        setLoading(false)
      }
    })
  }, [])

  const onToggleActive = (e, active) => {
    e.preventDefault()
    setSavingStatus(true)

    updateOrderRule(id, { active }).then((response) => {
      if (response.success) {
        setOrderRule(response.data.rule)
      }
      setSavingStatus(false)
    })
  }

  if (loading) {
    return (
      <Page>
        <>
          <div className="row orders-title-row">
            <div className="col-6">
              <div className="order-rules-loading-title"></div>
              <div className="order-rules-loading-back-btn"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <LoadingCondition />
                  <LoadingCondition />
                  <LoadingCondition />
                  <LoadingCondition />
                </div>
              </div>
            </div>
          </div>
        </>
      </Page>
    )
  }

  return (
    <Page>
      <>
        <div className="row orders-title-row">
          <div className="col-6">
            <h1>{orderRule?.name}</h1>
            <a href="/orders">
              <ArrowLeft size={16} /> Back to Rules
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">About</h5>
                <p className="card-text">{orderRule?.description}</p>
                <br />
                {orderRule.active ? (
                  <span className="badge bg-success">Active</span>
                ) : (
                  <span className="badge bg-danger">Inactive</span>
                )}
                <hr />
                <h5 className="card-title">Conditions & Actions</h5>
                <Condition
                  condition={orderRule.condition}
                  depth={0}
                  index={0}
                />
                <br />
                {orderRule.action && (
                  <p className="card-text">
                    Then {orderRule.action} the order if conditions are true.
                  </p>
                )}
                <hr />
                <h5 className="card-title">Status</h5>
                <p className="card-text">
                  Change the status of the order rule. This would only apply to
                  pending orders.
                </p>
                <br />
                {savingStatus ? (
                  <></>
                ) : orderRule.active ? (
                  <button
                    className="btn btn-danger"
                    onClick={(e) => onToggleActive(e, false)}
                  >
                    Deactivate
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={(e) => onToggleActive(e, true)}
                  >
                    Activate
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Page>
  )
}

export default ViewOrderRulesPage
