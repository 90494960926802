import { get, post, patch, deleteAPI } from './general'
import { getCompanyId } from '../cookies'

export const createOrganization = (body) => {
  return post('/organization', {}, body)
}

export const getCurrentOrganization = (id) => {
  return get(`/organization/${id}`, {}, {})
}

export const listOrganizations = () => {
  return get(`/organization`, {}, {})
}

export const updateCurrentOrganization = (orgId, body) => {
  return patch(`/organization/${orgId}`, {}, body)
}

export const addCreditCard = (body) => {
  return post('/organization/billing/add', {}, body)
}

export const deleteCreditCard = (body) => {
  return post('/organization/billing/delete', {}, body)
}

export const addCredit = (body) => {
  return post('/organization/billing/add-credit', {}, body)
}

export const runBillingProcessed = () => {
  return post('/organization/billing/process', {}, {})
}

export const getDashboard = (params) => {
  return get('/dashboard', {}, params)
}

export const onboardingSaveQuestionnaire = (body) => {
  return post('/organization/onboarding/questionnaire', {}, body)
}

export const onboardingRemoveRelatedToEDI = () => {
  return post('/organization/onboarding/remove-related-to-edi', {}, {})
}


export const onboardingSelectPlan = (body) => {
  return post('/organization/onboarding/select-plan', {}, body)
}

export const onboardingListApps = (body) => {
  return get('/organization/onboarding/apps', {}, body)
}

export const onboardingAppsNextUrl = (params) => {
  return get('/organization/onboarding/apps/next-url', {}, params)
}

export const onboardingDiscoveryUpdate = (body) => {
  return patch('/organization/onboarding/discovery', {}, body)
}

export const deleteOrganization = () => {
  
  return deleteAPI('/organization', {}, {})
}

export const getCurrentMonthBilling = () => {
  return get('/organization/billing/receipts/current-month', {}, {})
}

export const updateShipToLocations = (body) => {
  return patch('/organization/ship-to-locations', {}, body)
}