import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import InternalAppPage from 'internal-apps/Shared/InternalAppPage'

import { getEDIRetailerDetails } from 'utils/api/internal-apps'

import './EDIBetaPage.css'

const OnboardingQuestion = ({ question, type }) => {
  return (
    <div>
      <p>{question}</p>
    </div>
  )
}

const EDIBetaPage = () => {
  const { ediRetailerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [majorError, setMajorError] = useState(null)
  const [error, setError] = useState(null)
  const [details, setDetails] = useState({})

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  useEffect(() => {
    getEDIRetailerDetails(ediRetailerId, viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setDetails(response.data.details)
      } else {
        setMajorError(response.data.message)
      }
    })
  }, [])

  if (loading) {
    return (
      <InternalAppPage>
        <p>Loading...</p>
      </InternalAppPage>
    )
  }

  if (majorError) {
    return (
      <InternalAppPage>
        <div>{majorError}</div>
      </InternalAppPage>
    )
  }

  return (
    <InternalAppPage>
      <>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <img src={details.logoUrl} className="edi-retailer-logo" />
                <p>
                  Status: <span className="badge bg-secondary">Pending</span>
                </p>

                <div className="section">
                  <h2 className="section-title">Getting Started</h2>
                  <p className="text-muted">
                    We will work through each of the steps to onboarding.
                  </p>
                  <OnboardingQuestion
                    question="The first step in this process is connecting with Loblaw Companies Limited. Have you done this yet?"
                    type="boolean"
                  />
                </div>

                <div className="section">
                  <h2 className="section-title">Helpful Resources</h2>
                  <p className="text-muted">
                    Here are some resources to help you get started.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </InternalAppPage>
  )
}

export default EDIBetaPage
