import { get, post, patch } from './general'

export const listPartnerInvites = (queryParams) => {
  return get(`/partners/invites`, {}, queryParams)
}

export const listPartners = (queryParams) => {
  return get(`/partners`, {}, queryParams)
}

export const sendPartnerInvite = (body) => {
  return post(`/partners/invites`, {}, body)
}

export const acceptPartnerInvite = (inviteId) => {
  return post(`/partners/invites/${inviteId}/accept`, {}, {})
}

export const declinePartnerInvite = (inviteId) => {
  return post(`/partners/invites/${inviteId}/decline`, {}, {})
}

export const getPartnerById = (id) => {
  return get(`/partners/${id}`, {}, {})
}

export const updatePartner = (id, body) => {
  return patch(`/partners/${id}`, {}, body)
}

export const listSentInvites = (params) => {
  return get(`/partners/sent-invites`, {}, params)
}