import React, { useEffect, useState } from 'react'

import SettingsPage from '../SettingsPage'
import TeamSection from './TeamSection'

import { getMe } from 'utils/api/users'
import { deleteOrganization, updateCurrentOrganization, updateShipToLocations } from 'utils/api/organizations'

import './SettingsCompanyPage.css'
import Textbox from 'components/Textbox/Textbox'
import Dropdown from 'components/Dropdown/Dropdown'
import { countries, flags } from 'utils/countries'
import { X } from 'lucide-react'
import Switch from 'components/Switch/Switch'


const CompanyDetailsSection = ({ company}) => {
  return (
    <>
      <h2 className="settings-section-title">Company Details</h2>
      <p className="text-muted" style={{ marginBottom: 10 }}>
        Update your company details
      </p>
      <form>
        <Textbox
          label="Company Name"
          value={company.name}
          disabled
          textboxStyle={{ maxWidth: 400}}
        />
      </form>
    </>
  )
}

const ShipToLocationsSection = ({ company }) => {
  const [ locations, setLocations ] = useState(company.shipToLocations || [])
  const [ country, setCountry ] = useState(Object.keys(countries)[0])
  const [ state, setState ] = useState('')
  const [ city, setCity ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ showAll, setShowAll ] = useState(false)

  const onAddLocation = (e) => {
    e.preventDefault()
    
    if (!country) {
      console.log('Country is required')
      return
    }

    setLoading(true)
    updateShipToLocations({
      shipToLocations: [
        ...locations,
        { country }
      ]
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        setLocations([
          ...locations,
          { country }
        ])
        setCountry('')
      }
    })
  }

  const onAddAllBut = (e, countryCode) => {
      const newLocations = Object.keys(countries).filter((country) => country !== countryCode).map((country) => ({ country }))

      updateShipToLocations({
        shipToLocations: newLocations
      }).then((response) => {
        console.log(response)
        if (response.success) {
          setLocations(newLocations)
        }
      })
  }

  const onRemoveLocation = (e, location) => {
    e.preventDefault()
    const newLocations = locations.filter((l) => l !== location)

    updateShipToLocations({
      shipToLocations: newLocations
    }).then((response) => {
      console.log(response)
      if (response.success) {
        setLocations(newLocations)
      }
    })
  }

  return (
    <>
      <h2 className="settings-section-title">Ship to Locations</h2>
      <p className="settings-section-title-subtext" style={{ marginBottom: 20 }}>
        Set locations you only ship to. If empty, all countries are allowed.
      </p>
      <form onSubmit={onAddLocation}>
        <Dropdown
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          style={{ maxWidth: 400 }}
        >
          {
            Object.keys(countries).map((countryCode) => (
              <option key={countryCode} value={countryCode}>
                {countries[countryCode]}
              </option>
            ))
          }
        </Dropdown>
        <div>
          <button className="btn btn-primary" disabled={loading}>Add Location</button>
        </div>
      </form>
      <br />
      <div>
        {
          (locations || []).length > 0 ? (
            <div>
              {
                locations.map((location) => (
                  <span key={location} className="badge badge-subtle-secondary remove-country-badge">
                    <span className="flag">{flags[location.country]}</span>
                    <span className="remove-country-inner-text">{countries[location.country]}</span>
                    <button className="remove-country-btn" onClick={(e) => onRemoveLocation(e, location)}>
                      <X />
                    </button>
                  </span>
                ))
              }
            </div>
          ) : (
            <div>
              <p className="text-muted" style={{ marginBottom: 10 }}>
                All countries available.
                {' '}
                <button className="btn btn-link" onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'Hide' : 'Show'}
                </button>
              </p>
              {
                showAll && (
                  <div>
                    {
                      Object.keys(countries).map((countryCode) => (
                        <span key={countryCode} className="badge badge-subtle-secondary remove-country-badge">
                          <span className="flag">{flags[countryCode]}</span>
                          <span className="remove-country-inner-text">{countries[countryCode]}</span>
                          <button className="remove-country-btn" onClick={(e) => onAddAllBut(e, countryCode)}>
                            <X />
                          </button>
                        </span>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    </>
  )
}

const DeveloperModeSection = ({ company }) => {
  const [loading, setLoading] = useState(false)
  const [developerMode, setDeveloperMode] = useState(company.developerMode)

  useEffect(() => {
    console.log('developerMode', developerMode)
    updateCurrentOrganization(company.id, {
      developerMode,
    }).then((response) => {
      console.log('response', response)
      if (response.success) {
        console.log('response.data.organization.developerM', response.data.organization.developerMode)
        setDeveloperMode(response.data.organization.developerMode)
      }
    })
  }, [developerMode])

  return (
    <>
      <h2 className="settings-section-title">Developer Mode</h2>
      <p className="settings-section-title-subtext">
        Enable developer mode to access advanced features.
      </p>
      <div>
        <Switch
          label="Active"
          value={developerMode}
          onChange={(e) => setDeveloperMode(!developerMode)}
        />
      </div>
    </>
  )
}

const SettingsCompanyPage = () => {
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [deleting, setDeleting] = useState(false)

  const fetchCompany = () => {
    getMe({}).then((response) => {
      setLoading(false)
      if (response.success) {
        setCompany(response.data.company)
      }
    })
  }

  const onDelete = (e) => {
    e.preventDefault()
    deleteOrganization().then((response) => {
      if (response.success) {
        window.location.href = '/logout'
      }
    })
  }

  useEffect(() => {
    fetchCompany()
  }, [])

  if (!company) {
    return (
      <SettingsPage loading={loading}>
        <span />
      </SettingsPage>
    )
  }

  return (
    <SettingsPage loading={loading} developerMode={company.developerMode || false}>
      <CompanyDetailsSection company={company} />
      

      <hr />
      <h2 className="settings-section-title">Team</h2>
      <p className="settings-section-title-subtext">
        Invite your team to help contribute.
      </p>
      <TeamSection company={company} />
      
      <hr />
      <ShipToLocationsSection company={company} />
      <hr />
      <DeveloperModeSection company={company} />
      <hr />
      <div>
        <h2 className="settings-section-title">Delete Organization</h2>
        <p className="settings-section-title-subtext">
          DANGER - This action cannot be undone.
        </p>
        <button className="btn btn-danger" onClick={onDelete} disabled={deleting}>Delete Organization</button>
      </div>
    </SettingsPage>
  )
}

export default SettingsCompanyPage
