import React, { useEffect, useState } from 'react'
import { listLocations } from 'utils/api/locations'
import {
  addLocationToVariant,
  updateLocationToVariant,
} from 'utils/api/products'
import TextboxError from 'components/Textbox/TextboxError'

const AddNewLocationToInventory = ({ selectedVariant }) => {
  const [loadingLocations, setLoadingLocations] = useState(true)
  const [locations, setLocations] = useState([])
  const [error, setError] = useState('')
  const [locationId, setLocationId] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    listLocations({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        let locations = response.data.locations || []
        // Filter out locations that already have inventory for this variant
        locations = locations.filter((location) => {
          let found = false
          for (let inventory of selectedVariant.inventoryByLocation) {
            if (inventory.locationId === location.id) {
              found = true
              break
            }
          }
          return !found
        })
        setLocations(locations)
      }
      setLoadingLocations(false)
    })
  }, [])

  console.log('selectedVariant', selectedVariant)

  const onAddLocation = (e) => {
    e.preventDefault()

    if (!locationId) {
      setError('Please select a location')
      return
    }

    if (!quantity || quantity <= 0) {
      setError('Please enter a valid quantity')
      return
    }

    setError('')
    addLocationToVariant(
      selectedVariant.retailerProductId,
      selectedVariant.id,
      {
        locationId,
        quantity: parseInt(quantity),
      }
    ).then((response) => {
      if (response.success) {
        window.alert('Location added successfully')
        setLocationId('')
        setQuantity(0)
        setSuccess(true)
        setError('')
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      } else {
        setError(response.data.message)
      }
    })
  }

  return (
    <div className="card">
      <div className="card-body">
        <h3>Add Location for {selectedVariant.title}</h3>
        <p className="text-muted">
          Add a new location for inventory for this variant.
        </p>
        <br />
        {loadingLocations ? (
          <div>Loading locations...</div>
        ) : (
          <form>
            <div className="form-group">
              <label>Location</label>
              <select
                className="form-control"
                value={locationId}
                onChange={(e) => setLocationId(e.target.value)}
              >
                <option value="">Select Location</option>
                {locations.map((location) => (
                  <option value={location.id}>{location.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                className="form-control"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            {error && (
              <TextboxError style={{ marginTop: 10 }}>{error}</TextboxError>
            )}
            <div className="form-group text-end" style={{ paddingTop: 15 }}>
              {success ? (
                <button className="btn btn-success" disabled>
                  Added
                </button>
              ) : (
                <button className="btn btn-primary" onClick={onAddLocation}>
                  Add Location
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

const AdjustInventoryPerLocation = ({ variant, inventory }) => {
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState(inventory.quantity.toString())

  const onUpdate = (e) => {
    e.preventDefault()

    setLoading(true)
    // Update inventory
    updateLocationToVariant(
      variant.productId,
      variant.id,
      inventory.locationId,
      {
        quantity: parseInt(quantity),
      }
    ).then((response) => {
      if (response.success) {
        window.alert('Inventory updated successfully')
      }
      setLoading(false)
    })
  }

  return (
    <tr>
      <td></td>
      <td>{inventory.name}</td>
      <td>
        {inventory.owned ? (
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            className="form-control"
            style={{ maxWidth: 100 }}
          />
        ) : (
          <>
            <label className="form-label">{inventory.quantity}</label>
            {!inventory.owned && (
              <span
                className="badge badge-subtle-secondary"
                style={{ marginLeft: 5 }}
              >
                Owned by partner
              </span>
            )}
          </>
        )}
      </td>
      <td>
        {inventory.owned ? (
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={onUpdate}
            disabled={loading}
          >
            {loading ? 'Updating...' : 'Update'}
          </button>
        ) : (
          <button className="btn btn-sm btn-outline-danger">Disable</button>
        )}
      </td>
    </tr>
  )
}

const AdjustInventory = ({ variants }) => {
  const [showAddLocation, setShowAddLocation] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(null)

  const onAddLocation = (e, variant) => {
    e.preventDefault()
    setSelectedVariant(variant)
    setShowAddLocation(true)
  }

  if (showAddLocation && selectedVariant) {
    return <AddNewLocationToInventory selectedVariant={selectedVariant} />
  }

  return (
    <div className="card">
      <div className="card-body">
        <h3>Adjust Inventory</h3>
        <p className="text-muted">
          Adjust the inventory for each variant by location
        </p>
        <br />
        <table className="table">
          <thead>
            <th>Variants</th>
            <th>Location</th>
            <th>Inventory</th>
            <th></th>
          </thead>
          <tbody>
            {(variants || []).map((variant) => (
              <>
                <tr>
                  <td>{variant.title}</td>
                  <td></td>
                  <td>
                    <label>{variant.inventoryQuantity}</label>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={(e) => onAddLocation(e, variant)}
                    >
                      Add Location
                    </button>
                  </td>
                </tr>
                {(variant.inventoryByLocation || []).map((inventory) => (
                  <AdjustInventoryPerLocation
                    inventory={inventory}
                    variant={variant}
                  />
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AdjustInventory
