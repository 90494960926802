import React from 'react'

import './Button.css'

const Button = ({
  loading,
  children,
  onClick,
  style = {},
  className,
  link,
  disabled = false,
  btnStyle = 'btn-primary',
}) => {
  if (link && link !== '') {
    return (
      <a
        className={`btn ${btnStyle} ${className}`}
        type="submit"
        href={link}
        style={style}
      >
        <span>{children}</span>
      </a>
    )
  }
  return (
    <button
      className={`btn ${btnStyle} ${disabled ? 'main-button-disabled' : ''} ${className}`}
      type="submit"
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {loading ? (
        <div className="spinner-border main-button-spinner" />
      ) : (
        <span>{children}</span>
      )}
    </button>
  )
}

export default Button
