import { useState, useEffect, useRef } from 'react'

import {
  getAliExpressDetails,
  getAliExpressOAuth1,
  addAliExpressProduct,
} from 'utils/api/internal-apps'

import './AliExpressPage.css'
import { Lock } from 'lucide-react'

import Textbox from 'components/Textbox/Textbox'
import Dropdown from 'components/Dropdown/Dropdown'
import { countries } from 'utils/countries'
import { currencies } from 'utils/currencies'

const AccountSetup = ({ details, viewToken, isOnboarding, redirectUrl }) => {
  if (!details) {
    return <div>Loading...</div>
  }

  const onAccountSetup = (e) => {
    e.preventDefault()

    getAliExpressOAuth1(viewToken, {
      redirect: redirectUrl,
    }).then((response) => {
      if (response.success) {
        window.top.location.href = response.data.url
      } else {
        window.alert(response.data.message)
      }
    })
  }

  console.log('details', details)

  return (
    <div className="account-setup-section">
      <h3>Account</h3>
      {details.accessStatus === 'active' ? (
        <div>
          <p className="text-muted">
            You have successfully connected your AliExpress account.
          </p>
          <button className="btn btn-danger" onClick={() => {}}>
            Disconnect
          </button>
        </div>
      ) : (
        <div>
          <p className="text-muted">
            You have not connected your AliExpress account yet.
          </p>
          <button className="btn btn-primary" onClick={onAccountSetup}>
            Connect to AliExpress Account
          </button>
        </div>
      )}
    </div>
  )
}

const AddNewStore = ({ details, viewToken }) => {
  const [error, setError] = useState('')
  const [productAdded, setProductAdded] = useState(false)
  const [productUrl, setProductUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [shipToCountry, setShipToCountry] = useState('')
  const [targetCurrency, setTargetCurrency] = useState('')

  const onAddProduct = (e) => {
    e.preventDefault()

    if (productUrl === '') {
      setError('Please enter a product URL.')
      return
    }

    setError('')
    setProductAdded(false)
    setLoading(true)
    addAliExpressProduct(viewToken, {
      productUrl,
      shipToCountry,
      targetCurrency,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        setProductAdded(true)
        setProductUrl('')
        setTimeout(() => {
          setProductAdded(false)
        }, 2000)
      } else {
        setError(response.data.message)
      }
    })
  }

  if (!details || details.accessStatus === '') {
    return (
      <div className="add-new-store-section">
        <h3>Add New Store</h3>
        <p className="text-muted">
          Please enter a product URL from AliExpress to add a new store.
        </p>
        <div className="add-new-store-locked">
          <Lock />
          <span>
            <strong>Connect to AliExpress Account first</strong>
          </span>
        </div>
      </div>
    )
  }
  return (
    <div className="add-new-store-section">
      <h3>Add New Product</h3>
      <p className="text-muted">
        Please enter a product URL from AliExpress to add a new product.
      </p>
      {error && error !== '' && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {productAdded && (
        <div className="alert alert-success" role="alert">
          Product added successfully.
        </div>
      )}
      <form>
        <Textbox
          label="Product URL"
          placeholder="https://www.aliexpress.com/item/..."
          value={productUrl}
          onChange={(e) => setProductUrl(e.target.value)}
          textboxStyle={{ marginTop: 20 }}
        />
        <Dropdown
          label="One of the countries it must ship to..."
          value={shipToCountry}
          onChange={(e) => setShipToCountry(e.target.value)}
          dropdownStyle={{ marginTop: 20 }}
        >
          <option value="">Select Country</option>
          {Object.keys(countries).map((countryCode) => (
            <option value={countryCode}>{countries[countryCode]}</option>
          ))}
        </Dropdown>
        <Dropdown
          label="Target Currency"
          value={targetCurrency}
          onChange={(e) => setTargetCurrency(e.target.value)}
          dropdownStyle={{ marginTop: 20 }}
        >
          <option value="">Select Currency</option>
          {currencies.map((currency) => (
            <option value={currency}>{currency}</option>
          ))}
        </Dropdown>
        <div>
          <button
            className="btn btn-primary"
            onClick={onAddProduct}
            disabled={loading}
          >
            {loading ? 'Adding Product...' : 'Add Product'}
          </button>
        </div>
      </form>
    </div>
  )
}

const AliExpressPage = () => {
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState(null)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')
  const page = new URLSearchParams(window.location.search).get('page')

  useEffect(() => {
    getAliExpressDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setDetails(response.data.details)
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h2 className="onboarding-section-title">AliExpress</h2>
      <p className="text-muted">
        Please follow the instructions below to setup the Shopify app.
      </p>
      <AccountSetup
        details={details}
        viewToken={viewToken}
        isOnboarding={page && page.indexOf('/onboarding/app-setup/')}
        redirectUrl={page}
      />
      <AddNewStore details={details} viewToken={viewToken} />
    </div>
  )
}

export default AliExpressPage
