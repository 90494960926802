import React from 'react'

import './Textbox.css'

const Textbox = ({
  type,
  label,
  placeholder,
  helpText,
  error,
  value,
  onChange,
  textboxStyle,
  readOnly,
  className,
  hideLabel = false,
}) => {
  let finalTextboxStyle = {}
  let outterTextboxStyle = {}
  if (value !== '' && !hideLabel) {
    outterTextboxStyle = Object.assign(outterTextboxStyle, { paddingTop: 0 })
  }
  if (textboxStyle) {
    finalTextboxStyle = Object.assign(finalTextboxStyle, textboxStyle)
  }
  return (
    <div className={`textbox-wrapper ${className}`} style={finalTextboxStyle}>
      <div
        className={`textbox-outter ${readOnly ? 'textbox-outter-wrapper-readonly' : ''}`}
        style={outterTextboxStyle}
      >
        {value !== '' && !hideLabel && (
          <label className="textbox-mini-label">{label !== '' ? label : placeholder}</label>
        )}
        <input
          type={type}
          placeholder={placeholder}
          className={`${value === '' || hideLabel ? 'textbox-inner' : 'textbox-inner-with-label'} ${readOnly ? 'textbox-inner-readonly' : ''}`}
          onChange={onChange}
          value={value}
          readOnly={readOnly}
        />
      </div>
      {error ? (
        <p className="textbox-helptext error-helptext">{error}</p>
      ) : helpText ? (
        <p className="textbox-helptext">{helpText}</p>
      ) : null}
    </div>
  )
}

export default Textbox
