import React from 'react'

import './Dropdown.css'
const Dropdown = ({
  value,
  onChange,
  children,
  style = {},
  className,
  label,
}) => {
  let innerStyle = {}
  if (!label) {
    innerStyle = { top: 8 }
  }
  return (
    <div className="dropdown-outter-wrapper" style={style}>
      {label && <label className="dropdown-label">{label}</label>}
      <select
        className={`dropdown-inner ${className}`}
        style={innerStyle}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  )
}

export default Dropdown
