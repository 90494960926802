const EDIFiles = ({ companySlug }) => {
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Status</th>
            <th>ISA Control Number</th>
            <th>Transaction Sets</th>
            <th>Received</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
          <tr>
            <td>1234567890.edi</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Processed
              </span>
            </td>
            <td>1234567</td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                810
              </span>
              <span className="badge rounded-pill badge-subtle-primary">
                +3
              </span>
            </td>
            <td>2021-06-01</td>
            <td>
              <a href="#">Download</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="pagination-wrapper-right">
        <ul className="pagination pagination-sm">
          <li className="page-item">
            <a className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <a className="page-link" href="#">
              1
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              2
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              3
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default EDIFiles
