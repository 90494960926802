import SharedProductsPage from '../Shared/SharedProductsPage'

const ViewLocationPage = () => {
  return (
    <SharedProductsPage>
      <div className="card">
        <div className="card-body">
          <p>TEST</p>
        </div>
      </div>
    </SharedProductsPage>
  )
}

export default ViewLocationPage
