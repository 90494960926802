import { useState, useEffect } from 'react'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'

const ListNotificationsPage = () => {
  return (
    <Page>
      <PageTitleRow>
        <div className="col-12">
          <h1>Notifications</h1>
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-8"></div>
      </div>
    </Page>
  )
}

export default ListNotificationsPage
