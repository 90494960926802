import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import SharedOnboardingPage from './SharedOnboardingPage'
import OnboardingPageNextButtons from './OnboardingPageNextButtons'

import { getMe } from 'utils/api/users'
import { listSyncEvents } from 'utils/api/sync-events'

const SyncCard = ({ event }) => {
  return (
    <div className="card">
      <div className="card-body">
        {event.status === 'ended' ? (
          <>
            <p>Products Synced</p>
            <span className="badge badge-subtle-success">Completed</span>
          </>
        ) : event.status === 'failed' ? (
          <>
            <p>Product Synced Failed</p>
            <span className="badge badge-subtle-danger">Failed</span>
            <p className="text-muted">
              Our team has been notified and they are looking into it.
            </p>
          </>
        ) : (
          <>
            <p>Sync Products...</p>
            <span className="badge badge-subtle-primary">In Progress</span>
          </>
        )}
      </div>
    </div>
  )
}

const OnboardingSyncingPage = () => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [loadingSyncEvents, setLoadingSyncEvents] = useState(true)
  const [syncingEvents, setSyncingEvents] = useState([])

  const fetchSignEvents = () => {
    listSyncEvents({
      page: 1,
      limit: 1,
      type: 'sync_products',
    }).then((response) => {
      if (response.success) {
        setSyncingEvents([...response.data.events] || [])
        setLoadingSyncEvents(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  useEffect(() => {
    getMe().then((response) => {
      if (response.success) {
        setCompany(response.data.company)
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })

    fetchSignEvents()

    const interval = setInterval(() => {
      fetchSignEvents()
    }, 5000)
  }, [])

  useEffect(() => {
    if (company && syncingEvents.length > 0) {
      if (company.onboarding.isRetailer) {
        navigate('/onboarding/completed')
      }

      // Check if all jobs are completed
    }
  }, [company, syncingEvents])

  if (loading || loadingSyncEvents) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={8}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={8}>
      <h2 className="onboarding-section-title">Syncing</h2>
      <p className="text-muted">
        Below is the status of jobs that are currently syncing.
      </p>
      <br />
      {syncingEvents.length === 0 ? (
        <div>
          <p>
            It doesn't look like there are any jobs syncing at the moment. This
            means no products were synced from apps. You will be able to
            manually products if required. Otherwise, it will automatically sync
            products from your apps.
          </p>
          <OnboardingPageNextButtons>
            <button
              className="btn btn-primary"
              onClick={() => navigate('/onboarding/completed')}
            >
              Continue
            </button>
          </OnboardingPageNextButtons>
        </div>
      ) : (
        <div>
          <SyncCard event={syncingEvents[0]} key={syncingEvents[0].id} />
          <OnboardingPageNextButtons>
            <button
              className="btn btn-primary"
              onClick={() => navigate('/onboarding/pricing')}
            >
              Continue
            </button>
          </OnboardingPageNextButtons>
        </div>
      )}
    </SharedOnboardingPage>
  )
}

export default OnboardingSyncingPage
