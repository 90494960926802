import React, { useEffect, useState } from 'react'
import { X } from 'lucide-react'
import SharedOnboardingPage from './SharedOnboardingPage'

import { getMe } from 'utils/api/users'
import {
  onboardingRemoveRelatedToEDI,
  onboardingSelectPlan,
} from 'utils/api/organizations'

import './OnboardingPlansPage.css'
import { useNavigate, useParams } from 'react-router-dom'

const PlanCard = ({
  title,
  disabled,
  disabledReason,
  pricing,
  features,
  removeDisabledAction,
  loading,
  selectedPlan,
  value,
  onSelectPlan,
}) => {
  if (disabled) {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <div>
              <X className="text-danger" />
              <span className="text-danger"> {disabledReason}</span>
            </div>
            <br />
            <button
              className="btn btn-danger"
              onClick={removeDisabledAction}
              disabled={loading}
            >
              I don't need EDI
            </button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          {<p className="card-text">{pricing}</p>}
          <hr />
          {
            <ul>
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          }
          <br />
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={(e) => onSelectPlan(e, value)}
          >
            Select
          </button>
          {selectedPlan === value && (
            <span
              className="badge badge-subtle-success"
              style={{ marginLeft: 10 }}
            >
              Currently Selected
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const OnboardingPlansPage = () => {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [availablePlans, setAvailablePlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState('')
  let navigate = useNavigate()

  useEffect(() => {
    getMe().then((response) => {
      if (response.success) {
        setAvailablePlans(response.data.company.onboarding.availablePlans || [])
        setSelectedPlan(response.data.company.plan || '')
        setInitialLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  console.log('selectedPlan', selectedPlan)

  const onRemoveRelatedToEDI = (e) => {
    e.preventDefault()
    setLoading(true)
    onboardingRemoveRelatedToEDI().then((response) => {
      if (response.success) {
        setAvailablePlans(['basic', 'pro', 'enterprise'])
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onSelectPlan = (e, plan) => {
    e.preventDefault()

    setLoading(true)
    onboardingSelectPlan({ plan }).then((response) => {
      if (response.success) {
        setSelectedPlan(plan)
        setLoading(false)
        navigate('/onboarding/apps')
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (initialLoading) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={2}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={2}>
      <h2 className="onboarding-section-title">Plans</h2>
      <p className="text-muted">Select the plan that matches your use case.</p>

      <div className="container-fluid">
        <div className="row onboarding-plans-row">
          {availablePlans.filter((plan) => plan === 'basic').length > 0 ? (
            <PlanCard
              title="Basic"
              pricing="2% of Sales per month"
              features={[
                'Access to All Apps',
                '1k Products / SKUs',
                'Shopify, Wix, BigCommerce, WooCommerce integrations',
                'End-to-end dropship automation',
                'Unlimited Team Members',
                'No support plan included',
              ]}
              selectedPlan={selectedPlan}
              value="basic"
              onSelectPlan={onSelectPlan}
            />
          ) : (
            <PlanCard
              title="Basic"
              disabled={true}
              disabledReason="Connecting with EDI retailers is not available on the basic plan."
              pricing="2% of Sales per month"
              removeDisabledAction={onRemoveRelatedToEDI}
            />
          )}

          <PlanCard
            title="Pro"
            pricing="$2k + 0.5% of Sales per month"
            features={[
              'Access to All Apps',
              'Unlimited Products / SKUs',
              'Shopify, Wix, BigCommerce, WooCommerce integrations',
              'End-to-end dropship automation',
              'Unlimited Team Members',
              'Email support plan included',
              'Access to EDI apps (Your EDI connection or other retailers)',
            ]}
            selectedPlan={selectedPlan}
            value="pro"
            onSelectPlan={onSelectPlan}
          />
          <PlanCard
            title="Enterprise"
            pricing="Custom Pricing"
            features={[
              'Everything in Pro',
              'GSuite Sign In',
              'Role Base Access',
            ]}
            selectedPlan={selectedPlan}
            value="enterprise"
            onSelectPlan={onSelectPlan}
          />
        </div>
      </div>
    </SharedOnboardingPage>
  )
}

export default OnboardingPlansPage
