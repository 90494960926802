import TextboxError from 'components/Textbox/TextboxError'
import { useState, useEffect } from 'react'
import { resetPassword } from 'utils/api/login'

const ResetPasswordPage = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const code = new URLSearchParams(window.location.search).get('code')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()

    if (code === '') {
      setError('Invalid reset code')
      return
    }
    if (password === '') {
      setError('Password is required')
      return
    }
    if (confirmPassword === '') {
      setError('Confirm Password is required')
      return
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match')
      return
    }
    setError('')
    setLoading(true)

    resetPassword({ code, password }).then((response) => {
      if (response.success) {
        window.location.href = '/signin'
      } else {
        setError(response.data.message)
      }
      setLoading(false)
    })
  }

  return (
    <div className="container">
      <div className="row forgot-password-row">
        <div className="col-md-4 offset-md-4 text-center">
          <h1 className="h2">Reset Password</h1>
          <p className="lead">Enter your new password.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <form onSubmit={onSubmit}>
            <input type="hidden" name="code" value={code} />
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            {error && error !== '' && (
              <TextboxError style={{ marginTop: 10 }}>{error}</TextboxError>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: '100%', marginTop: 10 }}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                'Reset Password'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordPage
