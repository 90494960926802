import { Link } from 'react-router-dom'

const PageTab = ({ to, label, icon }) => {
  const isCurrentTab = window.location.pathname === to
  return (
    <Link
      to={to}
      className={`page-tab ${isCurrentTab ? 'page-tab-current' : ''}`}
    >
      {icon}
      <span style={{ marginLeft: `${icon ? '5px' : '0px'}` }}>{label}</span>
    </Link>
  )
}

export default PageTab
