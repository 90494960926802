export const displayDate = (str) => {
  const date = new Date(str)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return `${date.toLocaleDateString('en-US', options)} ${date.toLocaleTimeString('en-US')}`
}

export const diplayYYYYMMDD = (str) => {
  return str.split('T')[0]
}

export const timeSince = (date) => {
  if (date.getYear() < 0) {
    return 'Unknown'
  }
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months'
  }
  interval = seconds / 86400
  if (interval == 1) {
    return Math.floor(interval) + ' day'
  }
  if (interval > 1) {
    return Math.floor(interval) + ' days'
  }
  interval = seconds / 3600
  if (Math.floor(interval) == 1) {
    return Math.floor(interval) + ' hour'
  }
  if (interval > 1) {
    return Math.floor(interval) + ' hours'
  }
  interval = seconds / 60
  if (interval == 1) {
    return Math.floor(interval) + ' minute'
  }
  if (interval > 1) {
    return Math.floor(interval) + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}

export const withinXHours = (dateStr, hours) => {
  const date = new Date(dateStr)
  const now = new Date()
  const diff = now - date
  return diff < hours * 60 * 60 * 1000
}

export const getDateFromNow = (days) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  return date
}
