import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { isSignedIn, clearCookies } from 'utils/cookies'
import { logout } from 'utils/api/login'

const LogoutPage = () => {
  let navigate = useNavigate()

  useEffect(() => {
    logout().then((response) => {
      clearCookies()
      navigate('/')
    })
  }, [])

  if (!isSignedIn()) {
    console.log('User not signed in')
    navigate('/')
  }

  return (
    <div className="container">
      <div className="row login-row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <p>Logging out...</p>
        </div>
      </div>
    </div>
  )
}

export default LogoutPage
