import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Dropdown from 'components/Dropdown/Dropdown'

import SharedOnboardingPage from './SharedOnboardingPage'
import OnboardingPageNextButtons from './OnboardingPageNextButtons'

import { getMe } from 'utils/api/users'
import { startStripeSetup, updateInvoicingSettings } from 'utils/api/invoicing'
import Modal from 'components/Modal/Modal'

const OnboardingInvoicingPage = () => {
  let navigate = useNavigate()

  const [invoicingMethod, setInvoicingMethod] = useState('')
  const [invoicingFrequency, setInvoicingFrequency] = useState('individual')
  const [company, setCompany] = useState(null)
  const [connectingStripe, setConnectingStripe] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [pageLoading, setPageLoading] = useState(true)
  const [showStripeNotConnected, setShowStripeNotConnected] = useState(false)

  const fetchCompany = () => {
    getMe({}).then((response) => {
      if (response.success) {
        setCompany(response.data.company)
        setInvoicingMethod(response.data.company.invoiceMethod)
        setInvoicingFrequency(response.data.company.invoiceFrequency)
        setPageLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchCompany()

    const i = setInterval(() => {
      fetchCompany()
    }, 20000)

    return () => clearInterval(i)
  }, [])

  const onConnectStripe = (e) => {
    e.preventDefault()

    setConnectingStripe(true)
    startStripeSetup().then((response) => {
      setConnectingStripe(false)
      if (response.success) {
        window.open(response.data.redirectUrl, '_blank')
        fetchCompany()
      } else {
        setError(response.data.message)
      }
    })
  }

  const onContinueStripe = (e) => {
    e.preventDefault()

    setConnectingStripe(true)
    startStripeSetup().then((response) => {
      setConnectingStripe(false)
      if (response.success) {
        window.open(response.data.redirectUrl, '_blank')
      } else {
        setError(response.data.message)
      }
    })
  }

  const onSave = (e, body) => {
    updateInvoicingSettings(body).then((response) => {
      if (response.success) {
        setSaving(false)
      } else {
        setError(response.data.message)
      }
    })
  }

  const nextRedirect = () => {
    navigate('/onboarding/partners')
  }

  const onNext = (e) => {
    e.preventDefault()
    if (company.invoiceMethod === 'stripe' && !company.invoicingAvailable) {
      // Show warning
      setShowStripeNotConnected(true)
      return
    }
    nextRedirect()
  }

  if (pageLoading) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={5}>
        <p>Loading...</p>
      </SharedOnboardingPage>
    )
  }

  if (!company) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={5}>
        <span />
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={5}>
      <h2 className="onboarding-section-title">Invoicing</h2>
      <p className="text-muted">
        Configure how you will invoice retailers after orders are fulfilled.
      </p>
      <br />
      <Dropdown
        label="Invoicing Method"
        value={invoicingMethod}
        onChange={(e) => {
          setInvoicingMethod(e.target.value)
          onSave(e, { invoicingMethod: e.target.value })
        }}
        style={{ maxWidth: 300 }}
      >
        <option value="">Disabled</option>
        <option value="stripe">Stripe</option>
        <option value="manual">Manual</option>
      </Dropdown>
      {invoicingMethod !== '' && (
        <>
          {/* <Dropdown
            label="Currency"
            value={invoicingCurency}
            onChange={(e) => setInvoicingCurrency(e.target.value)}
            style={{ maxWidth: 300 }}
          >
            <option value="usd">USD</option>
            <option value="eur">EUR</option>
            <option value="gbp">GBP</option>
            <option value="cad">CAD</option>
            <option value="aud">AUD</option>
            <option value="nzd">NZD</option>
          </Dropdown> */}
          <Dropdown
            label="Frequency"
            value={invoicingFrequency}
            onChange={(e) => {
              setInvoicingFrequency(e.target.value)
              onSave(e, { invoicingFrequency: e.target.value })
            }}
            style={{ maxWidth: 300 }}
          >
            <option value="individual">Individual (Per Order)</option>
            {/* <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option> */}
          </Dropdown>
        </>
      )}
      <br />
      {invoicingMethod === 'stripe' && !company.invoicingStripeSetupBegun ? (
        <button
          className="btn btn-primary"
          onClick={onConnectStripe}
          disabled={connectingStripe}
        >
          Connect Stripe
        </button>
      ) : invoicingMethod === 'stripe' && !company.invoicingAvailable ? (
        <>
          <button
            className="btn btn-primary"
            onClick={onContinueStripe}
            disabled={connectingStripe}
          >
            Continue Setup Stripe
          </button>
          <p className="text-muted">
            Stripe onboarding has started. But has yet to be completed.
          </p>
        </>
      ) : invoicingMethod === 'stripe' && company.invoicingAvailable ? (
        <p className="text-muted">Stripe connected</p>
      ) : null}

      <OnboardingPageNextButtons>
        <button className="btn btn-primary" onClick={onNext}>
          Next
        </button>
      </OnboardingPageNextButtons>
      {showStripeNotConnected && (
        <Modal toggle={() => setShowStripeNotConnected(false)}>
          <div className="alert">
            <div className="alert-message">
              <h4 class="alert-heading">You have not completed setup</h4>
              <p>
                You have selected Stripe as your invoicing method, but you have
                not completed the setup process. We will be able to create
                invoice, but not finalize and send them to retailers. Are you
                sure you want to continue?
              </p>
              <hr />
              <div className="btn-list">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setShowStripeNotConnected(false)
                    nextRedirect()
                  }}
                >
                  Yes, continue
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => setShowStripeNotConnected(false)}
                  style={{ marginLeft: 10 }}
                >
                  No, go back
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </SharedOnboardingPage>
  )
}

export default OnboardingInvoicingPage
