import { useNavigate } from 'react-router-dom'
import SharedOnboardingPage from './SharedOnboardingPage'

import './OnboardingCompletedPage.css'

const OnboardingCompletedPage = () => {
  const navigate = useNavigate()

  const onNext = (e) => {
    e.preventDefault()
    navigate('/')
  }
  return (
    <SharedOnboardingPage title="Let's get to know you better" step={10}>
      <div className="onboarding-completed-page">
        <h2 className="onboarding-completed-page-title">You're all set!</h2>
        <p className="onboarding-completed-page-description">
          We've successfully completed your onboarding process. You can now
          start using our platform to manage your business.
        </p>
        <div className="onboarding-completed-page-actions">
          <button className="btn btn-primary" onClick={onNext}>
            Go to Dashboard
          </button>
        </div>
      </div>
    </SharedOnboardingPage>
  )
}

export default OnboardingCompletedPage
