import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'

import { listApps, installApp } from 'utils/api/apps'

import './AppStoreHomePage.css'

const AppStoreListing = ({ app, fetchApps }) => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const onInstall = (e) => {
    e.preventDefault()
    setLoading(true)

    installApp(app.id, {}).then((response) => {
      if (response.success) {
        console.log('App installed')
        fetchApps()
      } else {
        window.alert(response.data.message)
      }
      setLoading(false)
    })
  }

  const onManage = (e) => {
    e.preventDefault()
    console.log('Manage')
    navigate(`/apps/${app.id}/${app.installId}`)
  }

  const coverStyle = {}
  if (app.coverColor) {
    coverStyle.backgroundColor = app.coverColor
  }

  return (
    <div className="col-4">
      <div className="card app-store-listing-item">
        <div style={coverStyle} className="cover-section"></div>
        <div>
          <div className="icon-row">
            <div className="icon-wrapper">
              <img src={app.iconUrl} alt="App logo" className="icon" />
            </div>
          </div>
          <p className="description">{app.shortDescription}</p>
        </div>
        <div className="actions-row">
          {app.installed ? (
            <button className="btn btn-primary" onClick={onManage}>
              <i className="bi bi-bar-chart-steps"></i>
              Manage
            </button>
          ) : (
            <button className="btn btn-primary" onClick={onInstall}>
              {loading ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                <>
                  <i className="bi bi-cloud-arrow-down"></i>
                  <span>Install</span>
                </>
              )}
            </button>
          )}
          <Link to={''} className="learn-more-link">
            <i className="bi bi-box-arrow-up-right"></i>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  )
}

const AppStoreHomePage = () => {
  const [apps, setApps] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const fetchApps = () => {
    setLoading(false)

    listApps({
      page,
      search,
    }).then((response) => {
      if (response.success) {
        setApps(response.data.apps || [])
      } else {
        setError(response.data.message)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchApps()
  }, [])

  useEffect(() => {
    fetchApps()
  }, [page, search])

  return (
    <Page>
      {/* <div className="row">
                <div className="col-12">
                    <div className="app-store-cover-placeholder"></div>
                </div>
            </div> */}
      <div className="row app-title-row">
        <div className="col-8">
          <h1>App Store</h1>
          <p className="app-store-title-subtext">
            Welcome to the App Store. Here you can find and install apps to help
            you with your business.
          </p>
        </div>
        <div className="col-4 search-col">
          <div className="search-input-box">
            <i className="bi bi-search"></i>
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {loading ? (
          <div className="col-12">
            <p>Loading...</p>
          </div>
        ) : (
          <>
            {apps.map((app) => (
              <AppStoreListing key={app.id} app={app} fetchApps={fetchApps} />
            ))}
          </>
        )}
      </div>
    </Page>
  )
}

export default AppStoreHomePage

/*
<AppStoreListing
                        app={{
                            name: 'Shopify',
                            coverColor: '#5e8e3e',
                            shortDescription: 'Shopify is a complete commerce platform that lets anyone build an online store, manage sales, market to customers, and accept payments in digital and physical locations.',
                            homepageURL: 'https://www.shopify.com/',
                            iconURL: '/imgs/apps/shopify1.png'
                        }}
                    />
                    <AppStoreListing
                        app={{
                            name: 'WooCommerce',
                            coverColor: '#96588a',
                            shortDescription: 'WooCommerce is an open-source e-commerce plugin for WordPress. It is designed for small to large-sized online merchants using WordPress.',
                            homepageURL: 'https://woocommerce.com/',
                            iconURL: '/imgs/apps/woocommerce1.png'
                        }}
                    />
                    <AppStoreListing
                        app={{
                            name: 'SFTP',
                            coverColor: '#2c3e50',
                            shortDescription: 'SFTP provides you with an easy to use SFTP server to past documents to and from the platform.',
                            homepageURL: 'https://woocommerce.com/',
                            iconURL: '/imgs/apps/sftp1.png'
                        }}
                    />
                    <AppStoreListing
                        app={{
                            name: 'Permissionless',
                            coverColor: '#01A9DB',
                            shortDescription: 'Permissionless allows you to source products from other stores on the internet without a formal agreement.',
                            homepageURL: 'https://woocommerce.com/',
                            iconURL: '/imgs/apps/sftp1.png'
                        }}
                    />
                    <AppStoreListing
                        app={{
                            name: 'Home Hardware (EDI)',
                            coverColor: '#f39c12',
                            shortDescription: 'Home Hardware EDI allows you to connect to Home Hardware to send and receive EDI documents.',
                            homepageURL: 'https://woocommerce.com/',
                            iconURL: '/imgs/apps/sftp1.png',
                            installed: true,
                        }}
                    />
                    <AppStoreListing
                        app={{
                            name: 'Sourcing AI',
                            coverColor: '#8e44ad',
                            shortDescription: 'Use AI to automatically source new products based on instrutions and mood boards.',
                            homepageURL: 'https://woocommerce.com/',
                            iconURL: '/imgs/apps/sftp1.png'
                        }}
                    />
*/
