import { get, post, patch } from './general'
import { getCompanyId } from '../cookies'

export const createApp = (body) => {
  return post('/apps', {}, body)
}

export const listApps = (params) => {
  return get('/apps', {}, params)
}

export const installApp = (appId, body) => {
  return post(`/apps/${appId}/installs`, {}, body)
}

export const getLeftMenu = (params) => {
  return get('/apps/left-menu', {}, params)
}

export const getInstallId = (appId, installId, params) => {
  return get(`/apps/${appId}/installs/${installId}`, {}, params)
}

export const updateSettings = (appId, installId, body) => {
  return patch(`/apps/${appId}/installs/${installId}`, {}, body)
}

export const getApp = (appId) => {
  return get(`/apps/${appId}`, {}, {})
}

export const listOwnedApps = (params) => {
  return get('/apps/owned', {}, params)
} 