import { useState, useEffect, useRef } from 'react'

import {
  getWooCommerceDetails,
  updateWooCommerceDetails,
} from 'utils/api/internal-apps'

import './OnboardingWooCommerceApp.css'

const OnboardingWooCommerceApp = () => {
  const shopifyStoreRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [install, setInstall] = useState(null)
  const [blocker, setBlocker] = useState(null)

  const [storeUrl, setStoreUrl] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [direction, setDirection] = useState('hendricks_to_shopify')

  const [saving, setSaving] = useState(false)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  const fetchDetails = () => {
    getWooCommerceDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setInstall(response.data.install)
        setBlocker(response.data.blocker)
        setLoading(false)
        setApiKey(response.data.install.settings['apiKey'] || '')
        setApiSecret(response.data.install.settings['apiSecret'] || '')
        setStoreUrl(response.data.install.settings['storeUrl'] || '')
        setDirection(
          response.data.install.settings['direction'] ||
            'hendricks_to_woocommerce'
        )
      } else {
        window.alert(response.data.message)
      }
    })
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  const onUpdate = (e, body) => {
    e.preventDefault()
    setSaving(true)
    updateWooCommerceDetails(viewToken, body).then((response) => {
      setSaving(false)
      if (response.success) {
        console.log('Settings saved successfully')
        fetchDetails()
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h2 className="onboarding-section-title">WooCommerce Setup</h2>
      <p className="text-muted">
        Please follow the instructions below to setup the WooCommerce app.
      </p>
      {blocker && (
        <div className="alert alert-danger">
          <div className="alert-message">{blocker}</div>
        </div>
      )}
      <br />
      <div className="shopify-section">
        <label className="shopify-item-title">WooCommerce Store</label>
        <input
          type="text"
          className="form-control"
          placeholder="Store URL"
          autoComplete="off"
          value={storeUrl}
          onChange={(e) => {
            setStoreUrl(e.target.value)
            onUpdate(e, { settings: { storeUrl: e.target.value } })
          }}
        />
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>
      <div className="shopify-section">
        <label className="shopify-item-title">Consumer API Key</label>
        <input
          type="text"
          className="form-control"
          placeholder="Consumer API Key"
          autoComplete="off"
          value={apiKey}
          onChange={(e) => {
            setApiKey(e.target.value)
            onUpdate(e, { settings: { apiKey: e.target.value } })
          }}
        />
        <label className="shopify-item-title" style={{ marginTop: 5 }}>
          Consumer Secret Key
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Consumer API Secret"
          autoComplete="off"
          value={apiSecret}
          onChange={(e) => {
            setApiSecret(e.target.value)
            onUpdate(e, { settings: { apiSecret: e.target.value } })
          }}
        />
        <p>
          <a
            href="https://docs.hendricks.so/guides/2024/10/how-do-i-create-my-woocommerce-access-token"
            target="_blank"
          >
            How do I create my WooCommerce access token?
          </a>
        </p>
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>
      <div className="shopify-section">
        <label className="shopify-item-title">Direction</label>
        <select
          className="form-control"
          value={direction}
          onChange={(e) => {
            setDirection(e.target.value)
            onUpdate(e, { settings: { direction: e.target.value } })
          }}
        >
          <option value="retailer">Retailer</option>
          <option value="supplier">Supplier</option>
        </select>
        {saving && <div className="badge badge-subtle-success">Saving</div>}
      </div>
    </div>
  )
}

export default OnboardingWooCommerceApp
