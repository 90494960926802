import React, { useState, useEffect } from 'react'
import InternalAppPage from 'internal-apps/Shared/InternalAppPage'
import { InlineWidget } from 'react-calendly'

import { getEDIDemoDetails } from 'utils/api/internal-apps'

import { Syncing } from 'internal-apps/SharedEDI/Syncing'
import {
  Step,
  ConnectSFTPStep,
  PricingAndPartnerStep,
  SendPOStep,
} from 'internal-apps/SharedEDI/Step'
import CreateOrderModal from 'internal-apps/SharedEDI/CreateOrderModal'

import './EDIDemoPage.css'

const EDIDemoPage = () => {
  const [ediDemoDetails, setEDIDemoDetails] = useState(null)
  const [showCreateOrder, setShowCreateOrder] = useState(false)
  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  const fetchEDIDetails = () => {
    getEDIDemoDetails(viewToken).then((response) => {
      console.log('response', response)
      if (response.success) {
        setEDIDemoDetails(response.data.details)
      }
    })
  }

  useEffect(() => {
    fetchEDIDetails()
    // Set interval
    const interval = setInterval(() => {
      fetchEDIDetails()
    }, 30000)

    // Clear interval
    return () => clearInterval(interval)
  }, [])

  console.log('ediDemoDetails', ediDemoDetails)
  if (!ediDemoDetails) {
    return (
      <InternalAppPage>
        <p>Loading...</p>
      </InternalAppPage>
    )
  }

  return (
    <InternalAppPage>
      <div className="row">
        <div className="col-12">
          <h1 className="edi-demo-title">EDI Demo</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h2 className="edi-demo-about">About</h2>
              <p>
                This application is to demostrate how EDI would interact with
                both Hendricks and any other applications (Ex. Shopify,
                WooCommerce). We will step through the process of connecting to
                retailer who uses EDI. We will receive an order from an EDI
                document, process it, and send back an fulfillment information.
                The goal is to show how EDI can be used to automate the process
                of receiving and fulfilling orders, and you do not need to be an
                expert in it. Each EDI setup is similar but unique to each
                retailer. Our team will work with you to setup the connection
                and ensure that the data is being sent and received correctly.
              </p>
              {/* <br />
              <p>TODO - Youtube</p>
              <p>TODO - Book time</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Step
                step={<>Step 1: Connecting with SFTP</>}
                disabled={false}
                children={<ConnectSFTPStep ediDemoDetails={ediDemoDetails} />}
              />
              <hr />
              <Step
                step={<>Step 2: Create Price list & Assign to Partnership</>}
                disabled={false}
                children={
                  <PricingAndPartnerStep
                    ediDemoDetails={ediDemoDetails}
                    setEDIDemoDetails={setEDIDemoDetails}
                    viewToken={viewToken}
                  />
                }
              />
              <hr />
              <Step
                step={<>Step 3: Sending a Purchase Order</>}
                disabled={false}
                children={
                  <SendPOStep
                    ediDemoDetails={ediDemoDetails}
                    setShowCreateOrder={setShowCreateOrder}
                  />
                }
              />
              <hr />
              <Step
                step={<>Step 4: Reading Purchase Orders</>}
                disabled={false}
                children={
                  <>
                    <p>
                      Our system is constantly checking for new EDI files to
                      parse. If they contain Purchose Order transaction sets
                      within them, we will generate orders within Hendricks.
                    </p>
                    {ediDemoDetails.receivedPO === '' ? (
                      <></>
                    ) : ediDemoDetails.receivedPO === 'receiving' ? (
                      <Syncing
                        text={<>Receiving Purchase Order</>}
                        status={'loading'}
                      />
                    ) : (
                      <>
                        <Syncing
                          text={<>Received Purchase Order</>}
                          status={'success'}
                        />
                        <p>
                          We received a purchase order and created{' '}
                          <a
                            href={`/orders/${ediDemoDetails.receivedPOId}`}
                            target="_blank"
                          >
                            the order.
                          </a>{' '}
                          The next step is for you to manually accept / reject
                          the order, or the order rules will handle it.
                        </p>
                        <p>
                          We are not sending a Purchase Order Acknowledgement
                          (855) in this demo flow. We do support it.
                        </p>
                      </>
                    )}
                  </>
                }
              />
              <hr />
              <Step
                step={<>Step 5: Sync other Apps</>}
                disabled={false}
                children={
                  <>
                    <p>
                      In this step, we follow the standard order sync process.
                      If you have other platforms connected, we will sync the
                      accepted orders into them.
                    </p>
                    {ediDemoDetails.receivedPO === 'received' && (
                      <Syncing text={<></>} status={'success'} />
                    )}
                  </>
                }
              />
              <hr />
              <Step
                step={<>Step 6: Sending Advance Ship Notice</>}
                disabled={false}
                children={
                  <>
                    <p>
                      Similar to the regular flow, as you fulfill the order, we
                      will sync the fulfillment information to the Retailer. In
                      the EDI use case, we sent Advance Ship Notice (856) files
                      to the retailer.
                    </p>
                    {ediDemoDetails.fulfilled !== 'fulfilled' && (
                      <p>
                        We are waiting for you to fulfill{' '}
                        <a href={`/orders/${ediDemoDetails.receivedPOId}`}>
                          this order
                        </a>{' '}
                        completely.
                      </p>
                    )}
                    {(ediDemoDetails.asnFiles || []).length > 0 && (
                      <div>
                        <p>We sent:</p>
                        <ul>
                          {ediDemoDetails.asnFiles.map((file, index) => (
                            <li>{file.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                }
              />
              <hr />
              <Step
                step={<>Step 7: Get Started</>}
                disabled={false}
                children={
                  <>
                    <InlineWidget url="https://calendly.com/hello-hendricks/30min" />
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
      {showCreateOrder && (
        <CreateOrderModal
          ediDemoDetails={ediDemoDetails}
          toggle={(e) => setShowCreateOrder(false)}
          viewToken={viewToken}
        />
      )}
    </InternalAppPage>
  )
}

export default EDIDemoPage
