import React, { useState } from 'react'

import { Syncing } from './Syncing'
import { retryDemoEDI } from 'utils/api/internal-apps'

export const ConnectSFTPStep = ({ ediDemoDetails }) => (
  <>
    <p>
      We typically share EDI files over Secure File Transfer Protocol (SFTP). We
      create a unique space per retailer to share files.
    </p>
    {ediDemoDetails.sftpUserCreated === 'creating' ? (
      <Syncing text={<>Creating SFTP user</>} status={'loading'} />
    ) : ediDemoDetails.sftpUserCreated === 'created' ? (
      <>
        <Syncing text={<>Created SFTP user</>} status={'success'} />
      </>
    ) : (
      <>
        <button className="btn btn-primary" style={{ marginTop: 10 }}>
          Get Started
        </button>
      </>
    )}
  </>
)

export const PricingAndPartnerStep = ({
  ediDemoDetails,
  setEDIDemoDetails,
  viewToken,
}) => {
  const [retrying, setRetrying] = useState(false)
  const onRetry = (e) => {
    e.preventDefault()
    setRetrying(true)
    retryDemoEDI(viewToken).then((response) => {
      if (response.success) {
        setEDIDemoDetails(response.data.details)
      }
      setRetrying(false)
    })
  }
  if (ediDemoDetails.sftpUserCreated !== 'created') {
    return null
  }
  return (
    <>
      <p>
        We created a new organization {`(${ediDemoDetails.managedOrgName})`} to
        represent the EDI retailer.{' '}
        <b>
          You need to{' '}
          <a href="/pricing" target="_blank">
            create a price list
          </a>{' '}
          and assign it to{' '}
          <a href={`/partners/${ediDemoDetails.partnershipId}`} target="_blank">
            the partnership
          </a>
          .
        </b>
      </p>
      <div>
        {ediDemoDetails.partnershipStatus === 'error' ? (
          <div>
            <Syncing
              text={
                <>
                  <div>{ediDemoDetails.partnershipError}</div>
                  <div>
                    <button
                      className="btn btn-outline-secondary"
                      style={{ marginTop: 10 }}
                      onClick={onRetry}
                      disabled={retrying}
                    >
                      {retrying ? 'Retrying...' : 'Retry'}
                    </button>
                  </div>
                </>
              }
              status={'error'}
            />
          </div>
        ) : (
          <Syncing text={<>Ready</>} status={'success'} />
        )}
      </div>
    </>
  )
}

export const SendPOStep = ({ ediDemoDetails, setShowCreateOrder }) => {
  if (ediDemoDetails.partnershipStatus !== 'active') {
    return <p className="text-muted">Complete the step above</p>
  }
  console.log('ediDemoDetails', ediDemoDetails)
  if (ediDemoDetails.sendPO === '') {
    return (
      <>
        <p>
          In this step, we will send a purchase order from the retailer to{' '}
          <b>you</b>. This will be a test order so please advise your team not
          to fulfill it (We put a comment in the note too). Get started by
          clicking the button below.
        </p>
        <button
          className="btn btn-primary"
          style={{ marginTop: 10 }}
          disabled={ediDemoDetails.sftpUserCreated !== 'created'}
          onClick={(e) => setShowCreateOrder(true)}
        >
          Create Test Order
        </button>
      </>
    )
  }
  if (ediDemoDetails.sendPO === 'sending') {
    return <Syncing text={<>Sending Purchase Order</>} status={'loading'} />
  }
  return (
    <>
      <Syncing text={<>Sent Purchase Order</>} status={'success'} />
      <p>We sent a test purchase order.</p>
    </>
  )
}

export const Step = ({ step, disabled, children }) => {
  if (disabled) {
    return (
      <div>
        <h3 className="edi-demo-step-title">{step}</h3>
      </div>
    )
  }
  return (
    <div>
      <h3 className="edi-demo-step-title">{step}</h3>
      {children}
    </div>
  )
}
