import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { InlineWidget } from 'react-calendly'

import InternalAppPage from 'internal-apps/Shared/InternalAppPage'

import {
  getEDIRetailerDetails,
  createSFTPCreds,
  updateInstall,
} from 'utils/api/internal-apps'

import './TopEDI.css'
import './ProductValidation.css'
import './SFTPConnectionForm.css'
import './SupportSection.css'

import Textbox from 'components/Textbox/Textbox'
import { displayDate, timeSince } from 'utils/date'
import TextboxError from 'components/Textbox/TextboxError'

const SFTPConnectionForm = ({ ediId, viewToken, details }) => {
  const [host, setHost] = useState(details.sftpHost || '')
  const [port, setPort] = useState(details.sftpPort || '')
  const [username, setUsername] = useState(details.sftpUser || '')
  const [password, setPassword] = useState(details.sftpPassword || '')
  const [inbound, setInbound] = useState(details.sftpInbound || '')
  const [outbound, setOutbound] = useState(details.sftpOutbound || '')
  const [verified, setVerified] = useState(details.verified || false)
  const [lastConnected, setLastConnected] = useState(
    details.lastConnectedAt || ''
  )
  const [creating, setCreating] = useState(false)
  const [error, setError] = useState(null)

  const onCreateCredentials = (e) => {
    e.preventDefault()
    setCreating(true)
    createSFTPCreds(ediId, viewToken, {}).then((response) => {
      setCreating(false)
      if (response.success) {
        setVerified(true)
        setLastConnected(response.data.lastConnectedAt)
        setHost(response.data.sftpDetails.host)
        setPort(response.data.sftpDetails.port)
        setUsername(response.data.sftpDetails.user)
        setPassword(response.data.sftpDetails.password)
        setInbound(response.data.sftpDetails.inbound)
        setOutbound(response.data.sftpDetails.outbound)
      } else {
        setError(response.data.message)
      }
    })
  }

  return (
    <div>
      <div className="sftp-connection-details-item">
        <div className="sftp-connection-details-col">
          <p className="sftp-connetino-details-label">Host</p>
          <p className="sftp-connection-details-description">
            This is the host URL for accessing the SFTP server.
          </p>
        </div>
        <div className="sftp-connection-input-col">
          <Textbox
            hideLabel
            value={host}
            onChange={(e) => setHost(e.target.value)}
            placeholder={'sftp.hendricks.so'}
          />
        </div>
      </div>
      <div className="sftp-connection-details-item">
        <div className="sftp-connection-details-col">
          <p className="sftp-connetino-details-label">Port</p>
          <p className="sftp-connection-details-description">
            The port to access the SFTP server. This typically comes when
            receiving the host URL. It usually defaults to 22 or 2022.
          </p>
        </div>
        <div className="sftp-connection-input-col">
          <Textbox
            hideLabel
            value={port}
            onChange={(e) => setPort(e.target.value)}
            placeholder={'22'}
          />
        </div>
      </div>
      <div className="sftp-connection-details-item">
        <div className="sftp-connection-details-col">
          <p className="sftp-connetino-details-label">Username</p>
          <p className="sftp-connection-details-description">
            The username for connecting to the SFTP server.
          </p>
        </div>
        <div className="sftp-connection-input-col">
          <Textbox
            hideLabel
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="user1"
          />
        </div>
      </div>
      <div className="sftp-connection-details-item">
        <div className="sftp-connection-details-col">
          <p className="sftp-connetino-details-label">Password</p>
          <p className="sftp-connection-details-description">
            The password for connecting to the SFTP server.
          </p>
        </div>
        <div className="sftp-connection-input-col">
          <Textbox
            hideLabel
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="****"
          />
        </div>
      </div>
      <div className="sftp-connection-details-item">
        <div className="sftp-connection-details-col">
          <p className="sftp-connetino-details-label">Inbound Directory</p>
          <p className="sftp-connection-details-description">
            The path to the folder in which our system will read files from.
          </p>
        </div>
        <div className="sftp-connection-input-col">
          <Textbox
            hideLabel
            value={inbound}
            onChange={(e) => setInbound(e.target.value)}
            placeholder={'inbound'}
          />
        </div>
      </div>
      <div className="sftp-connection-details-item">
        <div className="sftp-connection-details-col">
          <p className="sftp-connetino-details-label">Outbound Directory</p>
          <p className="sftp-connection-details-description">
            The path to the folder in which our system will write files to
          </p>
        </div>
        <div className="sftp-connection-input-col">
          <Textbox
            hideLabel
            value={outbound}
            onChange={(e) => setOutbound(e.target.value)}
            placeholder={'outbound'}
          />
        </div>
      </div>
      <div className="sftp-connection-metadata-wrapper">
        <p>Verified Connection: {verified ? 'Yes' : 'No'}</p>
        <p>
          Last Connected At:{' '}
          {lastConnected ? `${timeSince(new Date(lastConnected))} ago` : 'N/A'}
        </p>
      </div>
      <div>
        {error && error !== '' && <TextboxError>{error}</TextboxError>}
        <button className="create-creds-btn" onClick={onCreateCredentials}>
          {creating ? (
            <span>Creating...</span>
          ) : (
            <span>Create Credentials</span>
          )}
        </button>
      </div>
    </div>
  )
}

const SupportSection = ({ showSupportSaved, ediId, viewToken, ediPartner }) => {
  const [showSupport, setShowSupport] = useState(showSupportSaved || false)
  const [showNo, setShowNo] = useState(false)
  const [settingYes, setSettingYes] = useState(false)

  const onClickNo = (e) => {
    e.preventDefault()
    setShowNo(true)
  }

  const onClickYes = (e) => {
    e.preventDefault()
    setSettingYes(true)
    setShowSupport(true)

    updateInstall(ediId, viewToken, {
      showSupport: true,
    }).then((response) => {
      setSettingYes(false)
      if (response.success) {
        console.log('Successful')
      } else {
        setShowSupport(false)
      }
    })
  }

  if (showSupport) {
    return (
      <div style={{ height: 660 }}>
        <InlineWidget url="https://calendly.com/hello-hendricks/30min" />
      </div>
    )
  }

  if (showNo) {
    return (
      <div className="support-questionaire-card">
        <p className="support-questionaire-card-next-steps">
          We recommend connecting with the {ediPartner} team before doing the
          EDI setup.
        </p>
      </div>
    )
  }

  return (
    <div className="support-questionaire-card">
      <div>
        <p className="support-questionaire-card-question">
          Have you already connected with {ediPartner} and begun onboarding?
        </p>
      </div>
      <div className="support-questionaire-buttons">
        <button
          className="support-questionaire-button support-questionaire-button-yes"
          onClick={onClickYes}
        >
          Yes
        </button>
        <button className="support-questionaire-button" onClick={onClickNo}>
          No
        </button>
      </div>
    </div>
  )
}

const EDIMainPage = () => {
  const [loading, setLoading] = useState(true)
  const { ediRetailerId } = useParams()
  const [majorError, setMajorError] = useState(null)
  const [error, setError] = useState(null)
  const [details, setDetails] = useState({})

  console.log('ediRetailerId', ediRetailerId)

  // Grab viewToken from query params
  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  const fetchStatusChange = () => {
    getEDIRetailerDetails(ediRetailerId, viewToken, {}).then((response) => {
      if (
        response.success &&
        details &&
        details.status !== response.data.details.status
      ) {
        setDetails(response.data.details)
      } else {
        setMajorError(response.data.message)
      }
    })
  }

  useEffect(() => {
    getEDIRetailerDetails(ediRetailerId, viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setDetails(response.data.details)
      } else {
        setMajorError(response.data.message)
      }
    })
  }, [])

  console.log('window.location', window.location)

  if (loading) {
    return (
      <InternalAppPage>
        <div>Loading...</div>
      </InternalAppPage>
    )
  }

  if (majorError) {
    return (
      <InternalAppPage>
        <div>{majorError}</div>
      </InternalAppPage>
    )
  }

  console.log('EDI Main Page', window.location)
  return (
    <InternalAppPage>
      <>
        <div className="row edi-retailer-logo-row">
          <div className="col-12">
            <div>
              <img src={details.logoUrl} className="edi-retailer-logo" />
            </div>
          </div>
        </div>
        <div className="row section-row">
          <div className="col-12">
            <div className="edi-retailer-details">
              <p className="edi-retailer-details-key-col">Status:</p>
              <p className="edi-retailer-details-value-col">
                {details.status === 'active' ? (
                  <span className="edi-retailer-status edi-retailer-status-active">
                    Active
                  </span>
                ) : (
                  <span className="edi-retailer-status edi-retailer-status-inactive">
                    Pending
                  </span>
                )}
                {details.statusReason && (
                  <span className="status-next-step">
                    {details.statusReason}
                  </span>
                )}
              </p>
            </div>
            <div className="edi-retailer-details">
              <p className="edi-retailer-details-key-col">Retailer:</p>
              <p className="edi-retailer-details-value-col">
                <a
                  href={`https://app.hendricks.so/?orgId=${details.retailerOrgId}`}
                >
                  {details.retailerName}
                </a>
                {}
              </p>
            </div>
            <div className="edi-retailer-details">
              <p className="edi-retailer-details-key-col">Partnership:</p>
              <p className="edi-retailer-details-value-col">
                <a
                  href={`https://app.hendricks.so/partners/${details.partnerId}`}
                >
                  {details.retailerName} {'<>'} You
                </a>
                {details.partnerDeleted && (
                  <span className="has-been-deleted">Has been deleted</span>
                )}
              </p>
            </div>
            <div>
              <p className="edi-retailer-details-help-text">
                We create a managed organization for {details.ediPartner} that
                you have access to. This is for 1{')'} Edit product details
                specific to {details.ediPartner}. 2{')'} Provides a way to see
                wahts happening with EDI (Ex. Order changes). If you delete the
                partnership, you will need to re-install the app. If you are
                pausig the trading partnership, you can mark it as inactive.
                When it is inactive, all products are not available.
              </p>
            </div>
          </div>
        </div>
        <div className="row section-row">
          <div className="col-12">
            <h2 className="section-title">Products</h2>
            <p className="section-title-subtext">
              Check the status of product errors within the retailer account.
            </p>

            {details.failingValidation ? (
              <div className="product-validation-check">
                <i className="bi bi-x product-validation-check-failing"></i>
                <span className="product-validation-check-failing">
                  Some Products are Failing Validation
                </span>
                <button>Fix</button>
              </div>
            ) : (
              <div className="product-validation-check">
                <i className="bi bi-check product-validation-check-passing"></i>
                <span className="product-validation-check-passing">
                  All Products are passing validation
                </span>
              </div>
            )}
            <p className="edi-retailer-details-help-text">
              We added a number of product validation rules to the{' '}
              <a
                href={`https://app.hendricks.so/products?orgId=${details.retailerOrgId}`}
              >
                {details.retailerName}
              </a>{' '}
              organization that allow you to meet Home Hardware EDI
              requirements. You can either adjust the product information in
              your system (ie. PIM, Shopify, etc.), or in the{' '}
              <a
                href={`https://app.hendricks.so/products?orgId=${details.retailerOrgId}`}
              >
                {details.retailerName}
              </a>{' '}
              organization (This limits the changes to just this EDI
              partnership).
            </p>
          </div>
        </div>
        <div className="row section-row">
          <div className="col-12">
            <h2 className="section-title">Support</h2>
            <p className="section-title-subtext">
              Our commitment is to make the EDI onboarding process as steamless
              as possible for what we can control. We are happy to help you set
              it up.
            </p>
            <SupportSection
              showSupportSaved={details.showSupport || false}
              ediId={ediRetailerId}
              viewToken={viewToken}
              ediPartner={details.ediPartner || ''}
            />
          </div>
        </div>
        <div className="row section-row">
          <div className="col-12">
            <h2 className="section-title">Connection</h2>
            <p className="section-title-subtext">
              Details about the SFTP server being used to exchange files.
            </p>
            <SFTPConnectionForm
              details={details}
              ediId={ediRetailerId}
              viewToken={viewToken}
            />
          </div>
        </div>
        {/* <div className="row">
                    <div className="col-12">
                        <h2 className="section-title">Additional Resources</h2>
                        <p>Below are a list of additional resources that should help clarify the process.</p>
                    </div>
                </div> */}
      </>
    </InternalAppPage>
  )
}

export default EDIMainPage
