import { get, post, patch, deleteAPI } from './general'

export const createPricing = (params) => {
  return post(`/pricing`, {}, params)
}

export const listPriceLists = (params) => {
  return get(`/pricing`, {}, params)
}

export const getPricingById = (id, params) => {
  return get(`/pricing/${id}`, {}, params)
}

export const listPricingEntries = (id, params) => {
  return get(`/pricing/${id}/entries`, {}, params)
}

export const searchProductsForPricing = (id, params) => {
  return get(`/supplier-products/search-products-for-pricing/${id}`, {}, params)
}

export const searchVariantsForPricing = (id, params) => {
  return get(`/supplier-products/search-variants-for-pricing/${id}`, {}, params)
}

export const downloadPricingCSV = (params) => {
  return get(`/supplier-products/download-pricing-csv`, {}, params)
}

export const uploadPricingCSV = (id, body) => {
  return post(`/supplier-products/upload-pricing-csv/${id}`, {}, body)
}

export const updateBasePrice = (id, body) => {
  return patch(`/pricing/${id}/entries/base-price`, {}, body)
}

export const updateRetailPrice = (id, body) => {
  return patch(`/pricing/${id}/entries/retail-price`, {}, body)
}

export const deletePricingEntry = (pricingId, id) => {
  return deleteAPI(`/pricing/${pricingId}/entries/${id}`)
}