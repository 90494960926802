import { useState, useEffect } from 'react'
import {
  ShoppingBag,
  MessageCircle,
  Settings,
  Bell,
  Home,
  Users,
  DollarSign,
  List,
  ShoppingCart,
  Truck,
  Layout,
} from 'lucide-react'

import Logo from 'components/Logo/Logo'
import LogoIcon from 'components/Logo/LogoIcon'
import CompanySelector from './CompanySelector'

import {
  getCompaniesAvailable as getStoredCompaniesAvailable,
  getCompanyId,
} from 'utils/cookies'
import {
  listOrganizations,
  getCurrentOrganization,
} from 'utils/api/organizations'
import { listNotifications } from 'utils/api/notifications'
import { getLeftMenu } from 'utils/api/apps'

import './Page.css'
import './LeftMenu.css'

const Page = ({ children }) => {
  const storedCompaniesAvailable = getStoredCompaniesAvailable()
  const [companiesAvailable, setCompaniesAvailable] = useState(
    storedCompaniesAvailable
  )
  const [selectedCompany, setSelectedCompany] = useState(getCompanyId())
  const [currentCompany, setCurrentCompany] = useState(null)
  const [pinnedApps, setPinnedApps] = useState([])
  const [isMoreApps, setIsMoreApps] = useState(false)
  const [loadingPinnedApps, setLoadingPinnedApps] = useState(true)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    listOrganizations().then((response) => {
      console.log('listOrganizations', response)
      if (response.success) {
        setCompaniesAvailable(response.data.organizations)
      }
    })

    if (selectedCompany) {
      getCurrentOrganization(selectedCompany).then((response) => {
        if (response.success) {
          setCurrentCompany(response.data.org)
        }
      })
      getLeftMenu().then((response) => {
        if (response.success) {
          setPinnedApps(response.data.installs || [])
          setIsMoreApps(response.data.isMoreApps)
        }
        setLoadingPinnedApps(false)
      })
      listNotifications({
        limit: 1,
        page: 1,
      }).then((response) => {
        if (response.success) {
          setNotifications(response.data.notifications)
        }
      })
    }
  }, [])

  return (
    <div className="wrapper">
      <nav id="sidebar" className="sidebar">
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="/">
            <Logo size="md" />
          </a>
        </div>
        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <a className="sidebar-link" href="/">
              <Home /> <span className="align-middle">Dashboard</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a className="sidebar-link" href="/partners/">
              <Users /> <span className="align-middle">Partners</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a className="sidebar-link" href="/pricing/">
              <DollarSign /> <span className="align-middle">Pricing</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a className="sidebar-link" href="/products/">
              <List /> <span className="align-middle">Products</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a className="sidebar-link" href="/orders/">
              <ShoppingCart /> <span className="align-middle">Orders</span>
            </a>
          </li>
          {/* <li className="sidebar-item">
            <a className="sidebar-link" href="/fulfillments/">
              <Truck /> <span className="align-middle">Fulfillments</span>
            </a>
          </li> */}
          <li className="sidebar-item">
            <a className="sidebar-link" href="/app-store/">
              <Layout /> <span className="align-middle">App Store</span>
            </a>
          </li>

          {pinnedApps && pinnedApps.length > 0 && (
            <>
              <li className="sidebar-header">Apps</li>
              {pinnedApps.map((app) => (
                <li className="sidebar-item">
                  <a className="sidebar-link" href={app.url}>
                    <div className="left-menu-app-icon">
                      <img
                        src={app.iconUrl}
                        alt={app.name}
                        width={20}
                        height={20}
                      />
                    </div>{' '}
                    <span className="left-menu-nav-text">{app.name}</span>
                  </a>
                </li>
              ))}

              {isMoreApps && (
                <li className="sidebar-item">
                  <a
                    href="/apps/"
                    className="nav-link align-middle px-0 menu-nav-item-link"
                  >
                    <i className="left-menu-nav-icon bi-three-dots"></i>{' '}
                    <span className="left-menu-nav-text">All Apps</span>
                  </a>
                </li>
              )}
            </>
          )}
        </ul>

        <ul className="sidebar-nav bottom-side-bar-menu">
          <li className="sidebar-item ">
            <a className="sidebar-link" href="/notifications">
              <div className="position-relative">
                <Bell />

                {notifications && notifications.length > 0 && (
                  <span className="side-item-indicator">
                    {notifications.length}
                  </span>
                )}
                <span className="align-middle">Notifications</span>
              </div>
            </a>
          </li>
          <li className="sidebar-item">
            <a className="sidebar-link" href="/settings">
              <Settings /> <span className="align-middle">Settings</span>
            </a>
          </li>
          <li className="sidebar-item">
            {companiesAvailable && companiesAvailable.length > 0 && (
              <CompanySelector
                companiesAvailable={companiesAvailable}
                companyId={getCompanyId()}
              />
            )}
          </li>
        </ul>
      </nav>
      <div className="main">
        <main className="content" style={{ paddingBottom: 0 }}>
          <div className="container-fluid p-0">{children}</div>
        </main>
      </div>
    </div>
  )
}

export default Page
