import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import Page from 'components/Page/Page'
import { listSFTPFiles, uploadSFTPFile, deleteSFTPFiles } from 'utils/api/sftp'

import Button from 'components/Button/Button'
import TextboxError from 'components/Textbox/TextboxError'

import './SFTPListFilesPage.css'

const SFTPListFileLoading = () => (
  <div className="sftp-list-files-loading-item-wrapper">
    <div className="sftp-list-files-details-col">
      <div className="sftp-list-files-checkbox-loading"></div>
      <div className="sftp-list-files-icon-loading"></div>
      <div className="sftp-list-files-name-loading"></div>
    </div>
    <div className="sftp-list-files-actions-col"></div>
  </div>
)

const getCurrentFiles = (files, path) => {
  console.log('getCurrentFiles', files, path)
  // If path prefix does start with /, add it
  if (path && path[0] !== '/') {
    path = `/${path}`
  }
  const pathParts = path.split('/')
  const results = []
  for (let i = 0; i < pathParts.length; i++) {
    const part = pathParts[i]
    if (files[part]) {
      files = files[part]
    } else {
      break
    }
  }

  for (let key in files) {
    if (key === '') {
      continue
    }
    results.push({
      fileName: key,
      type:
        files[key].path && files[key].fileName && files[key].fileDir
          ? 'file'
          : 'dir',
      path: files[key].path,
    })
  }
  console.log('Results', results)
  return results
}

const DeleteFileButton = ({ username, path, fetchFiles }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onDeleteFile = (e) => {
    console.log('Delete File', username, path)
    e.preventDefault()
    setLoading(true)
    deleteSFTPFiles(username, {
      paths: [path],
    }).then((response) => {
      if (response.success) {
        fetchFiles()
      } else {
        setError(response.data.message)
        window.alert(response.data.message)
      }
      setLoading(false)
    })
  }

  if (loading) {
    return (
      <button className="sftp-list-files-actions-btn">
        <div className="spinner-border" role="status"></div>
      </button>
    )
  }

  return (
    <button className="sftp-list-files-actions-btn" onClick={onDeleteFile}>
      <i className="bi bi-trash"></i>
    </button>
  )
}

const SFTPListFilesPage = () => {
  // Pull param
  const { username } = useParams()
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [currentPath, setCurrentPath] = useState(
    new URLSearchParams(window.location.search).get('path') || ''
  )
  const fileInput = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState('')
  const [selected, setSelected] = useState([])

  useEffect(() => {
    fetchFiles()
  }, [])

  const fetchFiles = () => {
    listSFTPFiles(username, {}).then((response) => {
      if (response.success) {
        const results = {} // Each level is the key, the value is the file obj
        for (let i = 0; i < (response.data.files || []).length; i++) {
          const file = response.data.files[i]
          const pathParts = file.path.split('/')
          let currentLevel = results
          pathParts.forEach((part, index) => {
            if (index === pathParts.length - 1) {
              currentLevel[part] = file
            } else {
              if (!currentLevel[part]) {
                currentLevel[part] = {}
              }
              currentLevel = currentLevel[part]
            }
          })
        }
        setFiles(results)
      }
      setLoading(false)
    })
  }

  const onClickFolder = (e, folder) => {
    console.log('Folder', folder)
    e.preventDefault()

    // Push to path query parameter
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('path', folder)
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${searchParams.toString()}`
    )
    setCurrentPath(folder)
  }

  const onUpParentDir = (e) => {
    e.preventDefault()
    const searchParams = new URLSearchParams(window.location.search)
    const path = searchParams.get('path') || ''
    const pathParts = path.split('/')
    pathParts.pop()
    searchParams.set('path', pathParts.join('/'))
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${searchParams.toString()}`
    )
    setCurrentPath(pathParts.join('/'))
  }

  console.log('Files', files)

  const onUploadFile = () => {
    fileInput.current.click()
  }

  const onChangeUploadFile = (event) => {
    setUploading(true)
    const file = event.target.files[0]
    // console.log('file', currentPath, file)

    // Convert the file to base64 text
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64Text = reader.result.split(',')[1]

      uploadSFTPFile(username, {
        content: base64Text,
        filePath: `${currentPath}/${file.name}`,
        fileType: file.type,
      }).then((response) => {
        if (response.success) {
          fetchFiles()
        } else {
          setError(response.data.message)
        }
        setUploading(false)

        // Reset the file input
        fileInput.current.value = null
      })
    }
  }

  const onSelectAll = (e) => {
    if (e.target.checked) {
      const allFiles = getCurrentFiles(files, currentPath)
      setSelected(allFiles.map((file) => file.path))
    } else {
      setSelected([])
    }
  }

  const onSelect = (e, path) => {
    if (e.target.checked && path) {
      setSelected([...selected, path])
    } else if (path) {
      setSelected(selected.filter((item) => item !== path))
    }
  }

  console.log(
    'getCurrentFiles(files, currentPath)',
    selected,
    getCurrentFiles(files, currentPath)
  )
  return (
    <Page>
      <div className="container">
        <div className="row install-details-header-row">
          <div className="col-md-1"></div>
          <div className="col-md-7">
            <h1 className="install-details-title">{username}</h1>
            <div>
              <Link to="/sftp" className="back-to-dashboard-link">
                <i className="bi bi-chevron-left"></i> Back to SFTP Users
              </Link>
            </div>
          </div>
          <div className="col-md-3 actions-parent-col">
            <div className="actions-col">
              <input
                type="file"
                id="upload-csv"
                accept=".csv"
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={onChangeUploadFile}
              />
              <Button onClick={onUploadFile} loading={uploading}>
                Upload
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="sftp-list-files-divider"></div>
            <div className="sftp-list-files-wrapper">
              {loading ? (
                <div className="sftp-list-files-loading-wrapper">
                  <SFTPListFileLoading />
                  <SFTPListFileLoading />
                  <SFTPListFileLoading />
                  <SFTPListFileLoading />
                  <SFTPListFileLoading />
                  <SFTPListFileLoading />
                  <SFTPListFileLoading />
                </div>
              ) : files.length === 0 ? (
                <div>
                  <p>No Files</p>
                </div>
              ) : (
                <>
                  <div className="sftp-list-details-top-row">
                    <div className="sftp-list-details-top-row-details-col">
                      <input
                        type="checkbox"
                        className="sftp-list-files-checkbox"
                        style={{ display: 'inline-block', marginRight: 5 }}
                        onClick={onSelectAll}
                        checked={
                          selected.length ===
                          getCurrentFiles(files, currentPath).length
                        }
                      />
                      {currentPath !== '' && currentPath !== '/' && (
                        <div style={{ display: 'inline-block' }}>
                          <button
                            onClick={onUpParentDir}
                            className="up-parent-btn"
                          >
                            <i className="bi bi-arrow-up"></i> Up to Parent
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="sftp-list-details-top-row-actions-col">
                      {selected.length > 0 && (
                        <p className="selected-text">
                          {selected.length} Selected
                        </p>
                      )}
                    </div>
                  </div>
                  {error && error !== '' && (
                    <TextboxError>{error}</TextboxError>
                  )}
                  {getCurrentFiles(files, currentPath).map((file) => {
                    console.log('file.path', file)
                    return (
                      <div className="sftp-list-files-list-item">
                        <div className="sftp-list-files-details-col">
                          <input
                            type="checkbox"
                            className="sftp-list-files-checkbox"
                            onClick={(e) => onSelect(e, file.path)}
                            checked={selected.includes(file.path)}
                          />

                          {file.type === 'dir' ? (
                            <button
                              className="sftp-list-files-item-btn"
                              onClick={(e) => onClickFolder(e, file.fileName)}
                            >
                              <i className="bi bi-folder sftp-list-files-icon"></i>
                              <span className="sftp-list-files-name">
                                {file.fileName}
                              </span>
                            </button>
                          ) : (
                            <button className="sftp-list-files-item-btn">
                              <i className="bi bi-file-earmark sftp-list-files-icon"></i>
                              <span className="sftp-list-files-name">
                                {file.fileName}
                              </span>
                            </button>
                          )}
                        </div>
                        <div className="sftp-list-files-actions-col">
                          <DeleteFileButton
                            username={username}
                            path={file.path}
                            fetchFiles={fetchFiles}
                          />
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default SFTPListFilesPage
