import React, { useState } from 'react'

import Textbox from 'components/Textbox/Textbox'
import Button from 'components/Button/Button'

import { createSFTPUser } from 'utils/api/sftp'

import './AddSFTPUserForm.css'
import TextboxError from 'components/Textbox/TextboxError'

const AddSFTPUserForm = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [creating, setCreating] = useState(false)
  const [error, setError] = useState('')

  const onAddUser = (e) => {
    e.preventDefault()

    setCreating(true)
    createSFTPUser({
      username,
      password,
    }).then((response) => {
      if (response.success) {
        window.location.reload()
      } else {
        setError(response.data.message)
      }
      setCreating(false)
    })
  }

  return (
    <div className="add-sftp-user-panel">
      <h2>Add User</h2>
      <form onSubmit={onAddUser}>
        <Textbox
          label="Username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Textbox
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && error !== '' && <TextboxError>{error}</TextboxError>}
        <Button type="submit" loading={creating}>
          Add User
        </Button>
      </form>
    </div>
  )
}

export default AddSFTPUserForm
