import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SharedProductsPage from '../Shared/SharedProductsPage'

import './ListProductsPage.css'
import { Link } from 'react-router-dom'

import { listProducts } from 'utils/api/products'

const SingleProductItem = ({ product, onSelectProduct }) => {
  let navigate = useNavigate()
  const [checked, setChecked] = useState(false)

  const onView = (e) => {
    e.preventDefault()
    navigate(`/products/${product.id}`)
  }

  console.log(
    'product',
    product,
    product.selected && product.isSelectable,
    product.isSelectable
  )

  return (
    <tr>
      <td>
        <div className="form-check fs-4">
          <input
            className="form-check-input"
            type="checkbox"
            value={checked}
            onChange={() => {
              setChecked(!checked)
              onSelectProduct(product)
            }}
          />
          <label className="form-check-label"></label>
        </div>
      </td>
      <td className="d-flex align-items-center">
        <div className="p-2 rounded bg-body-tertiary d-flex justify-content-center align-items-center me-2 w-50px h-50px">
          {product && product.images && product.images.length > 0 && (
            <img
              src={product.images[0].src}
              alt={product.title}
              className="mw-100 mh-100"
            />
          )}
        </div>
        <p className="mb-0">
          <strong>{product.title}</strong>
          <br />
          <span className="text-muted">
            {product.sku && product.sku !== '' && <>SKU: {product.sku}</>}
            {product.sku &&
              product.sku !== '' &&
              product.barcode &&
              product.barcode !== '' && <>{' / '}</>}
            {product.barcode && product.barcode !== '' && (
              <>
                Barcode: {`(${product.barcodeType})`} {product.barcode}
              </>
            )}
          </span>
        </p>
      </td>
      <td>{!product.isSupplier ? <span>{product.brand}</span> : null}</td>
      <td>
        {product.active ? (
          <span
            className="badge badge-subtle-success"
            style={{ marginRight: 5 }}
          >
            Active
          </span>
        ) : (
          <span
            className="badge badge-subtle-danger"
            style={{ marginRight: 5 }}
          >
            Inactive
          </span>
        )}
        {product.selected && product.isSelectable ? (
          <span
            className="badge badge-subtle-primary"
            style={{ marginRight: 5 }}
          >
            Selected
          </span>
        ) : product.isSelectable ? (
          <span
            className="badge badge-subtle-primary"
            style={{ marginRight: 5 }}
          >
            Not Selected
          </span>
        ) : null}
        {product.blocked && (
          <span className="badge badge-subtle-danger">
            {product.blockedByReason}
          </span>
        )}
      </td>
      <td className="text-end">
        <button type="button" className="btn btn-light" onClick={onView}>
          View
        </button>
      </td>
    </tr>
  )
}

const LoadingProducts = () => (
  <div>
    <p>Loading</p>
  </div>
)

const EmptyProducts = () => (
  <div>
    <p>No Products Found</p>
  </div>
)

const ListProductsPage = () => {
  const [search, setSearch] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [products, setProducts] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [page, setPage] = useState(1)

  const fetchProducts = () => {
    listProducts({
      page: page,
      search: search,
    })
      .then((response) => {
        if (response.success) {
          setProducts(response.data.products || [])
        }
        setInitialLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  const onSelectProduct = (product) => {
    if (selectedProducts.includes(product)) {
      setSelectedProducts(selectedProducts.filter((p) => p !== product))
    } else {
      setSelectedProducts([...selectedProducts, product])
    }
  }

  return (
    <SharedProductsPage>
      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
              <div className="input-group input-group-search">
                <input
                  type="text"
                  className="form-control"
                  id="datatables-products-search"
                  placeholder="Search products…"
                />
                <button className="btn" type="button">
                  <i className="align-middle" data-lucide="search"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 col-xl-8">
              <div className="text-sm-end">
                {/* <button type="button" className="btn btn-light btn-lg me-2">
                  <i data-lucide="download"></i> Export
                </button>
                <button type="button" className="btn btn-primary btn-lg">
                  <i data-lucide="plus"></i> New Product
                </button> */}
              </div>
            </div>
          </div>
          <table id="datatables-products" className="table w-100">
            <thead>
              <tr>
                <th className="align-middle">
                  <div className="form-check fs-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="datatables-products-check-all"
                      value={selectAll}
                      onChange={() => setSelectAll(!selectAll)}
                    />
                    <label
                      className="form-check-label"
                      for="datatables-products-check-all"
                    ></label>
                  </div>
                </th>
                <th className="align-middle">Product</th>
                <th className="align-middle">Vendor</th>
                <th className="align-middle">Status</th>
                <th className="align-middle text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {initialLoading ? (
                <LoadingProducts />
              ) : products.length == 0 ? (
                <EmptyProducts />
              ) : (
                <>
                  {products.map((product) => (
                    <SingleProductItem
                      key={product.id}
                      product={product}
                      onSelectProduct={onSelectProduct}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </SharedProductsPage>
  )
}

export default ListProductsPage

/*
 <div className="row search-filters-row">
          <div className="col-6">
            <div className="search-filters-actions-col">
              {!products || products.length == 0 ? null : (
                <>
                  <input
                    type="checkbox"
                    className="serach-filters-select-all-checkbox"
                    value={selectAll}
                    onChange={() => setSelectAll(!selectAll)}
                  />

                  {selectAll ? (
                    <>
                      <button>Mark All as Active</button>
                      <button>Mark All as Selected</button>
                    </>
                  ) : selectedProducts.length > 0 ? (
                    <>
                      <button>
                        Mark Selected {`(${selectedProducts.length})`} as Active
                      </button>
                      <button>
                        Mark Selected {`(${selectedProducts.length})`} as
                        Selected
                      </button>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className="col-6 search-filters-col">
            <div className="search-filters-search-col">
              <div className="search-input-box">
                <i className="bi bi-search"></i>
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <button>
              <i className="bi bi-funnel"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {initialLoading ? (
              <LoadingProducts />
            ) : products.length == 0 ? (
              <EmptyProducts />
            ) : (
              <>
                {products.map((product) => (
                  <SingleProductItem
                    key={product.id}
                    product={product}
                    onSelectProduct={onSelectProduct}
                  />
                ))}
              </>
            )}
          </div>
        </div>
*/
