import Textbox from 'components/Textbox/Textbox'
import React, { useEffect, useState } from 'react'
import {
  getShopifyDetails,
  getShopifyOAuth1,
  getShopifyOAuth1WithViewToken,
  updateShopifyDetails,
  disconnectShop,
} from 'utils/api/internal-apps'

import './ShopifyIDTextbox.css'
import './ShopifyAppPage.css'
import { Check, Pencil } from 'lucide-react'

const ShopifyIDTextbox = ({ value, onChange }) => {
  return (
    <div className="shopify-url-textbox-wrapper">
      <label className="your-shopify-url-label">Your Shopify URL:</label>
      <div className="shopify-url-inner">
        <input type="text" value={value} onChange={onChange} />
        <label className="myshopify-label">.myshopify.com</label>
      </div>
    </div>
  )
}

const ShopifyDetailsTableRow = ({
  type,
  label,
  value,
  onSave,
  options,
  isDisabled,
  disabledView,
}) => {
  const [editedValue, setEditedValue] = useState(value)
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState(false)
  const onToggleEditMode = (e) => {
    e.preventDefault()
    setEditing(!editing)
  }

  const onSaveText = (e) => {
    e.preventDefault()
    onSave(editedValue, (success, error) => {
      if (success) {
        // value, callback
        setEditing(false)
      } else {
        window.alert(error)
      }
    })
  }

  if (isDisabled && disabledView) {
    return (
      <tr>
        <td>{label}</td>
        <td>{disabledView}</td>
        <td></td>
      </tr>
    )
  } else if (isDisabled && !disabledView && type === 'dropdown') {
    return (
      <tr>
        <td>{label}</td>
        <td>{options.find((option) => option.value === value).label}</td>
        <td></td>
      </tr>
    )
  } else if (isDisabled) {
    return (
      <tr>
        <td>{label}</td>
        <td>{value}</td>
        <td></td>
      </tr>
    )
  }

  if (editing) {
    return (
      <tr>
        <td>{label}</td>
        <td>
          {type === 'dropdown' ? (
            <select
              className="form-control"
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label || option.value}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              className="form-control"
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
              placeholder="(Not set)"
            />
          )}
          {error !== '' && (
            <span
              className="badge badge-subtle-danger"
              style={{ marginTop: 5 }}
            >
              {error}
            </span>
          )}
        </td>
        <td>
          <button className="btn" onClick={(e) => onSaveText(e)}>
            <Check />
          </button>
        </td>
      </tr>
    )
  }
  let displayValue = value
  if (options && options.length > 0) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        displayValue = options[i].label
        break
      }
    }
  }
  return (
    <tr>
      <td>{label}</td>
      <td>{displayValue}</td>
      <td>
        <button className="btn" onClick={onToggleEditMode}>
          <Pencil />
        </button>
      </td>
    </tr>
  )
}

const ShopifyAppPage = () => {
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState(null)
  const [connecting, setConnecting] = useState(false)
  const [shopifyId, setShopifyId] = useState('')

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  useEffect(() => {
    getShopifyDetails(viewToken, {}).then((response) => {
      setLoading(false)
      if (response.success) {
        setDetails(response.data)
        setShopifyId(response.data.shopifyId)
        setLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  const onDisconnect = (e) => {
    e.preventDefault()
    setConnecting(true)

    disconnectShop(viewToken, {}).then((response) => {
      setConnecting(false)
      if (response.success) {
        setDetails({ ...details })
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onConnect = (e) => {
    e.preventDefault()
    setConnecting(true)

    getShopifyOAuth1WithViewToken(viewToken, {}).then((response) => {
      setConnecting(false)
      console.log('response', response)
      if (response.success) {
        // Redirect full parent page
        const url = response.data.url
        window.top.location.href = url
      }
    })
  }

  const onSaveDetails = (key, value, callback) => {
    const payload = {}
    payload[key] = value
    updateShopifyDetails(viewToken, payload).then((response) => {
      if (response.success) {
        details[key] = value
        setDetails({ ...details })
        if (callback) {
          callback(true, '')
        }
      } else {
        if (callback) {
          callback(false, response.data.message)
        }
      }
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (!details) {
    return <div>No details found</div>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h2>Shopify</h2>
              <p className="text-muted">
                Manage your Shopify connection details.
              </p>
              <div>
                {details.active ? (
                  <span className="badge rounded-pill bg-success">Active</span>
                ) : (
                  <span className="badge rounded-pill bg-danger">Inactive</span>
                )}
              </div>

              {details.accessType === 'app_store' && (
                <div style={{ marginTop: 20 }}>
                  {shopifyId === '' ? (
                    <>
                      <button className="btn btn-success" disabled={connecting}>
                        Connect my Shopify Shop
                      </button>
                      <div>
                        <span className="badge badge-subtle-danger">
                          Please enter your Shopify URL to connect
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {details.connected ? (
                        <button
                          className="btn btn-outline-danger"
                          disabled={connecting}
                          onClick={onDisconnect}
                        >
                          Disconnect my Shopify Shop
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          disabled={connecting}
                          onClick={onConnect}
                        >
                          Connect my Shopify Shop
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}

              <div style={{ marginTop: 20 }}>
                <table className="table" style={{ width: 500 }}>
                  <tbody>
                    <ShopifyDetailsTableRow
                      label="Shopify URL"
                      value={details.shopifyId || ''}
                      onSave={(value, callback) =>
                        onSaveDetails('shopifyUrl', value, callback)
                      }
                      type="textbox"
                    />
                    <ShopifyDetailsTableRow
                      label="Connection Method"
                      value={details.accessType}
                      details={details}
                      type="dropdown"
                      options={[
                        {
                          label: 'Custom Access Token',
                          value: 'custom',
                        },
                        {
                          label: 'App Store',
                          value: 'app_store',
                        },
                      ]}
                      onSave={(value, callback) => {
                        onSaveDetails('accessType', value, callback)
                      }}
                    />
                    <ShopifyDetailsTableRow
                      label="Access Token"
                      value={'********'}
                      details={details}
                      type="textbox"
                      isDisabled={details.accessType === 'app_store'}
                      disabledView={
                        <>
                          <span className="badge badge-subtle-secondary">
                            Access token is managed by the App Store
                          </span>
                        </>
                      }
                      onSave={(value, callback) => {
                        onSaveDetails('accessToken', value, callback)
                      }}
                    />
                    <ShopifyDetailsTableRow
                      label="Direction"
                      value={details.direction}
                      details={details}
                      type="dropdown"
                      options={[
                        {
                          label: 'Retailer',
                          value: 'retailer',
                        },
                        {
                          label: 'Supplier',
                          value: 'supplier',
                        },
                      ]}
                      isDisabled={true}
                      onSave={(value, callback) => {
                        onSaveDetails('direction', value, callback)
                      }}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopifyAppPage
