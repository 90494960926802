export const displayNumbers = (num) => {
  // Add commas
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const prettifyNumber = (num) => {
  // Above billion
  if (num >= 1000000000) {
    return `${displayNumbers(num / 1000000000)}B`
  }
  // Above million
  if (num >= 1000000) {
    return `${displayNumbers(num / 1000000)}M`
  }
  if (num >= 1000) {
    return `${displayNumbers(num / 1000)}K`
  }
  return displayNumbers(num)
}