import { useState, useEffect } from 'react'
import SharedOrdersPage from '../Shared/SharedOrdersPage'

import { listOrders, listOrderRules } from 'utils/api/orders'

// Partner
// (Timestamp)
//
const ListOrderRulesPage = () => {
  const [rules, setRules] = useState([])
  const [page, setPage] = useState(1)
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const fetchOrders = () => {
    setLoading(true)
    listOrderRules({ page }).then((response) => {
      if (response.success) {
        setRules(response.data.rules || [])
      }
      setLoading(false)
      setInitialLoading(false)
    })
  }

  useEffect(() => {
    fetchOrders()
  }, [page])

  if (initialLoading) {
    return (
      <SharedOrdersPage>
        <div className="spinner-border"></div>
      </SharedOrdersPage>
    )
  }

  return (
    <SharedOrdersPage>
      <>
        <div className="row">
          <div className="col-12">
            <div class="card">
              <div class="card-body">
                <table id="datatables-orders" class="table w-100">
                  <thead>
                    <tr>
                      <th scope="align-middle">Details</th>
                      <th scope="align-middle">Status</th>
                      <th scope="align-middle text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rules.map((rule, index) => (
                      <tr key={rule.id}>
                        <td>
                          <strong>{rule.name}</strong>
                          <br />
                          {rule.description}
                        </td>
                        <td>
                          {rule.active ? (
                            <span class="badge badge-subtle-success">
                              Active
                            </span>
                          ) : (
                            <span class="badge badge-subtle-danger">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td class="text-middle">
                          <a
                            href={`/orders/rules/${rule.id}`}
                            type="button"
                            class="btn btn-light"
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    </SharedOrdersPage>
  )
}

export default ListOrderRulesPage
