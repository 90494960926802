import { get, post, patch } from './general'
import { getCompanyId } from '../cookies'

//   export const createProductCatalog = (body) => {
//     return post(
//       '/product-catalogs',
//       {},
//       body,
//     )
//   }

export const listLocations = (params) => {
  return get(`/locations`, {}, params)
}

export const createLocation = (body) => {
  return post(
    '/locations',
    {},
    body,
  )
}