import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import OnboardingPageNextButtons from './OnboardingPageNextButtons'
import SharedOnboardingPage from './SharedOnboardingPage'

import { onboardingAppsNextUrl } from 'utils/api/organizations'
import { getInstallId, getApp } from 'utils/api/apps'

const OnboardingAppsSetupPage = () => {
  let navigate = useNavigate()
  const [loadingNextUrl, setLoadingNextUrl] = useState(false)
  const [loadingAppSetup, setLoadingAppSetup] = useState(true)
  const [app, setApp] = useState(null)
  let { appId, installId, viewToken } = useParams()

  // Get "previousApps" query string parameters
  const previousAppsStr =
    new URLSearchParams(window.location.search).get('previousApps') || ''
  const previousApps = previousAppsStr.split(',') || []

  useEffect(() => {
    getApp(appId).then((response) => {
      console.log('getApp :: response', response)
      if (response.success) {
        setApp(response.data.app)
        setLoadingAppSetup(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [installId])

  const onNext = (e) => {
    e.preventDefault()
    setLoadingNextUrl(true)
    const additionalApps = [app.id, ...previousApps]

    onboardingAppsNextUrl({
      previousApps: additionalApps,
    }).then((response) => {
      setLoadingNextUrl(false)
      if (response.success) {
        navigate(response.data.nextUrl)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (loadingAppSetup) {
    return <div>Loading...</div>
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={4}>
      <iframe
        src={`${app.appSetupUrl}?viewToken=${viewToken}&page=${window.location.pathname}${(window.location.search || '').replace('?', '&')}`}
        style={{
          width: '100%',
          minHeight: '500px',
          border: 'none',
          // border: '1px solid #ccc',
        }}
      />
      <OnboardingPageNextButtons>
        <button
          className="btn btn-primary"
          disabled={loadingNextUrl}
          onClick={onNext}
        >
          Next
        </button>
      </OnboardingPageNextButtons>
    </SharedOnboardingPage>
  )
}

export default OnboardingAppsSetupPage
