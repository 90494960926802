import React from 'react'

import './Switch.css'

const Switch = ({ value, onChange, label }) => {
  return (
    <div className="switch-outter-wrapper">
      <button className="switch-outter-btn" onClick={onChange}>
        <div
          className={`switch-inner-circle ${value ? 'switch-inner-circle-active' : 'switch-inner-circle-inactive'}`}
        ></div>
      </button>
      {label && <label className="switch-label">{label}</label>}
    </div>
  )
}

export default Switch
