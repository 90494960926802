import { useState } from 'react'

import './ForgotPasswordPage.css'
import TextboxError from 'components/Textbox/TextboxError'

import { sendForgotPasswordEmail, resetPassword } from 'utils/api/login'

const ForgotPasswordPage = () => {
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    if (email === '') {
      setError('Email is required')
      return
    }
    setError('')
    setLoading(true)

    sendForgotPasswordEmail({ email }).then((response) => {
      if (response.success) {
        setEmailSent(true)
      } else {
        setError(response.data.message)
      }
      setLoading(false)
    })
  }

  return (
    <div className="container">
      <div className="row forgot-password-row">
        <div className="col-md-4 offset-md-4 text-center">
          <h1 className="h2">Forgot Password</h1>
          <p className="lead">Enter your email to reset your password.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 offset-md-4">
          {emailSent ? (
            <div
              className="alert alert-success"
              role="alert"
              style={{ padding: 10 }}
            >
              Email sent successfully. Please check your inbox.
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {error && error !== '' && (
                <TextboxError style={{ marginTop: 10 }}>{error}</TextboxError>
              )}
              <div className="form-group">
                <button
                  className="btn btn-lg btn-primary"
                  style={{ width: '100%', marginTop: 10 }}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    'Send Email'
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
