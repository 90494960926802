import { get, post, patch, deleteAPI } from './general'
import { getCompanyId } from '../cookies'

export const listSFTPUsers = (params) => {
  return get('/sftp-server/users', {}, params)
}

export const createSFTPUser = (body) => {
  return post('/sftp-server/users', {}, body)
}

export const listSFTPFiles = (username, params) => {
  return get(`/sftp-server/users/${username}/files`, {}, params)
}

export const uploadSFTPFile = (username, body) => {
  return post(`/sftp-server/users/${username}/files`, {}, body)
}

export const deleteSFTPFiles = (username, body) => {
  return deleteAPI(`/sftp-server/users/${username}/files`, {}, body)
}
