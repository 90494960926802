import './LineGraphSelector.css'

const options = [
  {
    label: 'GMV',
    value: 'gmvTotal',
  },
  {
    label: 'Number of Orders',
    value: 'ordersCount',
  },
  {
    label: 'Partners',
    value: 'activePartnersCount',
  },
]
const LineGraphSelector = ({
  start,
  setStart,
  end,
  setEnd,
  graphTab,
  setGraphTab,
}) => {
  return (
    <div className="line-graph-selector-top-col">
      <div className="line-graph-selector-tabs-col">
        {options.map((option, i) => (
          <button
            key={i}
            className={`line-graph-selector ${option.value === graphTab && 'line-graph-selector-current'}`}
            onClick={(e) => setGraphTab(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
      <div className="line-graph-selector-date-range-col">
        <div className="line-graph-date-input">
          <input
            type="date"
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
        </div>
        <div className="line-graph-date-input-between">
          <label> to </label>
        </div>
        <div className="line-graph-date-input">
          <input
            type="date"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default LineGraphSelector
