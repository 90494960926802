import { ChevronLeft, Download, File, Folder, Trash, X } from 'lucide-react'
import { useEffect, useState } from 'react'

import { listSFTPUserFilesForManagedSFTP } from 'utils/api/internal-apps'

import './ListFilesAndFolders.css'

const getFilesOnCurrentLevel = (files, currentPath) => {
  const currentPathParts = currentPath.split('/')
  const matches = [] // { path: '/inbound/.keep', fileName: '.keep', fileDir: '/inbound', label: 'inbound' }
  for (const file of files) {
    const filePathParts = file.path.split('/')

    // Loop through current path parts and confirm that the file path matches the current path
    let isMatch = true
    for (let i = 0; i < currentPathParts.length; i++) {
      if (currentPathParts[i] !== filePathParts[i]) {
        isMatch = false
        break
      }
    }

    if (isMatch) {
      matches.push({
        label: filePathParts[currentPathParts.length],
        type:
          filePathParts[currentPathParts.length + 1] === undefined
            ? 'file'
            : 'folder',
        ...file,
      })
    }
  }
  return matches
}

const ListFilesAndFolders = ({ selectedUser, viewToken, setSelectedUser }) => {
  const [selectedPath, setSelectedPath] = useState('')
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])

  const fetchFiles = () => {
    listSFTPUserFilesForManagedSFTP(viewToken, selectedUser, {
      path: selectedPath,
    }).then((response) => {
      setFiles(response.data.files)
      // setFiles([])
      setLoading(false)
    })
  }

  const onDrillDown = (e, label) => {
    e.preventDefault()
    setSelectedPath(`${selectedPath}/${label}`)
  }

  const onDrillUp = (e) => {
    e.preventDefault()
    const pathParts = selectedPath.split('/')
    pathParts.pop()
    setSelectedPath(pathParts.join('/'))
  }

  useEffect(() => {
    fetchFiles()
  }, [])

  const currentFiles = getFilesOnCurrentLevel(files, selectedPath)
  console.log('currentFiles', currentFiles)
  return (
    <div className="card">
      <div className="card-body">
        <div className="list-files-top-row">
          <div>
            <h4 className="list-files-selected-user-title">{selectedUser}</h4>
            <button
              className="list-files-back-to-users-btn"
              onClick={(e) => setSelectedUser(null)}
            >
              <ChevronLeft /> Back to Users
            </button>
          </div>
        </div>
        <div>
          <p>
            <b>Current Path: </b>
            <span className="list-files-current-path">
              {selectedPath !== '' ? selectedPath : '/'}
            </span>
          </p>
          {selectedPath !== '' && (
            <button className="back-directory-btn" onClick={onDrillUp}>
              <ChevronLeft /> Back Directory
            </button>
          )}

          <table className="table w-100">
            <thead>
              <tr>
                <td></td>
                <td>Path</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {currentFiles.length === 0 ? (
                <tr>
                  <td colSpan={3} className="text-center">
                    No files or folders found
                  </td>
                </tr>
              ) : (
                <>
                  {(currentFiles || []).map((file, index) => (
                    <tr key={index}>
                      <td colSpan={1} width={50}>
                        {file.type === 'folder' ? <Folder /> : <File />}
                      </td>
                      {file.type === 'folder' ? (
                        <td
                          className="file-label-cell"
                          onClick={(e) => onDrillDown(e, file.label)}
                        >
                          {file.label}
                        </td>
                      ) : (
                        <td>{file.label}</td>
                      )}
                      <td>
                        {file.type === 'folder' ? (
                          // <button className="btn btn-outline-primary">
                          //   <Download />
                          // </button>
                          <></>
                        ) : (
                          <>
                            {/* <button className="btn btn-outline-primary">
                              <Download />
                            </button>
                            <button className="btn btn-outline-danger">
                              <Trash />
                            </button> */}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ListFilesAndFolders
