export const formatDollar = (num) => {
  return (Math.round(num) / 100).toFixed(2)
}

export const displayDollarAmount = (amount, currency) => {
  let symbol = ''
  if (currency === 'USD') {
    symbol = '$'
  } else if (currency === 'CAD') {
    symbol = '$'
  } else if (currency === 'EUR') {
    symbol = '€'
  } else if (currency === 'GBP') {
    symbol = '£'
  }
  return `${symbol}${formatDollar(amount)} ${currency} `
}
