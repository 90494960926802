export const stripeKey =
  'pk_live_51Ob1ZLLPnsWplCw1r6LSol8kN7iatphT39RVl8SGaY6nJKQnOi3XnuHF1soAt3ti1Pc8udTUsEWOwK8P1gqZn42x00mtOXvWlG'

// pk_test_51Ob1ZLLPnsWplCw1pZ0lyv4R8PGaNNkacscv3vUp7IGAK1HjBc0wOr2U4EmPv51HA1mqrk19Yk1AabKi6JCmO3F50082UnKLB5

export const getStripeKey = () => {
  if (window.location.href.includes('localhost')) {
    return 'pk_test_51Ob1ZLLPnsWplCw1pZ0lyv4R8PGaNNkacscv3vUp7IGAK1HjBc0wOr2U4EmPv51HA1mqrk19Yk1AabKi6JCmO3F50082UnKLB5'
  }
  return stripeKey
}
