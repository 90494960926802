import React, { useState, useEffect } from 'react'
import { getShopifyOAuth1 } from 'utils/api/internal-apps'

const ShopifyOAuthCallback = () => {
  const [error, setError] = useState(null)
  // Get code from query parameters
  const search = window.location.search
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const state = params.get('state')
  const shop = params.get('shop')
  const hmac = params.get('hmac')
  const timestamp = params.get('timestamp')
  const host = params.get('host')

  const [saving, setSaving] = useState(false)

  const onRunStep2OfOAuth = () => {
    if (saving) {
      return
    }
    if (error && error !== '') {
      return
    }

    setSaving(true)

    getShopifyOAuth1({
      code,
      state,
      shop,
      hmac,
      timestamp,
      host,
    }).then((response) => {
      if (response.success && response.data.redirect) {
        window.location.href = response.data.redirect
      } else if (response.success) {
        window.location.href = '/?success=shopify-connected'
      } else {
        setError(response.data.message)
      }
    })
  }

  useEffect(() => {
    onRunStep2OfOAuth()
  }, [])

  if (!code || !state) {
    return (
      <div>
        <p>Invalid request</p>
      </div>
    )
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    )
  }

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  )
}

export default ShopifyOAuthCallback
