import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Page from 'components/Page/Page'
import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import Textbox from 'components/Textbox/Textbox'

import AddSFTPUserForm from './AddSFTPUserForm'

import { listSFTPUsers } from 'utils/api/sftp'

import './SFTPListUsersPage.css'

const SingleUser = ({ user }) => {
  return (
    <div className="sftp-list-user-single-user-outter">
      <Link to={`/sftp/${user.username}`} style={{ textDecoration: 'none' }}>
        <div className="sftp-list-user-single-user">
          <div className="sftp-list-user-single-user-icon-col">
            <div>
              <i className="bi bi-person"></i>
            </div>
          </div>
          <div className="sftp-list-user-single-user-details-col">
            <p className="sftp-list-user-single-user-details-username">
              {user.username}
            </p>
            <p className="sftp-list-user-single-user-details-date">
              {user.createdAt || ''}
            </p>
          </div>
          <div className="sftp-list-user-single-user-actions-col">
            <i className="bi bi-chevron-right" style={{ marginLeft: 10 }}></i>
          </div>
        </div>
      </Link>
    </div>
  )
}

const EmptyUsers = ({ onToggleAddUserModal }) => (
  <div className="sftp-list-empty-users-wrapper">
    <p className="sftp-list-empty-users-line1">
      It doesn't look like you have any users yet.
    </p>
    <Button onClick={onToggleAddUserModal}>Add User</Button>
  </div>
)

const LoadingUser = () => (
  <div className="sftp-list-user-single-user-outter">
    <div className="sftp-list-user-single-user">
      <div className="sftp-list-user-single-user-icon-col">
        <div className="sftp-list-user-loading-icon shine"></div>
      </div>
      <div className="sftp-list-user-single-user-details-col">
        <div className="sftp-list-user-loading-detials-line1"></div>
        <div className="sftp-list-user-loading-detials-line2"></div>
      </div>
      <div className="sftp-list-user-single-user-actions-col">
        <div className="sftp-list-user-loading-connect-btn"></div>
      </div>
    </div>
  </div>
)

const LoadingUsers = () => (
  <div>
    <LoadingUser />
    <LoadingUser />
    <LoadingUser />
    <LoadingUser />
    <LoadingUser />
    <LoadingUser />
  </div>
)

const SFTPListUsersPage = () => {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [showAddUserModal, setShowAddUserModal] = useState(false)

  useEffect(() => {
    listSFTPUsers({}).then((response) => {
      if (response.success) {
        setUsers(response.data.users || [])
      }
      setLoading(false)
    })
  }, [])

  const onToggleAddUserModal = () => {
    setShowAddUserModal(!showAddUserModal)
  }

  return (
    <Page>
      <div className="container">
        <div className="row install-details-header-row">
          <div className="col-md-1"></div>
          <div className="col-md-7">
            <h1 className="install-details-title">SFTP</h1>
            <div>
              <Link to="/" className="back-to-dashboard-link">
                <i className="bi bi-chevron-left"></i> Back to Dashboard
              </Link>
            </div>
          </div>
          <div className="col-md-3 actions-parent-col">
            <div className="actions-col">
              {users.length > 0 && (
                <Button onClick={onToggleAddUserModal}>Add User</Button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            {loading ? (
              <LoadingUsers />
            ) : users.length === 0 ? (
              <EmptyUsers onToggleAddUserModal={onToggleAddUserModal} />
            ) : (
              users.map((user, index) => <SingleUser key={index} user={user} />)
            )}
          </div>
        </div>
      </div>
      {showAddUserModal && (
        <Modal toggle={onToggleAddUserModal}>
          <AddSFTPUserForm />
        </Modal>
      )}
    </Page>
  )
}

export default SFTPListUsersPage
