import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Page from 'components/Page/Page'

import { getInstallId } from 'utils/api/apps'

const AppsViewerPage = () => {
  const [loading, setLoading] = useState(true)
  const [appInstall, setAppInstall] = useState(null)

  let { appId, installId, viewToken } = useParams()

  useEffect(() => {
    getInstallId(appId, installId, {}).then((response) => {
      if (response.success) {
        console.log('response.data.install', response.data.install)
        setAppInstall(response.data.install)
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <Page>
        <p>Loading...</p>
      </Page>
    )
  }

  if (!appInstall || !appInstall.iframeUrl) {
    return (
      <Page>
        <p>App not found</p>
      </Page>
    )
  }

  const url = `${appInstall.iframeUrl}?viewToken=${viewToken}`
  console.log('url', url)
  return (
    <Page>
      <div className="row">
        <div className="col-12">
          <iframe
            src={url}
            style={{
              width: '100%',
              minHeight: '100vh',
              border: 'none',
              // border: '1px solid #ccc',
            }}
          />
        </div>
      </div>
    </Page>
  )
}

export default AppsViewerPage
