import React, { useEffect, useState } from 'react'

import SettingsPage from '../SettingsPage'
import Textbox from 'components/Textbox/Textbox'
import Button from 'components/Button/Button'

import { getMe, updateUser } from 'utils/api/users'

const SettingsProfilePage = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [company, setCompany] = useState(null)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const changed = user && name !== user.name

  useEffect(() => {
    getMe({}).then((response) => {
      setLoading(false)
      if (response.success) {
        setUser(response.data.user)
        setCompany(response.data.company)
        setName(response.data.user.name)
      }
    })
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()
    setNameError('')
    if (name === '') {
      setNameError('Name cannot be empty')
      return
    }

    setLoading(true)
    updateUser({
      name,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        setUser(response.data.user)
      } else {
        setNameError(response.data.message)
      }
    })
  }

  if (!user) {
    return (
      <SettingsPage loading={loading}>
        <span />
      </SettingsPage>
    )
  }

  return (
    <SettingsPage loading={loading} developerMode={company.developerMode || false}>
      <h2 className="settings-section-title">Account</h2>
      <p className="settings-section-title-subtext">
        Update your account information
      </p>
      <form onSubmit={onSubmit}>
        <Textbox
          placeholder="Email"
          value={user.email}
          readOnly
          textboxStyle={{ maxWidth: 300 }}
          helpText="You cannot change your email."
        />
        <Textbox
          placeholder="Your name"
          value={name}
          textboxStyle={{ maxWidth: 300 }}
          onChange={(e) => setName(e.target.value)}
          error={nameError}
          readOnly={loading}
        />

        {changed && (
          <div className="settings-save-btn-wrapper">
            <Button loading={loading}>Save</Button>
          </div>
        )}
      </form>
      <br />
      <br />
      <br />

      <div>
        <Button link="/logout">Logout</Button>
      </div>
    </SettingsPage>
  )
}

export default SettingsProfilePage
