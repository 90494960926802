import React, { useState, useEffect } from 'react'

import {
  getManagedSFTPServerDetails,
  createSFTPUserForManagedSFTP,
} from 'utils/api/internal-apps'

import Textbox from 'components/Textbox/Textbox'
import Textarea from 'components/Textbox/Textarea'
import TextboxError from 'components/Textbox/TextboxError'

import ListFilesAndFolders from './ListFilesAndFolders'

import './ManagedSFTPPage.css'

const SFTPUsers = ({ setSelectedUser, details, viewToken, fetchDetails }) => {
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    setSaving(true)

    createSFTPUserForManagedSFTP(viewToken, {
      username,
      password,
    }).then((response) => {
      if (response.success) {
        setUsername('')
        setPassword('')
        setShowCreateUser(false)
        fetchDetails()
      } else {
        setError(response.data.message)
      }
      setSaving(false)
    })
  }

  if (showCreateUser) {
    return (
      <div className="card managed-sftp-server-users-card">
        <div className="card-body">
          <h2>Create User</h2>
          <p className="text-muted">
            Create a new user to access the SFTP server.
          </p>
          <br />
          <form onSubmit={onSubmit}>
            <Textbox
              label="Username"
              name="username"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Textbox
              label="Password"
              name="password"
              placeholder="Enter password"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && error !== '' && <TextboxError>{error}</TextboxError>}
            <button className="btn btn-primary" disabled={saving}>
              {saving ? 'Creating...' : 'Create User'}
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() => setShowCreateUser(false)}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div className="card managed-sftp-server-users-card">
      <div className="card-body">
        <div className="managed-sftp-server-users-create-users-row">
          <div>
            <p>
              <strong>Host:</strong> <code>sftp.example.com</code>
            </p>
            <p>
              <strong>Port:</strong> <code>22</code>
            </p>
          </div>
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={() => setShowCreateUser(true)}
            >
              Create User
            </button>
          </div>
        </div>
        <br />
        <table className="table w-100 dataTable">
          <thead>
            <tr role="row">
              <th>Username</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {details && details.sftpUsers && details.sftpUsers.length > 0 ? (
              <>
                {details.sftpUsers.map((user) => (
                  <tr key={user.userName}>
                    <td>
                      <code>{user.userName}</code>
                    </td>
                    <td style={{ width: 90 }}>
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => setSelectedUser(user.userName)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ManagedSFTPPage = () => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState(null)

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  const fetchDetails = () => {
    getManagedSFTPServerDetails(viewToken, {}).then((response) => {
      if (response.success) {
        setDetails(response.data.details)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row managed-sftp-server-title-row">
        <div className="col-12">
          <h1>Managed SFTP Server</h1>
          <p cassName="text-muted">
            Managed SFTP Server is used for securely transferring files.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : selectedUser ? (
            <ListFilesAndFolders
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              viewToken={viewToken}
            />
          ) : (
            <SFTPUsers
              setSelectedUser={setSelectedUser}
              details={details}
              fetchDetails={fetchDetails}
              viewToken={viewToken}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ManagedSFTPPage
