import { Check, X } from 'lucide-react'

export const Syncing = ({ text, status }) => {
  return (
    <div className="edi-demo-syncing-wrapper">
      {status === 'success' ? (
        <Check style={{ position: 'relative', top: 2, marginRight: 5 }} />
      ) : status === 'error' ? (
        <X style={{ position: 'relative', top: 2, marginRight: 5 }} />
      ) : (
        <div className="spinner-border text-primary" role="status"></div>
      )}
      <span className="edi-demo-syncing-text">{text}</span>
    </div>
  )
}
