import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import SettingsPage from '../SettingsPage'
import Textbox from 'components/Textbox/Textbox'
import CheckoutForm from './CheckoutForm'
import AddAccountCredit from './AddAccountCredit'

import { getMe } from 'utils/api/users'
import { deleteCreditCard, getCurrentMonthBilling } from 'utils/api/organizations'
import { displayDollarAmount, formatDollar } from 'utils/money'
import { getStripeKey } from 'utils/constants'
import { Trash } from 'lucide-react'

import './SettingsBillingPage.css'
import { format } from 'prettier'


const promise = loadStripe(getStripeKey())

const CurrentMonthReceipt = () => {
  const [ loading, setLoading ] = useState(true)
  const [ receipt, setReceipt ] = useState(null)
  const [majorError, setMajorError] = useState(null)

  useEffect(() => {
    getCurrentMonthBilling().then((response) => {
      setLoading(false)
      if (response.success) {
        setReceipt(response.data.receipt)
      } else {
        setMajorError(response.data.message)
      }
    })
  }, [])

  if (loading) {
    return <p>Loading...</p>
  }

  if (majorError) {
    return <p>{majorError}</p>
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Line Item Cost</th>
          </tr>
        </thead>
        <tbody>
          {
            (receipt.lineItems).map((lineItem) => (
              <tr key={lineItem.id}>
                <td>
                  <span style={{ width: '100%', display: 'block' }}>
                    {lineItem.description}
                  </span>
                  <span style={{ fontSize: 10, paddingLeft: 0, width: '100%', display: 'block' }}>
                    Total GMV: {displayDollarAmount(lineItem.billedPeriodGMV, 'USD')}
                  </span>
                </td>
                <td>{displayDollarAmount(lineItem.amountInUSD, receipt.currency)}</td>
              </tr>
            ))
          }
          <tr>
            <td>Subtotal</td>
            <td>{displayDollarAmount(receipt.subtotal, receipt.currency)}</td>
          </tr>
          <tr>
            <td>Taxes</td>
            <td>{displayDollarAmount(receipt.taxes, receipt.currency)}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{displayDollarAmount(receipt.total, receipt.currency)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const SettingsBillingPage = () => {
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [cardSaved, setCardSaved] = useState(false)

  useEffect(() => {
    getMe({}).then((response) => {
      setLoading(false)
      if (response.success) {
        setCompany(response.data.company)
      }
    })
  }, [])

  const deleteCard = (e) => {
    e.preventDefault()
    deleteCreditCard().then((response) => {
      if (response.success) {
        window.location.reload()
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (!company) {
    return (
      <SettingsPage loading={loading}>
        <span />
      </SettingsPage>
    )
  }

  return (
    <SettingsPage loading={loading} developerMode={company.developerMode || false}>
      <h2 className="settings-section-title">Account Credit</h2>
      <p>Balance: ${formatDollar(company.accountCredit || 0)}</p>

      {company.billingSetup && <AddAccountCredit />}

      <hr />
      <h2 className="settings-section-title">Payment Method</h2>
      {company.billingSetup ? (
        <>
          <p>
            Your payment method:{' '}
            {
              (company.cardBrand === 'visa') ? (
                <img src="/imgs/cc/visa.png" alt="Visa" className="credit-card-icon" />
              ) : (company.cardBrand === 'mastercard') ? (
                <img src="/imgs/cc/mastercard.png" alt="Mastercard"  className="credit-card-icon" />
              ) : (company.cardBrand === 'amex') ? (
                <img src="/imgs/cc/amex.png" alt="American Express"  className="credit-card-icon" />
              ) : (company.cardBrand === 'discover') ? (
                <img src="/imgs/cc/discover.png" alt="Discover"  className="credit-card-icon" />
              ) : (
                <img src="/imgs/cc/credit-card.png" alt="Credit Card"  className="credit-card-icon" />
              )
            }
            <span className="last-four-digits-text">**** **** **** {company.lastFourDigits}</span>
            <button className="btn-delete-card" onClick={(e) => deleteCard()}>
              <Trash />
            </button>
          </p>
        </>
      ) : (company.skipBilling) ? (
        <>
          <p>We've disabled billing for this organization.</p>
        </>
      ) : (
        <>
          <Elements stripe={promise}>
            <CheckoutForm setCardSaved={setCardSaved} />
          </Elements>
        </>
      )}
      <hr />
      <h2 className="settings-section-title">Your Current Plan</h2>
      <p className="text-muted" style={{ marginBottom: 10 }}>
        Your are current on the <strong>{company.plan}</strong> plan.
      </p>
      {
        (company.plan === 'enterprise') ? (
          <p>Please contact support if you want to change this.</p>
        ) : (company.plan === 'pro') ? (
          <>
            <button className="btn btn-primary">Upgrade to Enterprise</button>
            <button className="btn btn-outline-danger">Downgrade to Basic</button>
          </>
        ) : (
          <button className="btn btn-success">Upgrade to Pro</button>
        )
      }
      <hr />
      <h2 className="settings-section-title">Your Next Bill</h2>
      <p className="text-muted">
        Your next bill will be on the 1st of the month. The numbers below are based on the current moment.
      </p>
      <CurrentMonthReceipt />
      <hr />
      <h2 className="settings-section-title">Billing History</h2>
      <p className="text-muted">
        Below are a list of past receipts for this organization.
      </p>
    </SettingsPage>
  )
}

export default SettingsBillingPage
