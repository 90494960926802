import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'

import PageTabs from 'components/Page/PageTabs'
import PageTab from 'components/Page/PageTab'
import { CpuIcon, GridIcon } from 'lucide-react'

const SharedOrdersPage = ({ children }) => {
  return (
    <Page>
      <PageTitleRow>
        <h1>Orders</h1>
      </PageTitleRow>
      <PageTabs>
        <PageTab to="/orders" label="Orders" icon={<GridIcon />} />
        <PageTab to="/orders/rules" label="Rules" icon={<CpuIcon />} />
      </PageTabs>
      {children}
    </Page>
  )
}

export default SharedOrdersPage
