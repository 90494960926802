import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts'

const LineGraph = ({ data }) => {
  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={data}
          margin={{ top: 25, right: 25, bottom: 25, left: 0 }}
        >
          <XAxis dataKey="x" style={{ fontSize: '10px' }} />
          <YAxis dataKey="y" style={{ fontSize: '10px' }} />
          <Area
            dataKey="y"
            isAnimationActive={true}
            fill={'#A9E2F3'}
            stroke={'#2ECCFA'}
          >
            {/* <LabelList dataKey="y" position="top" offset={10} /> */}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default LineGraph
