import { useState, useEffect } from 'react'

import Modal from 'components/Modal/Modal'
import Textbox from 'components/Textbox/Textbox'

import './CreateOrderModal.css'
import { Pencil, Search, Trash } from 'lucide-react'

import { getProductById, listProducts, listVariants } from 'utils/api/products'
import {
  listProductsForEDIDemo,
  createTestOrder,
} from 'utils/api/internal-apps'

const ShippingAddressForm = ({
  toggleShippingAddress,
  shippingAddress,
  setShippingAddress,
}) => {
  const [line1, setLine1] = useState(shippingAddress.line1 || '')
  const [line2, setLine2] = useState(shippingAddress.line2 || '')
  const [city, setCity] = useState(shippingAddress.city || '')
  const [state, setState] = useState(shippingAddress.state || '')
  const [zip, setZip] = useState(shippingAddress.zip || '')
  const [country, setCountry] = useState(shippingAddress.country || '')
  const [phone, setPhone] = useState(shippingAddress.phone || '')

  const onSave = (e) => {
    e.preventDefault()
    setShippingAddress({
      line1,
      line2,
      city,
      state,
      zip,
      country,
      phone,
    })
    toggleShippingAddress()
  }

  return (
    <div>
      <div
        className="create-order-form-title-wrapper"
        style={{ paddingBottom: 10 }}
      >
        <h3 className="create-order-form-title">Shipping Address</h3>
        <div>
          <button className="btn btn-primary" onClick={onSave}>
            Save
          </button>
        </div>
      </div>
      <div>
        <Textbox
          placeholder="Line 1"
          value={line1}
          onChange={(e) => setLine1(e.target.value)}
        />
        <Textbox
          placeholder="Line 2 (Optional)"
          value={line2}
          onChange={(e) => setLine2(e.target.value)}
        />
        <Textbox
          placeholder="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <Textbox
          placeholder="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <Textbox
          placeholder="Zip"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
        <Textbox
          placeholder="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <Textbox
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
    </div>
  )
}

const DisplayShippingAddressForm = ({
  shippingAddress,
  toggleShippingAddress,
}) => {
  return (
    <div>
      <div className="create-order-form-shipping-title-wrapper">
        <h3 className="create-order-form-title">Shipping Address</h3>
        <div>
          <button
            className="edit-shipping-address-btn"
            onClick={toggleShippingAddress}
          >
            <Pencil />
          </button>
        </div>
      </div>
      <div>
        <p>{shippingAddress.line1 || '(Missing Line 1)'}</p>
        <p>{shippingAddress.line2 || '(Missing Line 2)'}</p>
        <p>
          {shippingAddress.city || '(Missing City)'},{' '}
          {shippingAddress.state || '(Missing State)'},{' '}
          {shippingAddress.country || '(Missing Country)'}
        </p>
        <p>{shippingAddress.zip || '(Missing Zip)'}</p>
        <p>{shippingAddress.phone || '(Missing Phone)'}</p>
      </div>
    </div>
  )
}

const AddNewLineItems = ({ viewToken, toggleAddLineItem, onAddLineItems }) => {
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const [results, setResults] = useState([])
  const [allVariants, setAllVariants] = useState([])
  const [page, setPage] = useState(1)
  const [selecting, setSelecting] = useState(false)

  const searchProducts = () => {
    listProductsForEDIDemo(viewToken, { search, page, limit: 3 }).then(
      (response) => {
        console.log('list products', response.data)
        if (response.success) {
          setResults(response.data.products || [])
          setAllVariants(response.data.variants || [])
        }
      }
    )
  }

  useEffect(() => {
    searchProducts()
  }, [])

  useEffect(() => {
    searchProducts()
  }, [search, page])

  const onAddSelected = async (e) => {
    e.preventDefault()

    if (selected.length === 0) {
      toggleAddLineItem()
      return
    }
    console.log('selected', selected)
    console.log('allVariants', allVariants)

    // List of product ids, add all of the variants
    setSelecting(true)
    let countOfVariantsLoaded = 0
    const newLineItems = []
    for (let product of selected) {
      console.log('product.id', product.id)
      for (const variant of allVariants) {
        console.log('variant.id', variant.id, variant)
        if (variant.retailerProductId !== product.id) {
          console.log('skipping variant', variant)
          continue
        }
        console.log('adding variant', variant)
        newLineItems.push({
          productId: product.id,
          variantId: variant.id,
          quantity: 0,
          maxQuantity: variant.inventoryQuantity,
          productTitle: product.title,
          variantTitle: variant.title,
          sku: variant.sku,
          images: product.images || [],
          image:
            (product.images || [])[0]?.src || 'https://via.placeholder.com/50',
        })
      }

      countOfVariantsLoaded++
    }
    // if (countOfVariantsLoaded === selected.length) {
    // }
    setSelecting(false)
    onAddLineItems(e, newLineItems)
    toggleAddLineItem()
  }

  return (
    <div>
      <h3 className="create-order-form-title">Add Line Item</h3>
      <div>
        <div className="create-order-line-items-line-search">
          <div className="input-group input-group-search">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-outline-primary">
              <Search />
            </button>
          </div>
        </div>
        <p className="create-order-line-items-selected-text">
          Selected {selected.length} products
        </p>
      </div>
      <div>
        <table className="table w-100 dataTable dtr-inline">
          <thead>
            <tr>
              <th colspan="1" style={{ width: 35 }}></th>
              <th>Product</th>
            </tr>
          </thead>
          <tbody>
            {(results || []).length > 0 ? (
              <>
                {results.map((product) => (
                  <tr>
                    <td
                      colspan="1"
                      className="dtr-control"
                      style={{ width: 35 }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelected([...selected, product])
                          } else {
                            setSelected(
                              selected.filter((p) => p.id !== product.id)
                            )
                          }
                        }}
                      />
                    </td>
                    <td>
                      <img
                        src={
                          (product.images || []).length > 0
                            ? product.images[0].src
                            : 'https://via.placeholder.com/50'
                        }
                        alt="Product"
                        style={{ width: 50 }}
                      />
                      <span className="create-order-line-items-product-title">
                        {product.title}
                      </span>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colspan="2">
                  <p>No products found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="add-selected-wrapper">
        <div className="dt-paging paging_full_numbers">
          <ul className="pagination">
            <li
              className={`dt-paging-button page-item ${page > 1 ? '' : 'disabled'} `}
              onClick={(e) => {
                if (page > 1) {
                  setPage(page - 1)
                }
              }}
            >
              <a className="page-link">‹</a>
            </li>
            <li className="dt-paging-button page-item">
              <a className="page-link">{page}</a>
            </li>
            <li
              className="dt-paging-button page-item"
              onClick={(e) => {
                if (results.length > 0) {
                  setPage(page + 1)
                }
              }}
            >
              <a className="page-link">›</a>
            </li>
          </ul>
        </div>
        <button className="btn btn-primary" onClick={onAddSelected}>
          Add Selected
        </button>
      </div>
    </div>
  )
}

const CreateOrderModal = ({ toggle, viewToken }) => {
  const [toggleEditShippingAddress, setToggleEditShippingAddress] =
    useState(false)
  const [shippingAddress, setShippingAddress] = useState({})
  const [showAddLineItem, setShowAddLineItem] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [finishingOrder, setFinishingOrder] = useState(false)

  console.log('lineItems', lineItems)
  const onAddLineItems = (e, newItems) => {
    const updatedLineItems = [...lineItems, ...newItems]
    setLineItems(updatedLineItems)
  }

  const onRemoveLineItem = (e, lineItem) => {
    const r = lineItems.filter((item) => item !== lineItem)
    setLineItems(r)
  }

  const onFinishOrder = (e) => {
    e.preventDefault()

    // Convert quantity in lineItems to int
    const newLineItems = []
    for (let i = 0; i < lineItems.length; i++) {
      const q = parseInt(lineItems[i].quantity)
      if (q == 0) {
        continue
      }
      newLineItems.push({
        ...lineItems[i],
        quantity: parseInt(lineItems[i].quantity),
      })
    }

    setFinishingOrder(true)
    createTestOrder(viewToken, {
      shippingAddress,
      lineItems: newLineItems,
    }).then((response) => {
      if (response.success) {
        toggle()
      } else {
        // window.alert(response.data.message)
        console.log('error', response.data.message)
      }
      setFinishingOrder(false)
    })
  }

  return (
    <Modal toggle={toggle} size="large">
      <div className="card">
        <div className="card-body">
          <div className="create-order-form-title-wrapper">
            <h2 className="edi-demo-about">Create Order</h2>

            {!toggleEditShippingAddress && !showAddLineItem && (
              <button
                className="btn btn-primary"
                onClick={onFinishOrder}
                disabled={finishingOrder}
              >
                Finish Order
              </button>
            )}
          </div>
          {toggleEditShippingAddress ? (
            <ShippingAddressForm
              toggleShippingAddress={(e) =>
                setToggleEditShippingAddress(!toggleEditShippingAddress)
              }
              setShippingAddress={setShippingAddress}
              shippingAddress={shippingAddress}
            />
          ) : showAddLineItem ? (
            <AddNewLineItems
              toggleAddLineItem={(e) => setShowAddLineItem(!showAddLineItem)}
              onAddLineItems={onAddLineItems}
              viewToken={viewToken}
            />
          ) : (
            <>
              <DisplayShippingAddressForm
                toggleShippingAddress={(e) =>
                  setToggleEditShippingAddress(!toggleEditShippingAddress)
                }
                setShippingAddress={setShippingAddress}
                shippingAddress={shippingAddress}
              />
              <br />
              <div>
                {(lineItems || []).length > 0 ? (
                  <table className="table w-100">
                    <thead>
                      <tr>
                        <th>Line Item</th>
                        <th>Quantity</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {lineItems.map((lineItem, index) => (
                        <tr>
                          <td className="line-item-products-cell">
                            <img
                              src={
                                lineItem.image !== ''
                                  ? lineItem.image
                                  : 'https://via.placeholder.com/50'
                              }
                              alt="Product"
                              style={{ width: 50 }}
                            />
                            <div>
                              <p>{lineItem.productTitle}</p>
                              <p>{lineItem.variantTitle}</p>
                              <p>{lineItem.sku}</p>
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              value={lineItem.quantity}
                              onChange={(e) => {
                                const newLineItems = [...lineItems]
                                newLineItems[index].quantity = e.target.value
                                setLineItems(newLineItems)
                              }}
                            />
                            <label className="form-label">
                              {lineItem.maxQuantity} available
                            </label>
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-danger"
                              onClick={(e) => onRemoveLineItem(e, lineItem)}
                            >
                              <Trash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <>
                    <h3 className="create-order-form-title">Line Items</h3>
                    <div className="create-order-form-empty-line-items">
                      <p>No Line Items</p>
                    </div>
                  </>
                )}
                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => setShowAddLineItem(!showAddLineItem)}
                >
                  Add Line Item
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CreateOrderModal
