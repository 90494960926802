import React from 'react'

import './Textbox.css'

const Textarea = ({ type, placeholder, helpText, error, value, onChange }) => {
  return (
    <div className="textbox-wrapper">
      <div className="textbox-outter textarea-outter">
        {value !== '' && (
          <label className="textbox-mini-label">{placeholder}</label>
        )}
        <textarea
          type={type}
          placeholder={placeholder}
          className="textbox-inner textarea-inner"
          onChange={onChange}
          value={value}
        />
      </div>
      {error ? (
        <p className="textbox-helptext error-helptext">{error}</p>
      ) : helpText ? (
        <p className="textbox-helptext">{helpText}</p>
      ) : null}
    </div>
  )
}

export default Textarea
