import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'
import Panel from 'components/Panel/Panel'
import Button from 'components/Button/Button'

import PrestashopSetupForm from './PrestashopSetupForm'
import ConvictionalSetupForm from './ConvictionalSetupForm'

import { getCurrentOrganization } from 'utils/api/organizations'
import { installIntegration, listInstalls } from 'utils/api/installs'
import { getCompanyId } from 'utils/cookies'
import { listIntegrations } from 'utils/api/integrations'

import './DashboardPage.css'
import './AddInstallsPage.css'

// const options = [
//     // {
//     //   'type': 'PrestaShopConvictional',
//     //   'icon1': 'https://assets-global.website-files.com/61257930009f78b7763e79cb/6147bc95cc5fdd42207c09c7_convictional-webclip.png',
//     //   'icon2': 'https://cdn.freebiesupply.com/logos/large/2x/prestashop-logo-png-transparent.png',
//     //   'components': ['prestashop', 'convictional'],
//     // },
//     {
//       'type': 'WooCommerceEDI',
//       'icon1': '/imgs/edi-logo.png',
//       'icon2': 'https://www.mailerlite.com/assets/integration/woocommerce.png',
//       'components': ['woocommerce', 'sftp'],
//     }
// ]

const convertToOptionStructure = (integrations) => {
  const results = []
  for (let i = 0; i < integrations.length; i++) {
    const integration = integrations[i]

    results.push({
      id: integration.id,
      isIconBased: false,
      label: integration.name,
    })
  }
  results.push({
    id: 'custom',
    isIconBased: false,
    label: 'Custom',
  })
  return results
}

const OptionItem = ({ option, isSelected, setSelectedOption }) => {
  if (option.isIconBased) {
    return (
      <div
        className={`install-option-item ${isSelected && 'install-option-item-selected'}`}
        onClick={() => setSelectedOption(option.id)}
      >
        <div className="install-option-selection-col">
          {isSelected ? (
            <div className="selected-option">
              <i className="bi bi-check-circle-fill" />
            </div>
          ) : (
            <div className="unselected-option" />
          )}
        </div>
        <div className="install-option-details-col">
          <img src={option.icon1} alt="icon1" />
          <i className="bi bi-arrow-left-right" />
          <img src={option.icon2} alt="icon2" />
        </div>
      </div>
    )
  }
  return (
    <div
      className={`install-option-item ${isSelected && 'install-option-item-selected'}`}
      onClick={() => setSelectedOption(option.id)}
    >
      <div className="install-option-selection-col">
        {isSelected ? (
          <div className="selected-option">
            <i className="bi bi-check-circle-fill" />
          </div>
        ) : (
          <div className="unselected-option" />
        )}
      </div>
      <div className="install-option-details-col">
        <p>{option.label}</p>
      </div>
    </div>
  )
}

const AddInstallsPage = () => {
  let navigate = useNavigate()
  const companyId = getCompanyId()
  const [loading, setLoading] = useState(true)
  const [loadingCompany, setLoadingCompany] = useState(true)
  const [currentCompany, setCurrentCompany] = useState(null)
  const [error, setError] = useState(null)
  const [installing, setInstalling] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState('')
  const [body, setBody] = useState({})

  useEffect(() => {
    getCurrentOrganization(companyId).then((response) => {
      setLoadingCompany(false)
      if (response.success) {
        setCurrentCompany(response.data.company)
      }
    })

    listIntegrations({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        const newOptions = convertToOptionStructure(
          response.data.integrations || []
        )
        console.log('newOptions', newOptions)
        setOptions(newOptions)
        setSelectedOption(newOptions[0].id)
      }
      setLoading(false)
    })
  }, [companyId])

  const onInstall = (e) => {
    e.preventDefault()
    setError(null)
    setInstalling(true)
    if (selectedOption === 'custom') {
      body['integrationId'] = ''
      body['custom'] = true
    } else {
      body['integrationId'] = selectedOption
      body['custom'] = false
    }
    installIntegration(body).then((response) => {
      if (response.success) {
        navigate(`/installs/${response.data.install.id}`)
      } else {
        setError(response.data.message)
      }
      setInstalling(false)
    })
  }

  return (
    <Page>
      <div className="row dashboard-top-row dashboard-row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="installs-panel">
            <div className="installs-panel-header">
              <div>
                <h1>Install a New Integration</h1>
                {/* <ShowSteps
                    step={step}
                    totalSteps={2}
                /> */}
              </div>
            </div>

            {!loading ? (
              <>
                <h2 className="install-select-integration">
                  Select the integration
                </h2>
                {options.map((option) => (
                  <OptionItem
                    option={option}
                    setSelectedOption={setSelectedOption}
                    isSelected={option.id === selectedOption}
                  />
                ))}
                <div className="install-next-buttons">
                  {/* <Button onClick={() => setStep(2)}>Next</Button> */}
                  <Button loading={installing} onClick={(e) => onInstall(e)}>
                    Next
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <p>Loading...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default AddInstallsPage
