import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import _isEqual from 'lodash/isEqual'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import Textbox from 'components/Textbox/Textbox'
import Dropdown from 'components/Dropdown/Dropdown'

import { getInstallId } from 'utils/api/apps'
import Button from 'components/Button/Button'
import TextboxError from 'components/Textbox/TextboxError'

import { updateSettings } from 'utils/api/apps'

import './ManageInstallPage.css'

const getSettingsValue = (settingValue, settingOption, settingKey) => {
  if (settingValue) {
    return settingValue
  }
  if (settingOption && settingOption.default) {
    return settingOption.default
  }
  return ''
}

const SettingInput = ({
  settings,
  settingOption,
  settingKey,
  settingValue,
  onEditSetting,
}) => {
  console.log('setting', settingValue)
  const [value, setValue] = useState(
    getSettingsValue(settingValue, settingOption, settingKey)
  )
  return (
    <div className="install-details-setting">
      <div className="install-details-setting-about-col">
        <label className="install-details-setting-label">
          {settingOption.label}
        </label>
        <p className="install-details-setting-label-description">
          {settingOption.description}
        </p>
      </div>
      <div className="install-details-setting-textbox-col">
        {settingOption.type === 'select' ? (
          <Dropdown
            label=""
            placeholder={settingOption.placeholder || ''}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              onEditSetting(settingKey, e.target.value)
            }}
          >
            {settingOption.options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Dropdown>
        ) : settingOption.type === 'toggle' ? (
          <Dropdown
            label=""
            placeholder={settingOption.placeholder || ''}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              onEditSetting(settingKey, e.target.value)
            }}
          >
            {(settingOption.options || []).map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Dropdown>
        ) : (
          <Textbox
            label=""
            placeholder={settingOption.placeholder || ''}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              console.log('onEditSetting', onEditSetting)
              console.log('settingKey', settingKey)
              console.log('value', e.target.value)
              onEditSetting(settingKey, e.target.value)
            }}
            hideLabel={true}
          />
        )}
      </div>
    </div>
  )
}

const DisplaySettingsSection = ({
  section,
  sectionKey,
  settings,
  settingOptions,
  onEditSetting,
}) => {
  if (Object.keys(settingOptions).length === 0) {
    return null
  }

  console.log('settingOptions', settingOptions)

  const sectionSettings = Object.keys(settingOptions).filter((key) => {
    if (!sectionKey) {
      return !settingOptions[key].section
    }
    return (
      settingOptions[key].section && settingOptions[key].section === sectionKey
    )
  })

  if (sectionSettings.length === 0) {
    return null
  }

  console.log('sectionSettings', sectionSettings)

  return (
    <div>
      <div>
        <h3 className="settings-section-title">{section.name}</h3>
        <p className="sections-section-description">{section.description}</p>
      </div>
      {(sectionSettings || []).map((settingKey) => (
        <SettingInput
          key={settingKey}
          settings={settings}
          settingOption={settingOptions[settingKey]}
          settingKey={settingKey}
          settingValue={settings[settingKey]}
          onEditSetting={onEditSetting}
        />
      ))}
    </div>
  )
}

const ManageInstallPage = () => {
  const [loading, setLoading] = useState(true)
  const [appInstall, setAppInstall] = useState(null)
  const [originalSettings, setOriginalSettings] = useState({})
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')

  let { appId, installId } = useParams()

  useEffect(() => {
    getInstallId(appId, installId, {}).then((response) => {
      if (response.success) {
        setAppInstall(response.data.install)
        setOriginalSettings({ ...(response.data.install.settings || {}) })
      }
      setLoading(false)
    })
  }, [])

  const onSave = (e) => {
    e.preventDefault()
    setSaving(true)
    setError('')
    updateSettings(appId, installId, {
      settings: appInstall.settings || {},
    }).then((response) => {
      setSaving(false)
      if (response.success) {
        setOriginalSettings({ ...appInstall.settings })
      } else {
        setError(response.data.message)
      }
    })
  }

  const onEditSetting = (settingKey, value) => {
    appInstall.settings[settingKey] = value
    setAppInstall({ ...appInstall })
  }

  console.log('appInstall', appInstall)

  if (loading) {
    return (
      <Page>
        <p>Loading...</p>
      </Page>
    )
  }

  return (
    <Page>
      <PageTitleRow>
        <div className="settings-top-row-name-col">
          <h1>{appInstall.name}</h1>
        </div>
        <div className="settings-top-row-actions-col">
          {!_isEqual(appInstall.settings, originalSettings) && (
            <Button onClick={onSave}>Save</Button>
          )}
          {error && error !== '' && <TextboxError>{error}</TextboxError>}
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-12">
          <span className="manage-install-page"></span>
          {appInstall.settingOptions &&
            Object.keys(appInstall.settingOptions || {}).length > 0 && (
              <div>
                <h2 className="settings-title">Settings</h2>
                <DisplaySettingsSection
                  section={{ rank: 0, name: 'General', description: '' }}
                  sectionKey=""
                  settings={appInstall.settings || {}}
                  settingOptions={appInstall.settingOptions || {}}
                  onEditSetting={onEditSetting}
                />
                {(appInstall.sectionOptions || []).map((sectionKey) => (
                  <DisplaySettingsSection
                    key={appInstall.sectionOptions[sectionKey].id}
                    section={appInstall.sectionOptions[sectionKey]}
                    sectionKey={sectionKey}
                    settingOptions={appInstall.settingOptions || {}}
                    settings={appInstall.settings || {}}
                    onEditSetting={onEditSetting}
                  />
                ))}
              </div>
            )}
        </div>
      </div>
    </Page>
  )
}

export default ManageInstallPage
