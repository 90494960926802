import { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'

import Page from 'components/Page/Page'
import PageTitleRow from 'components/Page/PageTitleRow'
import Dropdown from 'components/Dropdown/Dropdown'

import { getPartnerById, updatePartner } from 'utils/api/partners'

import './ViewPartnerPage.css'
import { listPriceLists } from 'utils/api/pricing'
import { timeSince } from 'utils/date'
import { displayNumbers } from 'utils/numbers'
import Switch from 'components/Switch/Switch'
import { ChevronLeft } from 'lucide-react'
import Modal from 'components/Modal/Modal'

const AutoSelectProducts = ({ partner, partnerId }) => {
  const [autoSelect, setAutoSelect] = useState(
    partner.autoSelectProducts || false
  )

  const onAutoSelect = (e) => {
    setAutoSelect(e.target.value)
    updatePartner(partnerId, {
      autoSelectProducts: e.target.value == 'true',
    }).then((response) => {
      if (!response.success) {
        window.alert(response.data.message)
      }
    })
  }

  return (
    <div>
      <Dropdown
        value={autoSelect}
        onChange={(e) => onAutoSelect(e)}
        label="Auto Select Products"
        style={{ maxWidth: 400 }}
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </Dropdown>
    </div>
  )
}

const ViewPriceList = ({
  isSupplier,
  pricingId,
  pricingName,
  partnerName,
  partnerId,
}) => {
  const [pricing, setPricing] = useState(pricingId)
  const [priceLists, setPriceLists] = useState([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    listPriceLists({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        setPriceLists(response.data.priceLists || [])
      }
    })
  }, [])

  const onSetPriceList = (e) => {
    setSaving(true)
    setPricing(e.target.value)
    updatePartner(partnerId, {
      pricingId: e.target.value,
    }).then((response) => {
      if (!response.success) {
        window.alert(response.data.message)
      }
      setSaving(false)
    })
  }

  if (!isSupplier) {
    return (
      <div>
        <p>
          Pricing:{' '}
          {pricingId !== '' ? (
            <Link to={`/pricing/${pricingId}`}>{pricingName}</Link>
          ) : (
            'No pricing set'
          )}
        </p>
        <p className="pricing-view-help-text">
          {partnerName ? partnerName : 'Your partner'} owns the pricing of
          products. You can make suggestions once a value is set.
        </p>
      </div>
    )
  }
  return (
    <div>
      <Dropdown
        value={pricing}
        onChange={(e) => onSetPriceList(e)}
        label="Pricing"
        style={{ maxWidth: 400 }}
      >
        <option value="">-- Select Pricing --</option>
        {priceLists.map((priceList) => (
          <option key={priceList.id} value={priceList.id}>
            {priceList.name}
          </option>
        ))}
      </Dropdown>

      {saving ? (
        <p className="pricing-view-help-text">Saving...</p>
      ) : (
        <p className="pricing-view-help-text">
          You own the pricing of products.{' '}
          {partnerName ? partnerName : 'Your partner'} can make suggestions once
          a value is set.
          {priceLists.length === 0 && (
            <>
              {' '}
              <Link to="/pricing/">Set up new pricing</Link>
            </>
          )}
        </p>
      )}
    </div>
  )
}

const ViewPartnerPage = () => {
  const [loading, setLoading] = useState(true)
  // Get id from url
  const { id } = useParams()
  let navigate = useNavigate()
  const [partner, setPartner] = useState(null)
  const [active, setActive] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    getPartnerById(id).then((response) => {
      if (response.success) {
        setPartner(response.data.partner)
        setActive(response.data.partner.active)
        setLoading(false)
      }
    })
  }, [])

  const onChangeActive = (e) => {
    e.preventDefault()

    setActive(!active)
    updatePartner(id, {
      active: !active,
    }).then((response) => {
      if (!response.success) {
        // setPartner
      }
    })
  }

  const onToggleDeleteModal = (e) => {
    setShowDeleteModal(!showDeleteModal)
  }

  const onDelete = (e) => {
    e.preventDefault()
  }

  return (
    <Page loading={loading}>
      <PageTitleRow>
        <div className="col-6">
          <h1>{partner ? partner.name : 'Something went wrong'}</h1>
          <button className="back-btn" onClick={(e) => navigate('/partners')}>
            <ChevronLeft />
            Back to Partners
          </button>
        </div>
        <div className="col-6 text-end">
          <button className="btn btn-danger" onClick={onToggleDeleteModal}>
            Delete
          </button>
        </div>
      </PageTitleRow>
      <div className="row">
        <div className="col-8">
          {partner && (
            <>
              <div className="partner-divider"></div>
              <div className="card">
                <div className="card-body">
                  <ViewPriceList
                    isSupplier={partner.isSupplier}
                    pricingId={partner.pricingId}
                    pricingName={partner.pricingName || ''}
                    partnerName={partner.name}
                    partnerId={partner.id}
                  />
                  <Switch
                    label={active ? 'Active' : 'Inactive'}
                    value={active}
                    onChange={onChangeActive}
                  />
                  <div className="partner-divider-no-line"></div>
                  <div className="partner-divider-no-line"></div>
                  {!partner.isSupplier && (
                    <AutoSelectProducts
                      partner={partner}
                      partnerId={partner.id}
                    />
                  )}

                  <table className="partner-overview-table">
                    <tr>
                      <td className="partner-details-col">
                        Initially Partnered
                      </td>
                      <td className="partner-details-value-col">
                        {timeSince(new Date(partner.createdAt))}
                      </td>
                    </tr>
                    <tr>
                      <td className="partner-details-col">Variants Priced</td>
                      <td className="partner-details-value-col">
                        {displayNumbers(partner.metaData.variantsPriced)}
                      </td>
                    </tr>
                    <tr>
                      <td className="partner-details-col">
                        Variants Available
                      </td>
                      <td className="partner-details-value-col">
                        {displayNumbers(partner.metaData.variantsAvailable)}
                      </td>
                    </tr>
                    <tr>
                      <td className="partner-details-col">Margin</td>
                      <td className="partner-details-value-col">
                        {partner.metaData.minMargin ===
                        partner.metaData.maxMargin
                          ? `${partner.metaData.minMargin}%`
                          : `${partner.metaData.minMargin}% - ${partner.metaData.maxMargin}%`}
                      </td>
                    </tr>
                    <tr>
                      <td className="partner-details-col">
                        Average Ship Time (3 months)
                      </td>
                      <td className="partner-details-value-col">
                        {partner.metaData.averageShipTime !==
                        '0001-01-01T00:00:00Z'
                          ? timeSince(
                              new Date(partner.metaData.averageShipTime)
                            )
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td className="partner-details-col">
                        Average Returns (3 months)
                      </td>
                      <td className="partner-details-value-col">
                        {partner.metaData.averageReturn}%
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <Modal toggle={onToggleDeleteModal}>
          <div className="card">
            <div className="card-body">
              <h2>Are you sure you want to delete this partner?</h2>
              <p>
                This action is irreversible. All data associated with this
                partner will be lost.
              </p>

              <div className="text-end">
                <button className="btn btn-danger" onClick={onDelete}>
                  Delete
                </button>
                <button className="btn" onClick={onToggleDeleteModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Page>
  )
}

export default ViewPartnerPage
