import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import SharedOnboardingPage from './SharedOnboardingPage'
import OnboardingPageNextButtons from './OnboardingPageNextButtons'
import { getMe } from 'utils/api/users'
import {
  getPricingById,
  listPriceLists,
  listPricingEntries,
  deletePricingEntry,
  downloadPricingCSV,
  uploadPricingCSV,
} from 'utils/api/pricing'
import { listPartners } from 'utils/api/partners'
import TextboxError from 'components/Textbox/TextboxError'
import {
  ArrowLeftIcon,
  ChevronLeft,
  CircleCheck,
  TrashIcon,
} from 'lucide-react'

import { createPricing } from 'utils/api/pricing'

import './OnboardingPricingPage.css'
import { displayDollarAmount } from 'utils/money'
import { upload } from '@testing-library/user-event/dist/upload'

const CreatePricing = ({ fetchPriceLists }) => {
  const [step, setStep] = useState(1)
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [startingItems, setStartingItems] = useState('empty')
  const [syncNewProducts, setSyncNewProducts] = useState(false)
  const [newProductMarginType, setNewProductMarginType] = useState('percentage')
  const [newProductMarginValue, setNewProductMarginValue] = useState('0')
  const [partners, setPartners] = useState([])
  const [allPartners, setAllPartners] = useState([])
  const [creating, setCreating] = useState(false)
  const [initialProductsMarginType, setInitialProductsMarginType] =
    useState('percentage')
  const [initialProductsMarginValue, setInitialProductsMarginValue] =
    useState('0')

  useEffect(() => {
    listPartners({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        setAllPartners(response.data.partners || [])
      }
    })
  }, [])

  const onNext = (e) => {
    e.preventDefault()
    if (step === 1 && name === '') {
      setError('Name is required')
      return
    }

    if (step == 3 && allPartners.length == 0) {
      setError('')
      setStep(step + 2) // Skip partners page
      return
    }

    if (step === 5) {
      setError('')
      setCreating(true)
      createPricing({
        name,
        startingItems,
        syncNewProducts: syncNewProducts === 'true',
        newProductMarginType,
        newProductMarginValue,
        partners,
        initialProductsMarginType,
        initialProductsMarginValue,
      }).then((response) => {
        setCreating(false)
        if (response.success) {
          setStep(step + 1)

          setStep(1)
          setName('')
          setError('')
          setStartingItems('empty')
          setSyncNewProducts(false)
          setNewProductMarginType('percentage')
          setNewProductMarginValue('0')
          setPartners([])

          fetchPriceLists()
        } else {
          setError(response.data.message)
        }
      })
      return
    }

    // Successful
    setError('')
    setStep(step + 1)
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Set Up a Price List</h5>
        <p className="text-muted" style={{ top: -10, position: 'relative' }}>
          {step} of 5 steps
        </p>

        {step === 1 ? (
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter a name for your pricing list"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
            {error !== '' && (
              <>
                <TextboxError style={{ marginTop: 5 }}>{error}</TextboxError>
              </>
            )}
          </div>
        ) : step === 2 ? (
          <div>
            <label htmlFor="name">Start the price list with...</label>
            <select
              className="form-control"
              value={startingItems}
              onChange={(e) => setStartingItems(e.target.value)}
            >
              <option value="empty">Empty</option>
              <option value="all">All Products & Variants</option>
            </select>
            {startingItems == 'all' && (
              <>
                <label htmlFor="name" style={{ marginTop: 10 }}>
                  Initial Products Margin Type
                </label>
                <select
                  className="form-control"
                  value={initialProductsMarginType}
                  onChange={(e) => setInitialProductsMarginType(e.target.value)}
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </select>
                <label htmlFor="name" style={{ marginTop: 10 }}>
                  Initial Products Margin
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Margin"
                  value={initialProductsMarginValue}
                  onChange={(e) =>
                    setInitialProductsMarginValue(e.target.value)
                  }
                />
                {initialProductsMarginType == 'fixed' ? (
                  <small>
                    This dollar amount is subtracted from the retail price.
                  </small>
                ) : (
                  <small>
                    This percentage is subtracted from the retail price.
                  </small>
                )}
              </>
            )}
          </div>
        ) : step == 3 ? (
          <div>
            <label htmlFor="name">Automically Add New Products</label>
            <select
              className="form-control"
              value={syncNewProducts}
              onChange={(e) => setSyncNewProducts(e.target.value)}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
            {syncNewProducts && (
              <>
                <label htmlFor="name" style={{ marginTop: 10 }}>
                  New Product Margin Type
                </label>
                <select
                  className="form-control"
                  value={newProductMarginType}
                  onChange={(e) => setNewProductMarginType(e.target.value)}
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </select>
                <label htmlFor="name" style={{ marginTop: 10 }}>
                  New Product Margin
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Margin"
                  value={newProductMarginValue}
                  onChange={(e) => setNewProductMarginValue(e.target.value)}
                />
                {newProductMarginType == 'fixed' ? (
                  <small>
                    This dollar amount is subtracted from the retail price.
                  </small>
                ) : (
                  <small>
                    This percentage is subtracted from the retail price.
                  </small>
                )}
              </>
            )}
          </div>
        ) : step === 4 ? (
          <div>
            <label htmlFor="name">Assign to Partners...</label>
            <table className="table w-100 ">
              <tbody>
                {(allPartners || []).map((partner) => (
                  <tr>
                    <td style={{ width: 30 }}>
                      <input
                        type="checkbox"
                        id={partner.id}
                        checked={partners.includes(partner.id)}
                        className="form-check-input"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setPartners([...partners, partner.id])
                          } else {
                            setPartners(
                              partners.filter((p) => p !== partner.id)
                            )
                          }
                        }}
                      />
                    </td>
                    <td>
                      <label htmlFor={partner.id}>
                        <strong>{partner.name}</strong>
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : step == 5 ? (
          <div>
            <h5>Nearly Done!</h5>
            <p className="text-muted">
              Below are the details you've entered. Click "Create" to create the
              price list.
            </p>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>{name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Start with</strong>
                  </td>
                  <td>{startingItems}</td>
                </tr>
                {startingItems == 'all' && (
                  <>
                    <tr>
                      <td>
                        <strong>Initial Products Margin</strong>
                      </td>
                      <td>
                        {initialProductsMarginType == 'fixed'
                          ? `$${initialProductsMarginValue}`
                          : `${initialProductsMarginValue}%`}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <strong>Automically Add New Products</strong>
                  </td>
                  <td>{syncNewProducts ? 'Yes' : 'No'}</td>
                </tr>
                {syncNewProducts && (
                  <>
                    <tr>
                      <td>
                        <strong>New Product Margin</strong>
                      </td>
                      <td>
                        {newProductMarginType == 'fixed'
                          ? `$${newProductMarginValue}`
                          : `${newProductMarginValue}%`}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <strong>Assigned Partners</strong>
                  </td>
                  <td>
                    {(partners || []).map((partner) => (
                      <span
                        key={partner}
                        className="badge badge-subtle-secondary"
                        style={{ marginRight: 5 }}
                      >
                        {allPartners.find((p) => p.id === partner).name}
                      </span>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : step === 6 ? (
          <div style={{ textAlign: 'center', padding: '50px 20px' }}>
            <CircleCheck
              color="#04B431"
              style={{ width: 40, height: 40, marginBottom: 10 }}
            />
            <h5 style={{ color: '#04B431', fontSize: 20 }}>
              Price List Created
            </h5>
          </div>
        ) : null}
        <br />
        {error !== '' && <TextboxError>{error}</TextboxError>}
        {step < 5 ? (
          <button className="btn btn-primary" onClick={onNext}>
            Next
          </button>
        ) : step == 5 ? (
          <button className="btn btn-primary" onClick={onNext}>
            Create
          </button>
        ) : null}
      </div>
    </div>
  )
}

const PriceListTableItem = ({ priceList, setSelectedPriceList }) => {
  const [partners, setPartners] = useState([])
  const [loadingPartners, setLoadingPartners] = useState(true)

  useEffect(() => {
    listPartners({
      pricing: priceList.id,
      page: 1,
      limit: 50,
    }).then((response) => {
      if (response.success) {
        setPartners(response.data.partners || [])
        setLoadingPartners(false)
      }
    })
  }, [])

  return (
    <tr key={priceList.id}>
      <td>{priceList.name}</td>
      <td>
        {loadingPartners ? (
          <>Loading...</>
        ) : (
          <>
            {(partners || []).map((partner) => (
              <span
                key={partner.id}
                className="badge badge-subtle-secondary"
                style={{ marginRight: 5 }}
              >
                {partner.name}
              </span>
            ))}
          </>
        )}
      </td>
      <td>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault()
            setSelectedPriceList(priceList.id)
          }}
        >
          Edit
        </button>
      </td>
    </tr>
  )
}

const PriceListsCard = ({ priceLists, setSelectedPriceList }) => {
  if (!priceLists || priceLists.length === 0) {
    return null
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Price Lists</h5>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Partners</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {(priceLists || []).map((priceList) => (
              <PriceListTableItem
                priceList={priceList}
                setSelectedPriceList={setSelectedPriceList}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const PartnersCard = ({ partners }) => {
  if (!partners || partners.length === 0) {
    return null
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Partners</h5>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Price List</th>
            </tr>
          </thead>
          <tbody>
            {partners.map((partner) => (
              <tr key={partner.id}>
                <td>{partner.name}</td>
                <td>
                  {partner.pricingName !== ''
                    ? partner.pricingName
                    : partner.pricingId
                      ? partner.pricingId
                      : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const EditPriceListItem = ({ priceListId, entry, onRemoveById }) => {
  const [deleting, setDeleting] = useState(false)

  const onRemove = (e) => {
    e.preventDefault()
    setDeleting(true)

    deletePricingEntry(priceListId, entry.id).then((response) => {
      if (response.success) {
        onRemoveById(entry.id)
      }
      setDeleting(false)
    })
  }

  return (
    <tr key={entry.id}>
      <td>
        <p>
          {entry.productTitle} / {entry.title}
        </p>
        <p>
          {entry.sku} / {entry.upc} (UPC)
        </p>
      </td>
      <td>
        {Object.keys(entry.quantities).map((quantity) => (
          <>
            <p>
              {quantity}
              {'+'}:{' '}
              {displayDollarAmount(
                entry.quantities[quantity].basePrice,
                entry.quantities[quantity].basePriceCurrency
              )}
            </p>
          </>
        ))}
      </td>
      <td>
        {displayDollarAmount(entry.retailPrice, entry.retailPriceCurrency)}
      </td>
      <td>
        <button className="btn btn-danger" onClick={onRemove}>
          {deleting ? (
            <span
              className="spinner-border spinner-border-sm"
              style={{ color: '#fff' }}
            />
          ) : (
            <TrashIcon size={16} />
          )}
        </button>
      </td>
    </tr>
  )
}

const PriceListEdit = ({ selectedPriceList, setSelectedPriceList }) => {
  const [loading, setLoading] = useState(true)
  const [priceList, setPriceList] = useState(null)
  const [page, setPage] = useState(1)
  const [loadingEntries, setLoadingEntries] = useState(true)
  const [entries, setEntries] = useState([])
  const [uploading, setUploading] = useState(false)
  const [downloadingEmpty, setDownloadingEmpty] = useState(false)
  const [downloadingCurrent, setDownloadingCurrent] = useState(false)
  const [downloadingAllVariants, setDownloadingAllVariants] = useState(false)

  const uploadCSVRef = useRef(null)
  const [pricingCSVUpload, setPricingCSVUpload] = useState(null)

  const fetchEntries = () => {
    setLoadingEntries(true)

    listPricingEntries(selectedPriceList, {
      page,
      limit: 20,
    }).then((response) => {
      if (response.success) {
        setLoadingEntries(false)
        setEntries(response.data.entries || [])
      }
    })
  }

  useEffect(() => {
    getPricingById(selectedPriceList).then((response) => {
      if (response.success) {
        setLoading(false)
        setPriceList(response.data.priceList)
      }
    })

    fetchEntries()
  }, [])

  useEffect(() => {
    fetchEntries()
  }, [page])

  const onRemoveById = (id) => {
    setEntries(entries.filter((entry) => entry.id !== id))
  }

  const onUploadCSV = (e) => {
    e.preventDefault()

    uploadCSVRef.current.click()
  }

  const onUploadCSVChange = (e) => {
    setUploading(true)
    const file = e.target.files[0]
    setPricingCSVUpload(file)

    // Convert to base64
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64Text = reader.result.split(',')[1]
      console.log('Beginning to upload CSV')
      uploadPricingCSV(selectedPriceList, {
        content: base64Text,
      }).then((response) => {
        console.log('response', response)
        setUploading(false)
        setPricingCSVUpload(null)
        if (response.success) {
          window.alert('CSV uploaded successfully')

          fetchEntries()
        } else {
          window.alert(response.data.message)
        }
      })
    }
  }

  const onDownloadEmpty = (e) => {
    e.preventDefault()
    setDownloadingEmpty(true)

    downloadPricingCSV({
      type: 'empty',
    }).then((response) => {
      setDownloadingEmpty(false)
      if (response.success) {
        let csv = response.data.csv
        // Replace all \\n with \n
        csv = csv.replace(/\\n/g, '\n')
        const blob = `data:text/csv;charset=utf-8,${csv}`

        var encodedUri = encodeURI(blob)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'pricing.csv')
        document.body.appendChild(link) // Required for FF
        link.click()
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onDownloadCurrent = (e) => {
    e.preventDefault()
    setDownloadingCurrent(true)

    downloadPricingCSV({
      type: 'current',
      pricing: selectedPriceList,
    }).then((response) => {
      setDownloadingEmpty(false)
      if (response.success) {
        let csv = response.data.csv
        // Replace all \\n with \n
        csv = csv.replace(/\\n/g, '\n')
        const blob = `data:text/csv;charset=utf-8,${csv}`

        var encodedUri = encodeURI(blob)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'pricing.csv')
        document.body.appendChild(link) // Required for FF
        link.click()
      } else {
        window.alert(response.data.message)
      }
    })
  }

  const onDownloadAllVariants = (e) => {
    e.preventDefault()
    setDownloadingAllVariants(true)

    downloadPricingCSV({
      type: 'all',
    }).then((response) => {
      setDownloadingEmpty(false)
      if (response.success) {
        let csv = response.data.csv
        // Replace all \\n with \n
        csv = csv.replace(/\\n/g, '\n')
        const blob = `data:text/csv;charset=utf-8,${csv}`

        var encodedUri = encodeURI(blob)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'pricing.csv')
        document.body.appendChild(link) // Required for FF
        link.click()
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (loading) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={9}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={9}>
      <button
        onClick={(e) => {
          e.preventDefault()
          setSelectedPriceList(null)
        }}
        className="edit-pricing-btn-back"
      >
        <ChevronLeft size={12} />
        Back to Price Lists
      </button>
      <br />
      <br />
      <div className="card">
        <div className="card-body">
          <p className="edit-pricing-parent-title">Pricing</p>
          <h2 className="onboarding-section-title" style={{ marginBottom: 5 }}>
            {priceList && priceList.name ? priceList.name : 'Edit Price List'}
          </h2>
          <div>
            <p className="text-muted" style={{ marginBottom: 10 }}>
              You can manage the price list entries with CSV files. You can
              download a one of the templates to get started. Upload any changes
              you want to make.
            </p>
            <div>
              <button className="btn btn-primary" onClick={onUploadCSV}>
                {uploading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ color: '#fff' }}
                  ></span>
                ) : (
                  <>Upload Changes</>
                )}
              </button>
              <input
                type="file"
                id="pricing-csv-upload"
                ref={uploadCSVRef}
                style={{ display: 'none' }}
                onChange={onUploadCSVChange}
              />
            </div>
            <div className="edit-pricing-download-templates">
              <p>Download Templates</p>
              <button className="download-btn" onClick={onDownloadEmpty}>
                Empty Template
              </button>
              {' / '}
              <button className="download-btn" onClick={onDownloadCurrent}>
                Current Price List
              </button>
              {' / '}
              <button className="download-btn" onClick={onDownloadAllVariants}>
                All Variants
              </button>
            </div>
          </div>
          <br />
          <div>
            {loadingEntries ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="edit-pricing-entries-table-header"
                    >
                      Variant
                    </th>
                    <th
                      scope="col"
                      className="edit-pricing-entries-table-header"
                    >
                      Base Price
                    </th>
                    <th
                      scope="col"
                      className="edit-pricing-entries-table-header"
                    >
                      Retail Price
                    </th>
                    <th
                      scope="col"
                      className="edit-pricing-entries-table-header"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {entries.length === 0 ? (
                    <tr>
                      <td colSpan={4}>No entries found</td>
                    </tr>
                  ) : (
                    <>
                      {entries.map((entry) => (
                        <EditPriceListItem
                          entry={entry}
                          onRemoveById={onRemoveById}
                        />
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div>
            <div className="dt-paging paging_full_numbers">
              <ul className="pagination">
                <li
                  className={`dt-paging-button page-item ${page == 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link first"
                    disabled={page === 1}
                    onClick={(e) => {
                      e.preventDefault()
                      if (page > 1) {
                        setPage(page - 1)
                      }
                    }}
                  >
                    ‹
                  </button>
                </li>
                <li className="dt-paging-button page-item active">
                  <button className="page-link">{page}</button>
                </li>
                <li className="dt-paging-button page-item">
                  <button
                    className="page-link next"
                    onClick={(e) => {
                      e.preventDefault()
                      setPage(page + 1)
                    }}
                  >
                    ›
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </SharedOnboardingPage>
  )
}

const OnboardingPricingPage = () => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [selectedPriceList, setSelectedPriceList] = useState(null)
  const [loadingPriceLists, setLoadingPriceLists] = useState(true)
  const [priceLists, setPriceLists] = useState([])
  const [partners, setPartners] = useState([])
  const [loadingPartners, setLoadingPartners] = useState(true)

  useEffect(() => {
    fetchCompany()
    fetchPriceLists()
    fetchPartners()
  }, [])

  const fetchCompany = () => {
    getMe().then((response) => {
      if (response.success) {
        setCompany(response.data.company)
        setLoading(false)
        setInitialPageLoad(false)
      }
    })
  }

  const fetchPriceLists = () => {
    listPriceLists({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        setPriceLists(response.data.priceLists || [])
        setLoadingPriceLists(false)
      }
    })
  }

  const fetchPartners = () => {
    listPartners({
      page: 1,
      limit: 250,
    }).then((response) => {
      if (response.success) {
        setPartners(response.data.partners || [])
        setLoadingPartners(false)
      }
    })
  }

  const onNextPage = (e) => {
    e.preventDefault()
    navigate('/onboarding/completed')
  }

  if (initialPageLoad && (loading || loadingPriceLists || loadingPartners)) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={9}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
      </SharedOnboardingPage>
    )
  }

  if (selectedPriceList) {
    return (
      <PriceListEdit
        selectedPriceList={selectedPriceList}
        setSelectedPriceList={setSelectedPriceList}
      />
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={9}>
      <h2 className="onboarding-section-title">Pricing</h2>
      <p className="text-muted">Setup pricing for your products</p>
      <br />
      <CreatePricing fetchPriceLists={fetchPriceLists} />
      <br />
      <PriceListsCard
        priceLists={priceLists}
        setSelectedPriceList={setSelectedPriceList}
      />
      <br />
      <PartnersCard partners={partners} priceLists={priceLists} />
      <OnboardingPageNextButtons>
        <button className="btn btn-primary" onClick={onNextPage}>
          Next
        </button>
      </OnboardingPageNextButtons>
    </SharedOnboardingPage>
  )
}

export default OnboardingPricingPage
