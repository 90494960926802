import React, { useState } from 'react'

const TeamSection = () => {
  return (
    <div>
      <table className="table">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </table>
      <p>send invite</p>
    </div>
  )
}

export default TeamSection
