const SentInvites = ({ sentInvites }) => {
  if (!sentInvites) {
    return null
  }
  if (sentInvites.length === 0) {
    return null
  }
  return (
    <div className="card">
      <div className="card-header">
        <h3>Sent invites</h3>
      </div>
      <div className="card-body">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Email</th>
              <th>Supplier</th>
            </tr>
          </thead>
          <tbody>
            {sentInvites.map((invite) => (
              <tr key={invite.id}>
                <td>{invite.email}</td>
                <td>{invite.isSupplier ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SentInvites
